import React from 'react';


import InputField from 'common/input/InputField';
import SearchSelect from 'common/input/SearchSelect';
import CustomerProjectSelectors from 'components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors';
import { Grid } from '@mui/material';
import DynamicActionSelector from 'components/createFormComponents/dynamicSelectorFields/DynamicActionSelector';
import { fetchWarehouses } from 'redux/slices/listSlice/listSlice';

const BinForm = ({ formData = {} }) => {
  const gridItemSize = {
    sm: 6,
    md: 6,
    xs: 12,
  };

  return (
    <Grid sx={{ px: { xs: 2 }, py: 2 }} container spacing={2}>

      <Grid item {...gridItemSize}>
        <InputField formData={formData} name="binNo" required />
      </Grid>

      {/* <Grid item {...gridItemSize}>
        <DynamicActionSelector
          name="locationId"
          label="Location"
          fetchListAction={fetchLoca}
          getStateValue={state => state?.lists?.warehouses}
          formData={formData}
        />
      </Grid> */}
      <Grid item {...gridItemSize}>
        <SearchSelect
          name="locationId"
          label="Location"
          formData={formData}
          required
          searchSelectData={[]}
        />
      </Grid>

      <CustomerProjectSelectors
        formData={formData}
        {...gridItemSize}
      />
    </Grid>
  );
};
export default BinForm;
