import React, { memo } from 'react'

import InfoSection from 'pages/components/public/InfoSection';

import img1 from 'assets/staticPages/productPage/1.png';
import img2 from 'assets/staticPages/productPage/2.png';
import img3 from 'assets/staticPages/productPage/3.png';
import img4 from 'assets/staticPages/productPage/4.png';
import img5 from 'assets/staticPages/productPage/5.png';
import img6 from 'assets/staticPages/productPage/6.png';
import img7 from 'assets/staticPages/productPage/7.png';
import img8 from 'assets/staticPages/productPage/8.png';

//infoContent
const infoContent = [
  {
    img: img1,
    title: <>Lead Management</>,
    bullets: [
      'Add new leads manually or import leads from external sources.',
      'View a lead details, including contact information and lead source.',
      'Modify lead information and status based on interactions.',
      'Remove irrelevant or duplicate leads.',
      'Associations quotes, deals.',
    ],
  },
  {
    img: img2,
    title: <>Contact Management</>,
    bullets: [
      'Add new contacts and associate them with relevant accounts.',
      'Access comprehensive contact profiles with communication history.',
      'Modify contact details and preferences.',
      'Remove obsolete contacts and Interactive filterable dashboard.',
      'Meeting scheduling, Task, Activities, Powered by Interactive Calendar.',
    ],
  },
  {
    img: img3,
    title: <>Deal Pipeline</>,
    bullets: [
      'Add new deals with relevant details, such as deal stage and expected revenue.',
      'Monitor the status and progress of each deal in the pipeline.',
      'Move deals through different stages and update deal information.',
      'Remove deals that are no longer relevant.',
      'Relationship to Leads, quotes, contact, meetings, Customers.',
    ],
  },
  {
    img: img4,
    title: <>Quotes</>,
    bullets: [
      'Generate quotes for products or services.',
      'View and compare multiple quotes.',
      'Modify quote details based on negotiations.',
      'Remove outdated or cancelled quotes.',
      'Associated to Products, Deals and Services.',
    ],
  },
  {
    img: img5,
    title: <>Project And Customer Management</>,
    bullets: [
      'Add new customer Projects and organizations',
      'Access detailed account profiles, including transaction history.',
      'Modify account information and preferences.',
      'Deactivate or remove obsolete project and Customers.',
      '360 Degree association all activities related to the account.',
    ],
  },
  {
    img: img6,
    title: <>Orders Management</>,
    bullets: [
      'Generate Sales, Work, Purchase orders for products or services.',
      'Access order details, including quantities, pricing, and status.',
      'Modify order details based on changes or negotiations..',
      'Cancel or remove unnecessary orders.',
      'Relationship to products, services, and invoices.',
    ],
  },
  {
    img: img7,
    title: <>Product Management</>,
    bullets: [
      'Add new products or services to the catalogues.',
      'View detailed product information and specifications.',
      'Modify product details, pricing, availability and Remove obsolete or discontinued products.',
      'Associate with Assets, quotes, orders, and invoice.',
      'Vendor Management.',
      'HR-(New resource Creation, allocating to Categories, Task Assignment)',
    ],
  },
  {
    img: img8,
    title: <>Inventory & Warehouse Management</>,
    bullets: [
      'Add new inventory items, brands and Products',
      'Access real-time information on inventory, product stock levels.',
      'Modify stock quantities, update product information, and manage orders.',
      'Remove obsolete inventory items or discontinued products.',
      'Asset Processing (receiving, testing, Grading, and storing).',
      'Associates with products, Assets, Stock, Orders tracking, warehouses.',
    ],
  },
]

const ProductInfo = () => {
  return (
    <InfoSection infoContent={infoContent} />
  )
};

export default memo(ProductInfo);
