import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getCurrencies } from 'api/masterApi';
import { isEmpty } from 'lodash';

export const fetchAllCurrencies = createAsyncThunk(
  'actions/fetchAllCurrencies',
  async () => await getCurrencies(),
);

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    currencies: [],
    errorDialogText: null,
    snackbar: {
      open: false,
      message: 'Server error occurred, please try again later.',
      options: {},
    },
    sideDrawerOpen: false,
  },
  reducers: {
    setErrorDialogText: (state, action) => {
      const defaultMsg =
        (action.payload === '' || action.payload === undefined) &&
        'Server error occurred, please try again.';
      state.errorDialogText = defaultMsg || action.payload;
    },

    setSnackBar: (state, action) => {
      state.snackbar = action.payload;
    },
    disableSnackBar: (state) => {
      state.snackbar.open = false;
    },

    toggleSideDrawer: (state, action) => {
      const { payload } = action;
      state.sideDrawerOpen =
        payload === undefined ? !state.sideDrawerOpen : payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCurrencies.fulfilled, (state, action) => {
      if (isEmpty(action.payload)) return state;
      state.currencies = action.payload;
    });
  },
});

export const {
  setErrorDialogText,
  setSnackBar,
  disableSnackBar,
  toggleSideDrawer,
} = commonSlice.actions;
export default commonSlice.reducer;
