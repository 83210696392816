import React, { memo } from 'react'

import { Box, Container, Typography } from '@mui/material';

import { ReactComponent as Wave } from 'assets/staticPages/wave.svg';
import useScreenSize from 'hooks/useScreenSize';

import ParticlesGenerator from 'pages/components/public/ParticlesGenerator';

import LaptopCarousel from 'pages/components/public/LaptopCarousel';

const ProductHero = () => {
  const { isTablet } = useScreenSize();

  return <>
    <Box
      width='100%'
      backgroundColor='common.subtleGrey'
      position='relative'
      sx={{
        height: { xs: 'calc(100dvh - 64px)', md: 'calc(100dvh - 80px)' }
      }}
    >
      <ParticlesGenerator color={["#3e446b", '#ff826d']} />

      <Container maxWidth='xl' sx={{ zIndex: 2, position: 'relative' }}>
        <Box
          sx={{
            pt: { xs: 12, lg: 14 },
            pb: 4,
          }}
        >
          <Typography
            component='div'
            variant='p'
            color='common.text'
            sx={{
              fontWeight: '600',
              textAlign: 'center',
              fontSize: { xs: '36px', sm: '50px', md: '60px' },
              lineHeight: { xs: '50px', sm: '54px', md: '64px' }
            }}
            data-aos="fade"
            data-aos-duration="1000"
          >
            Build Excellent Customer Relationships
          </Typography>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <LaptopCarousel sx={{ maxWidth: '1100px' }} />
          </Box>
        </Box>
      </Container>

      <Box
        component={Wave}
        width='100%'
        height='22%'
        preserveAspectRatio={isTablet ? 'xMinYMax slice' : 'none'}
        sx={{
          position: 'absolute',
          bottom: -5, left: 0,
          '& path': {
            fill: theme => theme.palette.secondary.main,
          }
        }}
      />
    </Box>

    <Box
      sx={{
        width: '100vw',
        height: '60px',
        backgroundColor: 'secondary.main'
      }}
    />

  </>
}

export default memo(ProductHero);
