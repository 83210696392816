import React from 'react'

import PartnersHero from './sections/PartnersHero';
import PartnersInfo from './sections/PartnersInfo';
import LearnFaster from '../../components/public/LearnFaster';

import { useNavigate } from 'react-router-dom';
import { otherRoutes } from 'routes/allRoutesMap';

const PartnersPage = () => {
  const nav = useNavigate();

  return <>
    <PartnersHero />
    <PartnersInfo />
    <LearnFaster
      title='Partner with MasterCRM'
      description='Interested in partnering with us? Get in touch for more information.'
      minHeight='340px'
      onClick={() => nav(otherRoutes.CONTACT_US_PAGE)}
      ctaLabel='Contact Us'
    />
  </>
};

export default PartnersPage;