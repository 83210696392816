import React, { useState, useEffect, memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isArray, isNil, omit, trimEnd } from 'lodash';

import { Typography, Button, Box, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import PageTable from './PageTable';
import DetailsDrawer from 'components/detailsDrawer/DetailsDrawer';
import CreateDialog from 'components/createFormComponents/createForms/CreateDialog';
import CreateMultiDialog from 'components/createFormComponents/createForms/CreateMultiDialog';
import PaperBox from 'common/ui/PaperBox';
import PopperMenu from 'common/navigation/popperMenu/PopperMenu';

import { transitions } from 'core/transitions';

import { useNavigate, useParams } from 'react-router-dom';

import { setErrorDialogText } from 'redux/slices/commonSlice/commonSlice';

//too many props, needs a cleanup
const PageMain = ({
  fetchedData = [],
  rows = [],
  columns = [],
  formKey = null,
  label = '',
  labelContent,
  createLabel = '',
  isMiniTable = true,
  children = null,
  extraButtons,
  detailDrawerChildren = null,
  clickRowData = () => { },
  fetchApi,
  checkboxSelection = false,
  onRowSelectionModelChange = () => { },
  rowSelectionModel,
  autoHeight = false,
  height,
  replaceContent,
  contentAboveTable,
  formsOnly,
  drawerProps,
  isMultiAdd,
  preFillData,
  preFillUpdateData,
  createFormProps,
  disableIdAction,
  disableAddUpdate,
  onlyDetailsDrawer,
  removeCreateButton,
  setUpdatedData = () => { },
  customCreateDialog = null,
  fetchByIdApi = null,
  fetchApiId,
  detailDataFetchIdKey = null,
  pagePath = null,
  onDetailDataFetch = () => { },
  disableFetchData = false,
  drawerLabelKey = '',
  handleDrawerClose = () => { },
  updateEditRowData,
  tableProps = {},
}) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [drawerData, setDrawerData] = useState(null);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [extraButtonAnchor, setExtraButtonAnchor] = useState(null);
  const [drawerLoading, setDrawerLoading] = useState(true);

  const isDetailsFetchByApi = useMemo(
    () =>
      fetchApiId
        ? fetchApiId
        : !!detailDataFetchIdKey && !!pagePath && !!fetchByIdApi,
    [(detailDataFetchIdKey, pagePath, fetchByIdApi, fetchApiId)],
  );

  useEffect(() => {
    if (!updateEditRowData) return;
    setEditRow(updateEditRowData);
  }, [updateEditRowData]);

  const pathname = window.location.pathname;
  const isNotPagePathUrl = !pathname.includes(pagePath) || onlyDetailsDrawer;

  const { id } = useParams();
  useEffect(() => {
    if (!editRow?.[detailDataFetchIdKey] && !fetchApiId) {
      if (!isDetailsFetchByApi || !id || isNotPagePathUrl) {
        !isDetailsFetchByApi && setDrawerLoading(false);
        return;
      }
    }

    setDrawerLoading(true);

    fetchByIdApi(
      isNotPagePathUrl ? fetchApiId || editRow?.[detailDataFetchIdKey] : id,
    )
      ?.then((res) => {
        clickRowData(res);
        setDrawerData(res);
        onDetailDataFetch(res);
      })
      ?.catch(() =>
        dispatch(
          setErrorDialogText('Error while fetching data, please try again'),
        ),
      )
      ?.finally(() => setDrawerLoading(false));
  }, [id, isDetailsFetchByApi, editRow, fetchApiId]);

  useEffect(() => {
    if (!fetchApi || disableFetchData || onlyDetailsDrawer) return;
    setLoading(true);

    const callFetch = async () => {
      !fetchedData?.length && !rows?.length && (await dispatch(fetchApi()));
      setLoading(false);
    };
    callFetch();
  }, []);

  const detailsSideDrawer = (
    <DetailsDrawer
      open={!isNil(editRow) || !isNil(drawerData)}
      onClose={() => {
        setEditRow(null);
        clickRowData(null);
        setDrawerData(null);
        onDetailDataFetch({});
        handleDrawerClose();
        !isNotPagePathUrl &&
          isDetailsFetchByApi &&
          nav(pagePath + window.location.search);
      }}
      data={drawerData || preFillUpdateData || editRow}
      formKey={formKey}
      handleUpdate={setUpdatedData}
      drawerLoading={drawerLoading}
      drawerLabel={`${label ? trimEnd(label, 's') + ':' : ''} ${editRow?.[drawerLabelKey] || id || ''}`}
      {...drawerProps}
    >
      {detailDrawerChildren}
    </DetailsDrawer>
  );

  if (onlyDetailsDrawer) return detailsSideDrawer;

  return (
    <>
      {!formsOnly && (
        <Box sx={{ height: '100%' }}>
          <PaperBox
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              rowGap: 2,
              columnGap: 1,
              py: 0.5,
              mb: 0.5,
              minHeight: 0,
              backgroundColor: 'white',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              flexWrap="wrap"
              columnGap={1}
              rowGap={2}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
              >
                <Typography
                  component="span"
                  variant="h5"
                  sx={{ alignSelf: 'center' }}
                >
                  {label}
                </Typography>
                {labelContent}
              </Box>

              <Box
                display="flex"
                flexWrap="wrap"
                columnGap={2}
                rowGap={1}
                justifyContent="center"
              >
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  rowGap={2}
                  columnGap={2}
                  alignItems="flex-start"
                >
                  {children}
                </Box>

                {isArray(extraButtons) && extraButtons?.length ? (
                  <Button
                    size="tiny"
                    variant="contained"
                    onClick={(e) => setExtraButtonAnchor(e.currentTarget)}
                    endIcon={
                      <KeyboardArrowDownRoundedIcon
                        sx={{
                          scale: '1.2',
                          rotate: extraButtonAnchor ? 'x 180deg' : '',
                          transition: transitions().common,
                        }}
                      />
                    }
                    color="secondary"
                    sx={{ minWidth: '100px' }}
                  >
                    Actions
                  </Button>
                ) : (
                  extraButtons
                )}
                {isArray(extraButtons) && (
                  <PopperMenu
                    open={!!extraButtonAnchor}
                    anchorEl={extraButtonAnchor}
                    onClickAway={() => setExtraButtonAnchor(null)}
                    placement="bottom"
                    popperSx={{ zIndex: 1220 }}
                  >
                    {extraButtons?.map((buttonComponent, key) => (
                      <MenuItem key={key}>{buttonComponent}</MenuItem>
                    ))}
                  </PopperMenu>
                )}

                {!!formKey && !disableAddUpdate && !removeCreateButton && (
                  <Button
                    variant="contained"
                    size="tiny"
                    color="primary"
                    onClick={() => setIsCreateOpen(true)}
                    endIcon={<AddIcon />}
                    sx={{ minWidth: '100px' }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            </Box>
          </PaperBox>

          <PaperBox
            sx={{
              py: 0,
              px: 0,
              backgroundColor: 'white',
            }}
          >
            {contentAboveTable && contentAboveTable}
            {!replaceContent ? (
              <PageTable
                columns={columns}
                rows={rows}
                isMiniTable={isMiniTable}
                label={label}
                formKey={formKey}
                setIsCreateOpen={setIsCreateOpen}
                setEditRow={(row) => {
                  setDrawerLoading(true);
                  const filteredRow = omit(row, 'id');
                  clickRowData(filteredRow);
                  setEditRow(filteredRow);
                  !isNotPagePathUrl &&
                    isDetailsFetchByApi &&
                    nav(
                      `${pagePath}/${row?.[detailDataFetchIdKey]}${window.location.search}`,
                    );
                }}
                preFillUpdateData={preFillUpdateData}
                onRowSelectionModelChange={onRowSelectionModelChange}
                rowSelectionModel={rowSelectionModel}
                checkboxSelection={checkboxSelection}
                autoHeight={autoHeight}
                height={height}
                disableIdAction={disableIdAction}
                {...tableProps}
                loading={loading || tableProps?.loading}
              />
            ) : (
              <>{replaceContent}</>
            )}
          </PaperBox>
        </Box>
      )}

      {customCreateDialog ? (
        customCreateDialog
      ) : isMultiAdd ? (
        <CreateMultiDialog
          isDialogOpen={isCreateOpen}
          handleClose={() => {
            setIsCreateOpen(false);
            createFormProps?.handleClose && createFormProps?.handleClose();
          }}
          formKey={formKey}
          title={createLabel}
          label={label}
          preFillData={preFillData}
          {...createFormProps}
        />
      ) : (
        <CreateDialog
          isDialogOpen={isCreateOpen}
          handleClose={() => {
            setIsCreateOpen(false);
            createFormProps?.handleClose && createFormProps?.handleClose();
          }}
          formKey={formKey}
          title={createLabel}
          label={label}
          preFillData={preFillData}
          {...createFormProps}
        />
      )}

      {detailsSideDrawer}
    </>
  );
};

export default memo(PageMain);
