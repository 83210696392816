import React from 'react';

import { Box, Typography } from '@mui/material';

const AssetGrade = ({
  grade = 'a',
  disableLabel = false,
  isSmall = false,
}) => {
  const gradeColorMap = {
    a: '#4CAF50',
    b: '#8BC34A',
    c: '#CDDC39',
    d: '#FFC107',
    e: '#FF5722',
    f: '#F44336',
  };

  return (
    <Box display="flex" alignItems="center" columnGap={1}>
      {!disableLabel &&
        <Typography variant="p2" fontWeight="bold">
          Grade:
        </Typography>
      }
      <Box
        sx={{
          width: isSmall ? '24px' : '32px',
          height: isSmall ? '24px' : '32px',
          border: grade ? `2px solid ${gradeColorMap?.[grade?.toLowerCase()] || 'red'}` : '',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="p"
          fontSize={isSmall ? '18px' : '24px'}
          fontWeight={isSmall ? '500' : '500'}
          color={gradeColorMap?.[grade?.toLowerCase()] || 'red'}
          sx={{ transform: 'translateY(-1px)' }}
        >
          {grade}
        </Typography>
      </Box>
    </Box>
  );
};

export default AssetGrade;
