import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getHomepageData } from 'api/masterApi';
import { isEmpty } from 'lodash';

export const fetchHomepageData = createAsyncThunk(
  'actions/fetchHomepageData',
  async () => await getHomepageData(),
);

export const homeSlice = createSlice({
  name: 'home',
  initialState: [],
  extraReducers: (builder) => {
    builder.addCase(fetchHomepageData.fulfilled, (state, action) => {
      if (isEmpty(action.payload)) return state;
      return action.payload;
    });
  },
});

export default homeSlice.reducer;
