import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Groups3Icon from '@mui/icons-material/Groups3';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import AssuredWorkloadRoundedIcon from '@mui/icons-material/AssuredWorkloadRounded';
import GradingRoundedIcon from '@mui/icons-material/GradingRounded';
import EditNotificationsRoundedIcon from '@mui/icons-material/EditNotificationsRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded';
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
import DeviceUnknownRoundedIcon from '@mui/icons-material/DeviceUnknownRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import MemoryRoundedIcon from '@mui/icons-material/MemoryRounded';
import ScreenshotMonitorRoundedIcon from '@mui/icons-material/ScreenshotMonitorRounded';
import SdCardRoundedIcon from '@mui/icons-material/SdCardRounded';
import TerminalRoundedIcon from '@mui/icons-material/TerminalRounded';
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import AlignHorizontalCenterRoundedIcon from '@mui/icons-material/AlignHorizontalCenterRounded';
import AllInboxRoundedIcon from '@mui/icons-material/AllInboxRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import CloudSyncRoundedIcon from '@mui/icons-material/CloudSyncRounded';
import AutoModeRoundedIcon from '@mui/icons-material/AutoModeRounded';
import RuleFolderRoundedIcon from '@mui/icons-material/RuleFolderRounded';

import { profileRoutesMap } from 'routes/profileRoutesMap';

const profileEnum = [
  {
    path: profileRoutesMap.ACCOUNT,
    label: 'Account Details',
    icon: <AssignmentIndIcon />,
  },
  {
    path: profileRoutesMap.PROJECTS,
    label: 'Projects',
    icon: <AssignmentIcon />,
  },
  {
    path: profileRoutesMap.CUSTOMERS,
    label: 'Customers',
    icon: <Groups3Icon />,
  },

  //GENERAL_ADMINISTRATION
  {
    label: 'General Administration',
    icon: <ManageAccountsRoundedIcon />,
    list: [
      {
        path: profileRoutesMap.NOTIFICATIONS,
        label: 'Notifications',
        icon: <EditNotificationsRoundedIcon />,
      },
      {
        path: profileRoutesMap.BILLING_PLAN,
        label: 'Billing & Plan',
        icon: <CreditCardRoundedIcon />,
      },
      {
        path: profileRoutesMap.SECURITY,
        label: 'Security',
        icon: <SecurityRoundedIcon />,
      },
    ],
  },

  //USERS
  {
    label: 'Users',
    icon: <PeopleAltRoundedIcon />,
    list: [
      {
        path: profileRoutesMap.USER_PERMISSIONS,
        label: 'User Roles & Permissions',
        icon: <BadgeRoundedIcon />,
      },
      {
        path: profileRoutesMap.THIRD_PARTY_INTEGRATIONS,
        label: 'Third-Party Integrations',
        icon: <IntegrationInstructionsRoundedIcon />,
      },
    ],
  },

  //Assets & Inventory
  {
    label: 'Assets & Inventory',
    icon: <InventoryRoundedIcon />,
    list: [
      {
        path: profileRoutesMap.TEST_CONDITIONS,
        label: 'Test Conditions',
        icon: <HandymanRoundedIcon />,
      },
      {
        path: profileRoutesMap.ITEM_TYPES,
        label: 'Item Types',
        icon: <DeviceUnknownRoundedIcon />,
      },
      {
        path: profileRoutesMap.STATUSES,
        label: 'All Statuses',
        icon: <ListAltRoundedIcon />,
      },
      {
        path: profileRoutesMap.MAKES_MODELS,
        label: 'Makes/Models',
        icon: <DevicesOtherRoundedIcon />,
      },
      {
        path: profileRoutesMap.PROCESSORS,
        label: 'CPUs/Processors',
        icon: <MemoryRoundedIcon />,
      },
      {
        path: profileRoutesMap.SCREEN_TYPES,
        label: 'Screen Types',
        icon: <ScreenshotMonitorRoundedIcon />,
      },
      {
        path: profileRoutesMap.HDD_MEMORY,
        label: 'HDDs/Memory',
        icon: <SdCardRoundedIcon />,
      },
      {
        path: profileRoutesMap.OPERATING_SYSTEMS,
        label: 'Operating Systems',
        icon: <TerminalRoundedIcon />,
      },
      {
        path: profileRoutesMap.COLORS,
        label: 'Colors',
        icon: <ColorLensRoundedIcon />,
      },
      {
        path: profileRoutesMap.SERVICE_ITEMS,
        label: 'Service Items',
        icon: <ListAltRoundedIcon />,
      },
      {
        path: profileRoutesMap.SKUS,
        label: 'SKUs',
        icon: <ListAltRoundedIcon />,
      },
    ],
  },

  //Warehouse & Logistics
  {
    label: 'Warehouse & Logistics',
    icon: <WarehouseRoundedIcon />,
    list: [
      {
        path: profileRoutesMap.WAREHOUSES,
        label: 'Warehouses',
        icon: <WarehouseRoundedIcon />,
      },
      {
        path: profileRoutesMap.LOCATIONS,
        label: 'Locations',
        icon: <MyLocationRoundedIcon />,
      },
      {
        path: profileRoutesMap.AISLES,
        label: 'Aisles',
        icon: <AlignHorizontalCenterRoundedIcon />,
      },
      {
        path: profileRoutesMap.BINS,
        label: 'Bins',
        icon: <AllInboxRoundedIcon />,
      },
      {
        path: profileRoutesMap.CONTAINERS,
        label: 'Containers',
        icon: <Inventory2RoundedIcon />,
      },
      {
        path: profileRoutesMap.PALLETS,
        label: 'Pallets',
        icon: <AllInboxRoundedIcon />,
      },
      // {
      //   path: profileRoutesMap.SHIPPING_METHODS,
      //   label: 'Shipping Methods',
      //   icon: <LocalShippingRoundedIcon />,
      // },
    ],
  },

  //Compliance & Quality Control
  {
    label: 'Compliance & Quality Control',
    icon: <AssuredWorkloadRoundedIcon />,
    list: [
      {
        path: profileRoutesMap.AUDIT_LOGS,
        label: 'Audit Logs & Compliance Monitoring',
        icon: <BookRoundedIcon />,
      },
      {
        path: profileRoutesMap.ASSET_GRADING,
        label: 'Asset Grading & Valuation',
        icon: <DisplaySettingsRoundedIcon />,
      },
      {
        path: profileRoutesMap.SLAS,
        label: 'Service Level Agreements(SLAs)',
        icon: <HandshakeRoundedIcon />,
      },
      {
        path: profileRoutesMap.BACKUP_RECOVERY,
        label: 'Backup and Recovery',
        icon: <CloudSyncRoundedIcon />,
      },
    ],
  },

  //Process & documents
  {
    label: 'Process & Documents',
    icon: <GradingRoundedIcon />,
    list: [
      {
        path: profileRoutesMap.WORKFLOW_AUTOMATION,
        label: 'Workflow Automation',
        icon: <AutoModeRoundedIcon />,
      },
      {
        path: profileRoutesMap.DOCUMENT_MANAGEMENT,
        label: 'Document Management',
        icon: <RuleFolderRoundedIcon />,
      },
    ],
  },
];

export default profileEnum;
