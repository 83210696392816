import { Container, Grid2 } from '@mui/material'
import React, { memo } from 'react'

import SmallInfoBox from 'pages/components/public/SmallInfoBox';

const infoText = [
  {
    title: 'Our Partners',
    description: 'Master CRM collaborates with leading technology providers, consultants, and market experts to bring you a robust CRM solution. Our partners are an extension of our commitment to excellence.',
  },
  {
    title: 'Technology Partners',
    description: 'We team up with the best in tech to ensure that our platform runs smoothly, securely, and is always ahead of the curve.',
  },
  {
    title: 'Consulting Partners',
    description: 'Our consulting partners provide the strategic guidance and expertise needed to implement Master CRM effectively and tailor it to your unique business needs.',
  },
  {
    title: 'Become a Partner',
    description: "If you believe in driving business success through innovation, join our partner program. Let's grow together.",
  },
];

const PartnersInfo = () => {
  return (
    <Container maxWidth='xl' sx={{ py: { xs: 8, md: 10 } }}>
      <Grid2
        container
        spacing={{ xs: 3, md: 5 }}
        display='flex'
        justifyContent='center'
        alignItems='stretch'
      >
        {infoText.map((item, key) => (
          <Grid2 size={{ xs: 12, md: 6 }} key={key}>
            <SmallInfoBox title={item?.title} description={item?.description} />
          </Grid2>
        ))}
      </Grid2>
    </Container >
  )
};

export default memo(PartnersInfo);
