import React from 'react'

import PricingHero from './sections/PricingHero';
import CardsSection from './sections/CardsSection';

const PricingPage = () => {
  return <>
    <PricingHero />
    <CardsSection />
  </>
}

export default PricingPage;