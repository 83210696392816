import React, { useState, memo, useCallback, useMemo } from 'react';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { Box, Button, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Selector from 'common/input/Selector';
import { arrayToValueLabel } from 'utils/helperFunctions';

import SpinLoader from '../spinLoader/SpinLoader';
import { toNumber } from 'lodash';

const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];

function CustomPagination({ handlePageChange = () => { } }) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      page={toNumber(page) + 1}
      variant="text"
      size="small"
      count={pageCount}
      renderItem={(props2) => <PaginationItem {...props2} />}
      onChange={(event, value) => {
        apiRef.current.setPage(value - 1);
        handlePageChange(value);
      }}
      sx={{
        // mt: 2,
        '& .MuiPaginationItem-root': {
          borderRadius: '50%',
        },
        '& .Mui-selected': {
          bgcolor: 'primary.main',
          color: 'white',
          '&:hover': {
            bgcolor: 'primary.main',
          },
        },
      }}
    />
  );
}

function CustomFooter({
  paginationModel,
  setPaginationModel,
  handlePageChange = () => { },
  selectedRows,
  handleUnselectAll = () => { },
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column-reverse', sm: 'row' },
        flexWrap: 'wrap',
        width: '100%',
        gap: 2,
        px: 2,
        py: 0.5,
      }}
    >
      <Box display="flex" alignItems="center">
        {!!selectedRows &&
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="description">
              <Typography variant='p3' component='span' fontWeight={500}>
                {selectedRows}
              </Typography>
              &nbsp;rows selected
            </Typography>
            <Button variant='outlined' size='tiny' color='secondary' onClick={handleUnselectAll}>
              <Typography variant='description' fontWeight={500}>Unselect all</Typography>
            </Button>
          </Box>
        }

        {/* <Box display="flex" alignItems="center">
          <Typography sx={{ width: '95px' }} variant="description">
            Rows per page
          </Typography>
          <Selector
            sx={{ width: '70px' }}
            size="tiny"
            value={paginationModel.pageSize}
            onChange={(name, value) => {
              setPaginationModel((prev) => ({
                ...prev,
                pageSize: value,
              }));
            }}
            required
            disableStar
            selectorData={arrayToValueLabel(PAGE_SIZE_OPTIONS)}
          />
        </Box> */}
      </Box>
      <CustomPagination handlePageChange={handlePageChange} />
    </Box>
  );
}

const DataTable = ({
  density = 'compact',
  height = 'calc(100dvh - 200px)',
  maxHeight = 'unset',
  minHeight = '10px',
  hideFooter = false,
  columns = [],
  rowData,
  fullBorder = false,
  autoHeight = false,
  sx,
  showPageSize = false,
  initialState,
  isFullTable = false,
  invisibleColumns = {},
  slots = {},
  width = '100%',
  defaultPageSize = 30,
  disabled = false,
  loading = false,
  handlePageChange = () => { },
  onPaginationModelChange = () => { },
  onRowSelectionModelChange = () => { },
  totalSelectedRows = null,
  handleUnselectAll = () => { },
  paginationMode = 'client',
  ...rest
}) => {
  const [currentPageSize, setCurrentPageSize] = useState(0);
  const [selectedRows, setSelectedRows] = useState(0);

  const rows = useMemo(() => {
    if (!rowData?.id) {
      const rowWithId = rowData?.map((data, idx) => ({
        ...data,
        id: idx,
      }));
      return rowWithId || [];
    }
    return rowData;
  }, [rowData]);

  const CustomLoading = useCallback(
    () => (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <SpinLoader loading noBlur />
      </Box>
    ),
    [],
  );

  return (
    <Box
      sx={{
        height: autoHeight ? '100%' : height,
        maxHeight: maxHeight,
        minHeight: minHeight,
        width: width,
        minWidth: 150,
        position: 'relative',
        display: 'flex',
      }}
    >
      <DataGrid
        slots={{
          ...slots,
          footer: () => (
            <CustomFooter
              // paginationModel={paginationModel}
              // setPaginationModel={setPaginationModel}
              handlePageChange={handlePageChange}
              selectedRows={totalSelectedRows || selectedRows}
              handleUnselectAll={() => { handleUnselectAll(); setSelectedRows(0); }}
            />
          ),
          loadingOverlay: CustomLoading,
        }}
        onRowSelectionModelChange={(value) => {
          setSelectedRows(value?.length);
          onRowSelectionModelChange(value);
        }}
        autosizeOnMount
        autoPageSize={!autoHeight}
        rows={rows}
        columns={columns}
        autoHeight={autoHeight}
        initialState={{
          // pagination: { paginationModel: { pageSize: 10 } },
          columns: {
            columnVisibilityModel: {
              ...invisibleColumns,
              id: false,
            },
          },
          ...initialState,
        }}
        pageSizeOptions={isFullTable || showPageSize ? PAGE_SIZE_OPTIONS : []}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnFilter
        paginationMode={paginationMode}

        //use this to get the current page size to fetch from api
        onPaginationModelChange={(model) => {
          const { pageSize } = model;

          //restricting the onPaginationModelChange call to only when the page size changes by 2
          if (pageSize > currentPageSize + 2 || pageSize < currentPageSize - 2) {
            onPaginationModelChange(model);
            setCurrentPageSize(pageSize);
          }
        }}
        hideFooter={hideFooter ? hideFooter : paginationMode === 'client' && rows?.length <= 10}
        density={isFullTable ? 'standard' : density}
        loading={loading || disabled}
        sx={{
          fontSize: '11px',
          ...(fullBorder
            ? {}
            : {
              border: 'unset',
            }),
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'common.backgroundGrey',
          },
          borderRadius: 'unset',
          '& .MuiDataGrid-footerContainer': {
            minHeight: 'unset',
            border: 'none',
          },
          '& .MuiDataGrid-main': {
            border: 1,
            borderColor: 'common.borderGrey',
            borderRadius: '12px',
          },
          '& .MuiDataGrid-cell': {
            px: 0.5,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            backgroundColor: 'white',
          },
          '& .MuiDataGrid-selectedRowCount': {
            display: 'none',
          },
          ...sx,
        }}

        {...rest}
      />
    </Box>
  );
};

export default memo(DataTable);
