/* eslint-disable react/jsx-key */
import React, { useEffect, useState, memo, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import { detailColumn } from 'enum/tableColumnEnum';
import { assetsColumns } from 'pages/tableColumns/assetsColumns';
import ActionPageMain from 'pages/components/PageMain';
import createFormEnum from 'enum/createFormEnum';

import { Box, Button, Typography } from '@mui/material';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';

// import BulkImportDialog from 'pages/components/BulkImportDialog';
import AssetTestDialog from 'pages/components/assets/testComponents/AssetTestDialog';
import assetDetailContent from 'pages/components/detailDrawerComponents/inventoryDetails/assetDetailContent';
import TableFilters from 'pages/components/TableFilters';
import DynamicFilters from 'pages/components/DynamicFilters';

import AssetTransfer from 'pages/components/assets/AssetTransfer';

import AddToLoad from 'pages/components/assets/AddToLoad';
import BulkEdit from 'pages/components/assets/BulkEdit';
import ExportXlsx from 'pages/components/common/ExportXlsx';
import SsnSearch from 'pages/components/assets/SsnSearch';
import AssetsBulkImport from 'pages/components/assets/AssetsBulkImport';

// import useTableSelectData from 'hooks/useTableSelectData';
import useReactForm from 'hooks/useReactForm';

import { getModels } from 'api/listApis';
import { fetchLoads, addToList, fetchMakes, fetchWarehouses, fetchItemTypes, fetchAssetStatus, fetchWorkOrderList } from 'redux/slices/listSlice/listSlice';

import { crmRoutes } from 'routes/allRoutesMap';

import {
  setErrorDialogText,
  setSnackBar,
} from 'redux/slices/commonSlice/commonSlice';
import {
  updateAssets,
  getAssetDetails,
  getFilteredAssets,
} from 'api/assetsApi';
import { blancooSync } from 'api/masterApi';
import { map, chain, isEmpty, flatMap, mapValues, isArray } from 'lodash';
import AssetBarcode from 'pages/components/assets/barcodeGenerator/AssetBarcode';

import { useUrlParams } from 'hooks/useUrlParams';
import { useFetchAction } from 'hooks/useFetchAction';

const Assets = ({
  disableAddUpdate = false,
  disableActions = false,
  onRowSelection = () => { },
  onSelectReturnFullAsset = false,
  defaultSelectedAssetIndexes = [],
  children = null,
  label,
  onFilterSubmit = () => { },
  triggerFilterSubmit,
  filtersEnum = [],
  filtersEnumNoBox,
  assetsData = null,
  // overrideAssetData = false,
  tableLoading,
  preFillData,
  isMultiSelectFilters = true,
  drawerProps = {},
  isSmall = false,
  triggerAssetFetch = () => { },
  ...rest
}) => {
  const dispatch = useDispatch();
  const { assets } = useSelector((state) => state.actions);

  const [fetchedAssets, setFetchedAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState(fetchedAssets);
  const [currentFilterValues, setCurrentFilterValues] = useState({});
  // const [bulkDialogOpen, setBulkDialogOpen] = useState(false);
  const [testOpen, setTestOpen] = useState(false);
  const [bulkTestOpen, setBulkTestOpen] = useState(false);
  const [assetRowData, setAssetRowData] = useState({});
  // const [, setAssetFileHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState([]);
  const [editedValues, setEditedValues] = useState(null);
  const [assetDetailListContent, setAssetDetailListContent] = useState([]);
  const [isOpenBulk, setIsOpenBulk] = useState(false);

  const [enableClearFilterButton, setEnableClearFilterButton] = useState(false);

  const [urlParams, setUrlParams] = useUrlParams();
  const [paginationData, setPaginationData] = useState({});
  const [pageSize, setPageSize] = useState(20);
  const pageNumber = paginationData?.currentPage - 1 || urlParams?.pageNumber - 1 || 0;
  const [triggerApiReFetch, setTriggerApiReFetch] = useState(false);

  // const isFirstRender = useRef(true);

  // const tableSelectorData = useTableSelectData();
  const columns = assetsColumns();

  const reloadFetchedAssets = () => {
    const data = assetsData !== null ? assetsData : assets;
    setFetchedAssets(data);
  };

  useEffect(() => {
    reloadFetchedAssets();
  }, [assets, assetsData]);

  useEffect(() => {
    if (isEmpty(defaultSelectedAssetIndexes)) return;

    setDefaultSelectedIndex(defaultSelectedAssetIndexes);
    setSelectedIndexes(defaultSelectedAssetIndexes);
  }, []);

  useEffect(() => {
    setFilteredAssets(fetchedAssets);
  }, [fetchedAssets]);

  const { formData, handleSubmit, reset } = useReactForm();

  useEffect(() => {
    if (triggerFilterSubmit) {
      handleSubmit(onFilterSubmit)();
    }
  }, [triggerFilterSubmit]);

  const [modelList, setModelList] = useState(null);

  const { userProjects = [] } = useSelector((state) => state?.userDetails);

  const assetGrades = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
  ];
  const projectKey = 'project';

  const { loads, makes } =
    useSelector((state) => state.lists);

  const warehouses = useFetchAction(state => state?.lists?.warehouses, fetchWarehouses);
  const itemTypes = useFetchAction(state => state?.lists?.itemTypes, fetchItemTypes);
  const assetStatus = useFetchAction(state => state?.lists?.assetStatus, fetchAssetStatus);
  const workOrderList = useFetchAction(state => state?.lists?.workOrderList, fetchWorkOrderList);

  //fetching filter lists / need to create a reusable component that can generate formSelector map
  useEffect(() => {
    isEmpty(makes) && dispatch(fetchMakes());
  }, [])

  const filterSelectorEnum = useMemo(
    () => [
      {
        name: 'itemType',
        label: 'Item Type',
        data: itemTypes,
        multiple: isMultiSelectFilters,
      },
      {
        name: 'assetStatus',
        label: 'Asset Status',
        data: assetStatus,
        multiple: isMultiSelectFilters,
      },
      {
        name: 'make',
        label: 'Make',
        data: makes,
        multiple: isMultiSelectFilters,
      },
      {
        name: 'model',
        label: 'Model',
        data: modelList,
        multiple: isMultiSelectFilters,
      },
      {
        name: projectKey,
        label: 'Projects',
        data: userProjects,
        multiple: isMultiSelectFilters,
      },
      {
        name: 'load',
        label: 'Loads',
        data: loads,
        multiple: isMultiSelectFilters,
      },
      {
        name: 'workOrder',
        label: 'Work Orders',
        data: workOrderList,
        multiple: isMultiSelectFilters,
      },
      {
        name: 'warehouse',
        label: 'Warehouse',
        data: warehouses,
        multiple: isMultiSelectFilters,
      },
      {
        name: 'grade',
        label: 'Grade',
        data: assetGrades,
        multiple: isMultiSelectFilters,
      },
      {
        name: 'receivedDateFrom',
        date: true,
      },
      {
        name: 'receivedDateTo',
        date: true,
      },
    ],
    [
      isMultiSelectFilters,
      assetStatus,
      makes,
      userProjects,
      warehouses,
      workOrderList,
      loads,
      itemTypes,
      modelList,
      currentFilterValues
    ],
  );

  //fetch models and loads based on make and project filter values
  const updateFilterSelectors = async (filterValues) => {

    console.log(filterValues, 'filterValues');

    //wrap the value in array as the api expects array
    const wrappedInArray = mapValues(filterValues, (value, key) =>
      isArray(value) ? value : [value]
    );

    if (!isEmpty(wrappedInArray?.make)) {
      const makeIds = wrappedInArray?.make;
      const res = await getModels(makeIds);
      setModelList(res);

    }
    if (!isEmpty(wrappedInArray?.[projectKey])) {
      console.log(wrappedInArray?.[projectKey], 'wrappedInArray?.[projectKey]');

      const projectIds = wrappedInArray?.[projectKey];
      const res = await dispatch(fetchLoads(projectIds));

      if (res?.error) {
        dispatch(addToList({ loads: [] }));
      }
    }
  };

  //fetch filter values on page load
  useEffect(() => {
    updateFilterSelectors(urlParams);
  }, [])

  //fetch filter values on filter field value change
  useEffect(() => {
    updateFilterSelectors(currentFilterValues);
  }, [currentFilterValues?.make, currentFilterValues?.[projectKey]]);

  const handleCellDoubleClick = async (clickedCell) => {
    if (clickedCell?.field !== 'model' && clickedCell?.field !== 'load') return;
    setLoading(true);
    const isModel = clickedCell?.field === 'model';

    if (isModel) {
      const cellId =
        !!makes &&
        makes?.find((value) => value?.value === clickedCell?.row?.make)?.id;
      const resData = await getModels(cellId);
      dispatch(addToList({ models: resData }));
    } else {
      const cellId =
        !!userProjects &&
        userProjects?.find(
          (value) => value?.value === clickedCell?.row?.project,
        )?.id;
      await dispatch(fetchLoads(cellId));
    }

    setLoading(false);
  };

  //need to move this in a separate component
  const handleBlancooClick = () => {
    if (isEmpty(flatMap(selectedAssetIds))) {
      dispatch(
        setErrorDialogText('Please select at least one asset to proceed.'),
      );
      return;
    }

    blancooSync({ assetId: flatMap(selectedAssetIds) }).then(() => {
      dispatch(
        setSnackBar({
          open: true,
          message: `Blancoo data successfully synced!`,
        }),
      );

      setTriggerApiReFetch(!triggerApiReFetch);
    });
  };

  const acceptableStatuses = [
    'Pending Blanco Test',
    'Pending Blancoo Test',
    'Pending Condition Test',
    'Received',
    'New',
    'Collected',
    'Allocated',
    'Available',
    'In Repair',
  ];

  const fetchAssetDetailsAfterUpdate = (updatedData = {}) => {
    setTimeout(() => {
      triggerAssetFetch();
      setTriggerApiReFetch(!triggerApiReFetch);
    }, 20);
  };

  const handleSortApply = (sortModel) => {
    if (isEmpty(sortModel)) {
      const { sort, ...rest } = urlParams;
      setUrlParams(rest);
      return;
    }

    const { field, sort } = sortModel?.[0];

    setUrlParams({
      ...urlParams,
      sort: JSON.stringify({
        field,
        sortOrder: sort
      }),
    });
  }

  return (
    <>
      <AssetTestDialog
        open={testOpen || bulkTestOpen}
        assetData={assetRowData}
        assetIds={flatMap(selectedAssetIds)}
        handleClose={() => {
          setTestOpen(false);
          setBulkTestOpen(false);
        }}
        isBulk={bulkTestOpen}
        triggerAssetFetch={fetchAssetDetailsAfterUpdate}
      />
      <AssetsBulkImport isOpen={isOpenBulk} setIsOpen={setIsOpenBulk} />
      <ActionPageMain
        formKey={createFormEnum.assets}
        preFillData={preFillData}
        rows={filteredAssets}
        // fetchedData={assets}
        columns={columns}
        label={`${label || 'Assets'} (${paginationData?.totalRecords || filteredAssets.length})`}
        createLabel="Create Assets"
        createButtonLabel="Add Assets"
        createFormProps={{
          //by default createDialog triggers redux dispatch api call so we can pass disableDispatchCall if we need normal api call
          disableDispatchCall: true,
          callback: () => setTriggerApiReFetch(!triggerApiReFetch),
        }}
        fetchByIdApi={async (id) => await assetDetailContent(id, fetchAssetDetailsAfterUpdate)}
        detailDataFetchIdKey="ssn"
        pagePath={crmRoutes.ASSETS_PATH}
        onDetailDataFetch={setAssetDetailListContent}
        preFillUpdateData={assetRowData}
        clickRowData={(row) => {
          if (!row) return;
          setAssetRowData(row?.['Asset Info']);
        }}
        drawerProps={{
          drawerLabel: `SSN: ${assetRowData?.ssn || ''}`,
          handleUpdate: fetchAssetDetailsAfterUpdate,
          listChildren: assetDetailListContent || {},
          ...drawerProps,
        }}
        // fetchApi={fetchAllAssets}
        isMultiAdd
        checkboxSelection
        rowSelectionModel={assetsData === null ? selectedIndexes[pageNumber] || [] : selectedIndexes}
        onRowSelectionModelChange={(value) => {
          if (!isEmpty(defaultSelectedIndex)) {
            setDefaultSelectedIndex([]);
            return;
          }

          const asset = map(value, (item) => filteredAssets[item]);
          const assetId = map(value, (item) => filteredAssets[item]?.assetID);

          //if assetsData is null then it means we are using dynamic pagination and filtration
          if (assetsData === null) {
            const allCheckedAssets = flatMap({ ...selectedAssets, [String(pageNumber)]: [...asset] });
            const allCheckedAssetsIds = flatMap({ ...selectedAssetIds, [String(pageNumber)]: [...assetId] });

            setSelectedIndexes(prev => ({ ...prev, [String(pageNumber)]: [...value] }));
            setSelectedAssets(prev => ({ ...prev, [String(pageNumber)]: [...asset] }));
            setSelectedAssetIds(prev => ({ ...prev, [String(pageNumber)]: [...assetId] }));
            onRowSelection(onSelectReturnFullAsset ? allCheckedAssets : allCheckedAssetsIds, value);

          } else {
            setSelectedIndexes(value);
            onRowSelection(onSelectReturnFullAsset ? asset : assetId, value);
            setSelectedAssetIds(assetId);
            setSelectedAssets(asset);
          }
        }}
        disableAddUpdate={disableAddUpdate}
        // conditionally adding extra buttons
        {...(!disableAddUpdate && !disableActions
          ? {
            extraButtons: [
              <SsnSearch
                assetsData={assets}
                handleAssetSearch={(searchedAssets) => {
                  setFetchedAssets(searchedAssets);
                  setEnableClearFilterButton(true);
                }}
              />,

              <Box onClick={() => setIsOpenBulk(true)}>
                <UploadFileRoundedIcon />
                Bulk Import
              </Box>,

              <AddToLoad
                selectedAssets={flatMap(selectedAssetIds)}
                setTableLoading={setLoading}
              />,

              <Box
                onClick={handleBlancooClick}
                display="flex"
                alignItems="center"
              >
                <SyncRoundedIcon />
                Blancoo-sync
              </Box>,

              <AssetBarcode selectedAssets={flatMap(selectedAssets)} />,

              <BulkEdit
                bulkItems={flatMap(selectedAssetIds)}
                editedValues={editedValues}
                formKey={createFormEnum.assets}
                updateApi={updateAssets}
                apiPayloadObj={({ ids, updateData }) => ({
                  assetIds: ids,
                  updateFields: updateData,
                })}
                setEditedValues={setEditedValues}
              />,

              <AssetTransfer assetIDs={flatMap(selectedAssetIds)} />,

              <Box
                onClick={() => {
                  if (isEmpty(flatMap(selectedAssetIds))) {
                    dispatch(
                      setErrorDialogText(
                        'Please select at least one asset to proceed.',
                      ),
                    );
                    return;
                  }
                  setBulkTestOpen(true);
                }}
                display="flex"
                alignItems="center"
              >
                <HandymanRoundedIcon />
                Bulk Test
              </Box>,

              <ExportXlsx rows={filteredAssets} columns={columns} />,
            ],
          }
          : {})}
        tableProps={{
          onCellDoubleClick: handleCellDoubleClick,
          loading: loading || tableLoading,

          //Start --- dynamic filtration and pagination props (FIND A WAY TO ADD ALL THIS TO PAGEMAIN COMPONENT FOR REUSABILITY)
          onPaginationModelChange: (model) => setPageSize(model?.pageSize),
          handlePageChange: (pageNumber) => setUrlParams({ ...urlParams, pageNumber: pageNumber }),
          paginationMode: 'server',
          filterMode: 'server',
          rowCount: paginationData?.totalRecords || 1,
          paginationModel: { pageSize: pageSize, page: pageNumber },
          disableSelectAllCheckbox: true,
          totalSelectedRows: flatMap(selectedIndexes)?.length || null,
          handleUnselectAll: () => {
            setSelectedIndexes([]);
            setSelectedAssets([]);
            setSelectedAssetIds([]);
          },
          sortingMode: 'server',
          // sortModel: urlParams?.sort ? [{ field: urlParams?.sort?.field, sort: urlParams?.sort?.sortOrder }] : [],
          onSortModelChange: handleSortApply,
          //End --- dynamic filtration and pagination props 

          ...(disableAddUpdate
            ? {}
            : {
              tableActionItem: (row) => {
                const showTestButton = acceptableStatuses.includes(
                  row?.assetStatus,
                );
                return (
                  <>
                    {showTestButton && (
                      <Button
                        variant="contained"
                        disableElevation
                        sx={{
                          minWidth: '10px',
                          height: '30px',
                        }}
                        onClick={() => {
                          setAssetRowData(row);
                          setTestOpen(true);
                        }}
                      >
                        <Typography variant="description">
                          {row?.grade ? 'Re-test' : 'Test'}
                        </Typography>
                      </Button>
                    )}
                  </>
                );
              },
            }),
        }}
        {...rest}
      >
        {assetsData !== null ?
          <TableFilters
            filterSelectorEnum={
              !isEmpty(filtersEnum) ? filtersEnum : filterSelectorEnum
            }
            filterSelectorEnumNoBox={filtersEnumNoBox}
            data={fetchedAssets}
            formData={formData}
            resetFields={() => reset({})}
            handleChange={(filteredData, currentFilterValue) => {
              setFilteredAssets(filteredData);
              setCurrentFilterValues(currentFilterValue);
              setSelectedIndexes([]);
            }}
            enableClearFilter={enableClearFilterButton}
            clearFilterCallback={() => {
              reloadFetchedAssets();
              setEnableClearFilterButton(false);
            }}
            isSmall={isSmall}
          />
          :
          <DynamicFilters
            filterSelectorEnum={filterSelectorEnum}
            filterSelectorEnumNoBox={filtersEnumNoBox || [
              {
                name: 'ssn',
                label: 'SSN',
                isInput: true,
              }
            ]}
            fetchApi={getFilteredAssets}
            pageSize={pageSize}
            handleChange={(filteredData, paginationValues) => {
              setPaginationData(paginationValues);
              setFilteredAssets(filteredData?.[0] === null ? [] : filteredData);
            }}
            handleFieldChange={(value) => setCurrentFilterValues(value)}
            setLoading={setLoading}
            triggerApiReFetch={triggerApiReFetch}
          />
        }

        {children}
      </ActionPageMain >
    </>
  );
};

export default memo(Assets);
