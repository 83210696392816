export const crmPaths = {
  HOME_PATH: '/',
  LEADS_PATH: 'leads',
  LOADS_PATH: 'loads',
  GRA_PATH: 'gra',
  CONTACTS_PATH: 'contacts',
  // ACCOUNTS_PATH: "accounts",
  DEALS_PATH: 'deals',
  TASKS_PATH: 'tasks',
  REPORTS_PATH: 'reports',
  CUSTOM_REPORTS_PATH: 'custom-reports',
  FILTERED_REPORTS_PATH: 'filtered-reports',
  ANALYTICS_PATH: 'analytics',
  PRODUCTS_PATH: 'products',
  ASSETS_PATH: 'assets',
  QUOTES_PATH: 'quotes',
  SALES_ORDERS_PATH: 'sales-orders',
  PURCHASE_ORDERS_PATH: 'purchase-orders',
  PAYMENT_ORDERS_PATH: 'payment-orders',
  DISPATCH_ORDERS_PATH: 'dispatch-orders',
  WORK_ORDERS_PATH: 'work-orders',
  INVOICES_PATH: 'invoices',
  VENDORS_PATH: 'vendors',
  SUPPLIERS_PATH: 'suppliers',
  PROJECTS_PATH: 'projects',
  ASSET_RECYCLE_REPORT_PATH: 'asset-recycle-report',
  TIME_SHEET_PATH: 'time-sheets',
};

export const sideBarPaths = {
  BUSINESS_DASHBOARD_PATH: 'business-dashboard',
  ANALYTICS_DASHBOARD_PATH: 'analytics-dashboard',
  ORDERS_PATH: 'orders',
  INVENTORY_PATH: 'inventory',
  CUSTOMERS_PATH: 'customers',
  CALENDAR_EVENTS_PATH: 'calendar-events',
};
