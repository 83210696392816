import React, { memo, useState } from 'react'

import { Box, Button, Container, AppBar, Typography, IconButton } from '@mui/material';
import master_crm_logo from 'assets/master-crm-logo.png';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';

import { useNavigate } from 'react-router-dom';

import { navMap } from './mapping/navMap';
import { otherRoutes } from 'routes/allRoutesMap';

import useScreenSize from 'hooks/useScreenSize';

import MobilePublicDrawer from './MobilePublicDrawer';

export const PublicNavbar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const nav = useNavigate();

  const { isTablet } = useScreenSize();

  const handleNav = (path) => {
    nav(path);
    window.scrollTo(0, 0);
  }

  return <>
    <MobilePublicDrawer
      open={openDrawer}
      handleClose={() => setOpenDrawer(false)}
      handleNav={handleNav}
    />

    <Box minHeight={isTablet ? '64px' : '80px'} />
    <AppBar
      sx={{
        py: 1,
        bgcolor: 'white',
        minHeight: isTablet ? '64px' : '80px'
      }}
    >
      <Container maxWidth='xl'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Box
            display='flex'
            alignItems='center'
            sx={{
              gap: { xs: 1, md: 2 },
            }}
          >
            <Box
              component='img'
              src={master_crm_logo}
              sx={{
                width: { xs: '44px', md: '64px' },
                '&:hover': { cursor: 'pointer' }
              }}
              onClick={() => handleNav(otherRoutes.LOGIN)}
            />
            <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
              <IconButton
                size='large'
                color='secondary'
                onClick={() => setOpenDrawer(true)}
              >
                <MenuIcon sx={{ scale: 1.3 }} />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              gap: 3,
              display: { xs: 'none', lg: 'flex' },
            }}
          >
            {navMap?.map((item, key) => (
              <Button
                key={key}
                onClick={() => handleNav(item?.path)}
                disableRipple
                color='secondary'
                size='large'
                sx={{
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    color: 'primary.main'
                  }
                }}
              >
                <Typography variant='p' fontWeight='500'>{item?.label}</Typography>
              </Button>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: { xs: 1, md: 2 },
            }}
          >
            <Button
              variant='outlined'
              color='secondary'
              size={isTablet ? 'medium' : 'large'}
              startIcon={<PersonIcon />}
              onClick={() => handleNav(otherRoutes.LOGIN)}
            >
              Login
            </Button>
            <Button
              variant='contained'
              size={isTablet ? 'medium' : 'large'}
              onClick={() => handleNav(otherRoutes.SIGNUP)}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Container>
    </AppBar>
  </>
}

export default memo(PublicNavbar);