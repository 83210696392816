import React, { memo } from 'react'

import { Box, Button, Typography } from '@mui/material';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { transitions } from 'core/transitions';

const PricingCard = ({
  cardContent = {},
}) => (
  <Box
    sx={{
      borderRadius: '20px',
      minHeight: { xs: '500px', md: '650px' },
      maxWidth: '470px',
      height: '100%',
      backgroundColor: '#F8F9FB',
      pb: { xs: 2, md: 5 },
      pt: { xs: 2, md: 4 },
      px: { xs: 2, md: 3 },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      transition: transitions().short,
      outline: theme => `0px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 0px 0px 0px #0000003b',
      '&:hover': {
        outline: theme => `4px solid ${theme.palette.primary.main}`,
        boxShadow: '20px 34px 0px 0px #0000003b',
      }
    }}
  >
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: { xs: 2, md: 4 },
      }}
      >
        <Box>
          <Typography
            component='div'
            mb={1}
            variant='p'
            sx={{
              fontSize: '20px',
              fontWeight: '600',
            }}
          >
            {cardContent.title}
          </Typography>
          <Typography
            variant='p2'
            sx={{
              color: 'common.textLight',
            }}
          >
            {cardContent.description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant='p'
            sx={{
              fontSize: { xs: '24px', md: '32px' },
              fontWeight: '600',
            }}
          >
            ${cardContent.price}/
          </Typography>
          <Typography
            variant='p'
            fontWeight='500'
          >
            Month
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mt: { xs: '20px', md: '30px' } }}>
        {cardContent?.features.map((feature, key) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: { xs: 1, md: 1.5 },
              mt: { xs: 1, md: 2 },
            }}
          >
            <CheckBoxRoundedIcon
              sx={{
                fontSize: '20px',
                color: 'common.backgroundBlue',
              }}
            />
            <Typography
              variant='p'
              sx={{
                fontSize: { xs: '14px', md: '16px' }
              }}
            >
              {feature}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>

    <Box pt={4}>
      <Button
        variant='contained'
        color='primary'
        size='large'
        fullWidth
        sx={{ height: { xs: '48px', lg: '60px' } }}
      >
        <Typography variant='p' fontSize='18px' fontWeight='600' color='white'>Select</Typography>
      </Button>
    </Box>
  </Box>
);

export default memo(PricingCard);