import React from 'react';

import { Grid } from '@mui/material';

import CustomImport from 'components/customImport/CustomImport';
import { assetImportFields } from 'components/customImport/importFields/assetImportFields';
import CustomerProjectSelectors from 'components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors';

import { importAssets } from 'redux/slices/actionSlice/assetSlice';
import { getLoadsList } from 'api/listApis';
import DynamicSelector from 'components/createFormComponents/dynamicSelectorFields/DynamicSelector';
import DynamicActionSelector from 'components/createFormComponents/dynamicSelectorFields/DynamicActionSelector';
import { fetchWorkOrderList } from 'redux/slices/listSlice/listSlice';

export default function AssetsBulkImport({
  isOpen = false,
  setIsOpen = () => { },
}) {
  return (
    <CustomImport
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dispatchApi={importAssets}
      internalImportFields={assetImportFields}
      setMapFields={(formData) => (
        <Grid container spacing={2.5}>
          <CustomerProjectSelectors formData={formData} md={4} sm={4} xs={12} />
          <Grid item md={4} sm={4} xs={12}>
            <DynamicSelector
              formData={formData}
              name="loadNo"
              label="Load"
              returnLabel
              fetchId={formData?.getValues('projectId')}
              fetchListApi={getLoadsList}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <DynamicActionSelector
              formData={formData}
              name="workOrderNo"
              label="Work Order"
              returnLabel
              fetchListAction={fetchWorkOrderList}
              getStateValue={state => state?.lists?.workOrderList}
            />
          </Grid>
        </Grid>
      )}
    />
  );
}
