import React, { memo } from 'react'

import { Typography, Box, Container, Button, Grid2 } from '@mui/material';
import { transitions } from 'core/transitions';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

const featureContent = [
  {
    aosDelay: "0",
    heading: 'Inventory and Warehouse Management',
    description: 'Effectively manage inventory levels, product details, and warehouse status to ensure smooth operations and availability.',
    featureList: [
      'Add and manage stock levels and product information.',
      'Access real-time information on inventory levels and warehouse status.',
      'Associate inventory with products, orders, and track warehouse movements.',
    ]
  },
  {
    aosDelay: "100",
    heading: 'Scheduler and Task Management',
    description: 'Streamline your workflow with an interactive calendar and task management system to keep your team organized and efficient.',
    featureList: [
      'Schedule meetings and tasks with reminders.',
      'View and manage activities through an interactive calendar.',
      'Assign tasks to team members and track progress.',
    ]
  },
  {
    aosDelay: "200",
    heading: 'Vendor and Order Management',
    description: 'Optimize your procurement and sales processes with robust vendor and order management capabilities.',
    featureList: [
      'Generate and manage sales, purchase, and work orders.',
      'Access detailed order information, including quantities, pricing, and status.',
      'Modify order details based on changes or negotiations.',
    ]
  },
  {
    aosDelay: "300",
    heading: 'Account and Customer Management',
    description: 'Gain a 360-degree view of customer accounts with detailed profiles and transaction history to enhance relationship management.',
    featureList: [
      'Add and manage customer accounts and organizations.',
      'Access detailed profiles including transaction history and preferences.',
      'Deactivate or remove obsolete accounts to maintain data accuracy.',
    ]
  },
  {
    aosDelay: "400",
    heading: 'Product and Catalog Management',
    description: 'Efficiently manage your product catalog with detailed product information, pricing, and availability to meet market demands.',
    featureList: [
      'Add new products or services to the catalog.',
      'View and update detailed product information and specifications.',
      'Associate products with assets, quotes, orders, and invoices.',
    ]
  },
  {
    aosDelay: "500",
    heading: 'Quote Generation and Management',
    description: 'Simplify the process of creating and managing quotes for products and services, ensuring accuracy and efficiency in negotiations.',
    featureList: [
      'Generate quotes for products or services quickly.',
      'View and compare multiple quotes to find the best options.',
      'Modify quote details based on customer negotiations.',
    ]
  },
];

const FeatureBox = ({ content = {} }) => {
  const { heading, description, featureList } = content;

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F8F9FB',
        border: '1px solid #D0D3D6',
        borderRadius: '20px',
        p: { xs: 3, md: 5 },
        transition: transitions().common,
        '&:hover': {
          borderColor: 'primary.main',
        }
      }}
      data-aos="fade-down"
      data-aos-delay={content?.dataAos?.aosDelay}
    >
      <Typography
        component='div'
        variant='p'
        sx={{
          fontSize: { xs: '22px', md: '26px' },
          lineHeight: { xs: '25px', md: '30px' },
          fontWeight: '600',
        }}
      >
        {heading}
      </Typography>

      <Typography
        component='div'
        variant='p'
        color='common.textLight'
        sx={{
          lineHeight: '24px',
          mt: 2.5,
        }}
      >
        {description}
      </Typography>

      <Typography
        component='div'
        variant='p'
        color='common.textLight'
        sx={{
          fontSize: { xs: '18px', md: '20px' },
          fontWeight: '600',
          my: { xs: 3, md: 4 },
        }}
      >
        Popular Features
      </Typography>

      {featureList?.map((item, key) => (
        <Box
          key={key}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <CheckBoxRoundedIcon
            sx={{
              fontSize: '20px',
              color: 'common.backgroundBlue',
              transform: 'translateY(1px)',
            }}
          />
          <Typography
            component='div'
            variant='p'
            sx={{
              lineHeight: '24px',
              mb: 1.5,
            }}
          >
            {item}
          </Typography>
        </Box>
      ))}

      <Box
        mt={4}
        flexGrow='1'
        display='flex'
        alignItems='flex-end'
      >
        <Button
          fullWidth
          variant='contained'
          color='primary'
          size='large'
          sx={{
            minHeight: { xs: '48px', md: '56px' },
          }}
        >
          <Typography variant='p' fontSize='18px'>Learn More</Typography>
        </Button>
      </Box>
    </Box>
  )
}

const Features = () => {
  return (
    <Container
      maxWidth='xl'
      sx={{
        py: { xs: 7, lg: 13 },
      }}
    >
      <Typography
        component='div'
        textAlign='center'
        variant='h2p'
        sx={{
          fontSize: { xs: '36px', md: '48px' }
        }}
        fontWeight='600'
      >
        Features of MasterCRM
      </Typography>

      <Grid2
        container
        spacing={{ xs: 3, md: 5 }}
        sx={{
          mt: { xs: 4, md: 7 },
          display: 'flex',
          alignItems: 'stretch',
        }}
      >
        {featureContent?.map((content, key) => (
          <Grid2 size={{ xs: 12, sm: 6 }} key={key}>
            <FeatureBox content={content} />
          </Grid2>
        ))}
      </Grid2>
    </Container>
  )
}

export default memo(Features);