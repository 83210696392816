import React, { memo } from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconButton } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';


const Carousel = ({
  children,
  ...rest
}) => {

  const SlickButton = memo(({
    onClick,
    isRight,
    slideCount,
    currentSlide
  }) => {
    if (rest?.infinite === false) {
      if (!isRight) {
        if (currentSlide === slideCount - 1) {
          return '';
        }
      } else {
        if (currentSlide === 0) {
          return '';
        }
      }
    }

    return (
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 10,
          ...(!isRight ? { right: { xs: '-35px', md: '-50px' } } : { left: { xs: '-35px', md: '-50px' } }),
          background: '#ff55387d',
          '&:hover': {
            background: theme => theme.palette.primary.main,
          },
          width: { xs: '26px', md: '36px' },
          height: { xs: '26px', md: '36px' },
        }}
        size='small'
        color='primary'
        onClick={onClick}
      >
        <ArrowLeftIcon
          sx={{
            transform: `rotate(${!isRight ? '180deg' : '0deg'})`,
            scale: { xs: '1.4', md: '1.7' },
            color: 'white',
          }} />
      </IconButton>
    );
  });

  const defaultSettings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SlickButton />,
    prevArrow: <SlickButton isRight />,
  };

  return (
    <Slider {...defaultSettings} {...rest}>
      {children}
    </Slider>
  )
}

export default memo(Carousel);
