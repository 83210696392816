import { React } from 'react';
import Grid from '@mui/material/Grid';
import InputField from 'common/input/InputField';
import SearchSelect from 'common/input/SearchSelect';
import { fetchItemTypes, fetchMakes } from 'redux/slices/listSlice/listSlice';
import { useFetchAction } from 'hooks/useFetchAction';

const ModelForm = ({ formData = {}, isUpdate = false }) => {
  const gridItemSize = {
    xs: 12,
  };

  const itemTypes = useFetchAction(state => state?.lists?.itemTypes, fetchItemTypes);
  const makes = useFetchAction(state => state?.lists?.makes, fetchMakes);

  return (
    <Grid py={2} container rowSpacing={2} columnSpacing={2}>
      <Grid {...gridItemSize} item>
        <SearchSelect
          formData={formData}
          name="makeId"
          label="Make"
          required
          searchSelectData={makes}
        />
      </Grid>

      <Grid {...gridItemSize} item>
        <InputField
          formData={formData}
          name="modelName"
          label="Model"
          required
        />
      </Grid>

      <Grid {...gridItemSize} item>
        <SearchSelect
          formData={formData}
          name="itemTypeId"
          label="Product Type"
          required
          searchSelectData={itemTypes}
        />
      </Grid>
    </Grid>
  );
};

export default ModelForm;
