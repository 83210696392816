import React, { useState, useEffect, memo, useCallback } from 'react'

import SearchSelect from 'common/input/SearchSelect';
import { isArray, isEmpty } from 'lodash';

const DynamicSelector = ({
  createForm,
  fetchId = null,
  fetchListApi = () => { },
  directFetch = false,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const [selectorList, setSelectorList] = useState([]);

  const performFetch = useCallback(() => {
    setLoading(true);
    fetchListApi(fetchId)
      ?.then(setSelectorList)
      ?.finally(() => setLoading(false));
  }, [fetchId]);

  useEffect(() => {
    if (!directFetch) {
      if (isArray(fetchId)) {
        if (isEmpty(fetchId) || !fetchId?.[0]) {
          setSelectorList([]);
          return;
        };
      }

      if (!fetchId) {
        setSelectorList([]);
        return;
      };
    }

    performFetch();
  }, [fetchId]);

  return (
    <SearchSelect
      loading={loading}
      searchSelectData={selectorList}
      {...rest}
      disabled={isEmpty(selectorList)}
    />
  );
}

export default memo(DynamicSelector);