import React, { useMemo, useState } from 'react';

import { isEmpty, keys, values } from 'lodash';
import { Typography, Box, Grid, Chip } from '@mui/material';
import { camelCaseToSpace } from 'utils/textFormatUtils';

import PaperBox from 'common/ui/PaperBox';

import WorkOrderDialog from 'components/createFormComponents/workOrder/WorkOrderDialog';
import SalesOrders from 'pages/actionPages/orders/SalesOrders';
import DispatchOrders from 'pages/actionPages/orders/DispatchOrders';
import PurchaseOrders from 'pages/actionPages/orders/PurchaseOrders';
import Invoices from 'pages/actionPages/invoice/Invoices';

import Loads from 'pages/actionPages/inventory/Loads';

const orderLabelMap = {
  workOrder: 'Work orders',
  salesOrder: 'Sales orders',
  dispatchOrder: 'Dispatch Orders',
  purchaseOrder: 'Purchase Orders',
  loadNo: 'Loads',
  invoiceNo: 'Invoices',
};

const defaultIdState = {
  workOrder: null,
  salesOrder: null,
  dispatchOrder: null,
  purchaseOrder: null,
  loadNo: null,
  invoiceNo: null,
};

// purchaseOrder: { id: 1, label: 'PO00001' },
// loadNo: { id: 1087, label: 'LOAD053' },
// invoiceNo: { id: 1, label: 'INV001' },

const AssetOrders = ({ orderData = {} }) => {
  const [fetchId, setFetchId] = useState(defaultIdState);

  if (isEmpty(orderData)) return;

  const data = {
    ...orderData,
    loadNo: [{ id: 1088, value: 'LOAD054' }],
    purchaseOrder: [{ id: 1, value: 'PO00001' }],
    invoiceNo: [{ id: 1, value: 'INV001' }],
  };

  const ChipButton = ({ objKey }) => {
    if (isEmpty(data?.[objKey]))
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography>No items</Typography>
        </Box>
      );

    return (
      <Box display="flex" flexWrap="wrap" gap={2}>
        {data?.[objKey]?.map((order, key) => (
          <Chip
            key={key}
            label={
              <Typography variant="p3" fontWeight="500">
                {order?.value}
              </Typography>
            }
            variant="outlined"
            color="primary"
            onClick={() =>
              setFetchId((prev) => ({
                ...prev,
                [objKey]: { id: order?.id, label: order?.value },
              }))
            }
          />
        ))}
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={1}>
        {keys(orderData)?.map((objKey, key) => {
          return (
            <Grid key={key} item sm={6} xs={12}>
              <PaperBox
                white
                sx={{
                  height: '100%',
                  minHeight: '120px',
                }}
                label={orderLabelMap?.[objKey] || ''}
              >
                <ChipButton objKey={objKey} />
              </PaperBox>
            </Grid>
          );
        })}
      </Grid>

      <WorkOrderDialog
        workOrderId={fetchId?.workOrder?.id}
        openDrawer={!!fetchId?.workOrder?.id}
        setOpenDrawer={() => setFetchId(defaultIdState)}
      />

      <SalesOrders
        onlyDetailsDrawer
        fetchApiId={fetchId?.salesOrder?.id}
        handleDrawerClose={() => setFetchId(defaultIdState)}
        drawerProps={{
          open: !!fetchId?.salesOrder?.id,
          drawerLabel: `Sale Order: ${fetchId?.salesOrder?.label}`,
        }}
      />

      <DispatchOrders
        onlyDetailsDrawer
        fetchApiId={fetchId?.dispatchOrder?.id}
        handleDrawerClose={() => setFetchId(defaultIdState)}
        drawerProps={{
          open: !!fetchId?.dispatchOrder?.id,
          drawerLabel: `Dispatch Order: ${fetchId?.dispatchOrder?.label}`,
        }}
      />

      <PurchaseOrders
        onlyDetailsDrawer
        fetchApiId={fetchId?.purchaseOrder?.id}
        handleDrawerClose={() => setFetchId(defaultIdState)}
        drawerProps={{
          open: !!fetchId?.purchaseOrder?.id,
          drawerLabel: `Purchase Order: ${fetchId?.purchaseOrder?.label}`,
        }}
      />

      <PurchaseOrders
        onlyDetailsDrawer
        fetchApiId={fetchId?.invoiceNo?.id}
        handleDrawerClose={() => setFetchId(defaultIdState)}
        drawerProps={{
          open: !!fetchId?.invoiceNo?.id,
          drawerLabel: `Invoice No: ${fetchId?.invoiceNo?.label}`,
        }}
      />

      <Loads
        fetchApiId={fetchId?.loadNo?.id}
        onlyDetailsDrawer
        handleDrawerClose={() => setFetchId(defaultIdState)}
        drawerProps={{
          open: !!fetchId?.loadNo?.id,
          drawerLabel: `Load: ${fetchId?.loadNo?.label}`,
        }}
      />
    </>
  );
};

export default AssetOrders;
