import React from 'react'

import Hero from './sections/Hero';
import Benefits from './sections/Benefits';
import LearnFaster from '../../components/public/LearnFaster';
import Features from './sections/Features';

const HomePage = () => {
  return (
    <>
      <Hero />
      <Benefits />
      <LearnFaster
        title='Learn Faster With MasterCRM'
        description='Stay organized without feeling overwhelmed. Effortlessly integrate your tasks, teams, and clients with a flexible project management system designed to scale alongside your company.'
      />
      <Features />
    </>
  )
}

export default HomePage;
