import React, { memo } from 'react'

import { ReactComponent as Line } from 'assets/staticPages/lines.svg';
import { ReactComponent as Dots } from 'assets/staticPages/dots.svg';
import { ReactComponent as Arrow } from 'assets/staticPages/arrow.svg';

import { Container, Box, Typography } from '@mui/material';

const ResourcesHero = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        height: { xs: 'auto', md: '350px' },
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >

      <Container maxWidth='xl'>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          py={{ xs: 6, md: 12 }}
          sx={{ gap: { xs: 2, md: 4 } }}
        >
          <Typography
            variant='p'
            sx={{
              color: 'white',
              fontWeight: '600',
              fontSize: { xs: '36px', sm: '50px', md: '60px' },
              lineHeight: { xs: '50px', sm: '54px', md: '64px' },
              textAlign: 'center',
            }}
          >
            Resources
          </Typography>
          <Typography
            variant='p'
            textAlign='center'
            color='white'
            maxWidth='1150px'
            lineHeight='28px'
          >
            Master CRM's resource hub is your go-to for best practices, tips, and insights on customer relationship and enterprise resource management. Stay informed and inspired to get the most out of our CRM platform.
          </Typography>
        </Box>
      </Container>

      <Box
        component={Line}
        sx={{
          position: 'absolute',
          top: '-100px',
          left: '-100px',
          transform: 'scale(.8)',
        }}
      />
      <Box
        component={Dots}
        sx={{
          position: 'absolute',
          top: '10px',
          right: { xs: '10%', sm: '59%' },
          display: { xs: 'none', sm: 'block' },
        }}
      />
      <Box
        component={Arrow}
        sx={{
          position: 'absolute',
          bottom: { xs: '0px', md: '40px' },
          right: { xs: '0px', md: '50px' },
          transform: { xs: 'scale(.65)', md: 'scale(1)' },
          display: { xs: 'none', sm: 'block' },
        }}
      />
    </Box>
  )
};

export default memo(ResourcesHero);
