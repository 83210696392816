import React, { memo } from 'react';

import { Typography } from '@mui/material';
import { camelCaseToSpace } from 'utils/textFormatUtils';

const FieldLabel = ({ label, name, required, disableStar }) => {
  const hasStar = required && !disableStar;
  const labelText = label || camelCaseToSpace(name) || '';

  const labelWithStar = (
    <>
      {labelText}&nbsp;
      <Typography variant="h5" color="crimson" component="span">
        *
      </Typography>
    </>
  );

  if (hasStar) return labelWithStar;
  return labelText;
};

export default memo(FieldLabel);
