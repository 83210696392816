import React, { useState, useEffect, useMemo } from 'react';

import { keys, values, mapValues, isEmpty } from 'lodash';

import PaperBox from 'common/ui/PaperBox';
import { Typography, Box, Grid, Chip, Button } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineOppositeContent,
} from '@mui/lab';

import AssetTestDialog from 'pages/components/assets/testComponents/AssetTestDialog';
import AssetGrade from 'pages/components/assets/testComponents/AssetGrade';
import DividerLine from 'common/ui/DividerLine';

import { uniqBy } from 'lodash';
import { format, parseISO } from 'date-fns';
import { camelCaseToSpace } from 'utils/textFormatUtils';

const AssetTestResults = ({
  testData = [],
  assetData,
  assetTestHistory = [],
  assetRefetch = () => { },
}) => {
  const [labels, setLabels] = useState([]);
  const [testValues, setTestValues] = useState([]);
  const [testDialogValues, setTestDialogValues] = useState([]);
  const [manualGradeMessage, setManualGradeMessage] = useState(null);
  const [openTestDialog, setOpenTestDialog] = useState(false);
  const [assetGrade, setAssetGrade] = useState('');

  const testHistoryReduced = useMemo(() => {
    return uniqBy(
      assetTestHistory.map((item) => ({
        ...item,
        dateOnly:
          item?.dateTested &&
          format(
            parseISO(item?.dateTested ? item?.dateTested : new Date()),
            'yyyy-MM-dd',
          ),
      })),
      (item) => `${item.dateOnly}-${item.grade}-${item.testConditionName}`,
    );
  }, [assetTestHistory]);

  const splitValues = (data) =>
    mapValues(data, (value) =>
      value
        ?.split(';')
        ?.map((item) => item.trim())
        ?.filter((item) => item.length > 0),
    );

  useEffect(() => {
    setAssetGrade(assetData?.grade);
  }, [assetData]);

  const handleTestValues = (data) => {
    const { isManual: manualData, ...autoTestData } = data;
    const { isManual, grade, gradingComments } = manualData;

    if (isManual) {
      setManualGradeMessage(gradingComments);
      setAssetGrade(grade);
      return;
    }

    setLabels(keys(autoTestData));
    setTestValues(values(autoTestData));
    setTestDialogValues(splitValues(autoTestData));
  }

  useEffect(() => {
    handleTestValues(testData);
  }, [testData]);

  return (
    <>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          onClick={() => setOpenTestDialog(true)}
          sx={{ minWidth: '140px' }}
        >
          {assetGrade ? 'Re-test' : 'Test'}
        </Button>
        <Box>{assetGrade && <AssetGrade grade={assetGrade} />}</Box>
      </Box>

      <AssetTestDialog
        open={openTestDialog}
        assetData={assetData}
        testValues={testDialogValues}
        manualData={!manualGradeMessage ? null : {
          gradingComments: manualGradeMessage,
          grade: assetGrade,
        }}
        handleClose={() => setOpenTestDialog(false)}
        isAssetDetail
        setUpdatedData={(updatedTestData) => {
          const {
            assetTestResults,
            assetInfo: { grade },
          } = updatedTestData;

          setAssetGrade(grade);
          handleTestValues(assetTestResults);
        }}
        triggerAssetFetch={assetRefetch}
      />

      {isEmpty(manualGradeMessage) ?
        <Grid spacing={1} container>
          {!assetGrade ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <Typography variant="h5" fontWeight="400">
                Asset
                <Box component="span" fontWeight="500">
                  &nbsp;{assetData?.ssn}&nbsp;
                </Box>
                is not tested yet
              </Typography>
            </Grid>
          ) : (
            labels?.map((label, key) => (
              <Grid key={key} item sm={6} xs={12}>
                <PaperBox
                  white
                  sx={{
                    height: '100%',
                    minHeight: '120px',
                    // p: { md: 2, lg: '20px' },
                    // background: 'white',
                  }}
                  label={camelCaseToSpace(label)}
                >
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {testValues?.[key]?.split(';')?.map((test, idx) => (
                      <Chip
                        key={idx}
                        label={
                          <Typography
                            variant="p3"
                            color="secondary.main"
                          >
                            {test}
                          </Typography>
                        }
                        sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                          p: 0.5,
                          background: (theme) =>
                            test?.toLowerCase() === 'all-pass'
                              ? theme.palette.common.highGreen
                              : '#FF55381A',
                        }}
                        size="small"
                      />
                    ))}
                  </Box>
                </PaperBox>
              </Grid>
            ))
          )}
        </Grid>
        :
        <>
          <PaperBox white label='Manual Test Message'>
            <Typography>{manualGradeMessage}</Typography>
          </PaperBox>
        </>
      }


      <DividerLine color="white" height="1px" sx={{ my: 2 }} />

      <PaperBox label="Test history" white>
        <Timeline sx={{ pl: 10 }}>
          {testHistoryReduced.map((history, key) => (
            <TimelineItem
              key={key}
              sx={{
                '&::before': {
                  display: 'none',
                },
                mb: 2,
              }}
            >
              <TimelineOppositeContent color="text.secondary">
                <Typography variant="p2">{history.dateTested}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box>
                  <Typography variant="body1">
                    <strong>{history?.grade}</strong>
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    sx={{ color: 'grey', fontWeight: 'bold', mt: 2 }}
                  >
                    {history.testConditionName}
                  </Typography> */}
                  <Typography variant="body2">
                    {history?.testConditionName}
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </PaperBox>
    </>
  );
};

export default AssetTestResults;
