import React from 'react';

import ContainerForm from '../componenets/forms/warehouseLogistics/ContainerForm';

import { getAllContainers, createUpdateContainers } from 'api/profileApis/warehouseLogisticsApis';

import ProfileTable from '../componenets/ProfileTable';
import { containerColumns } from '../componenets/tableColumns/warehouseLogistics/containerColumns';

const Containers = () => (
  <ProfileTable
    getApi={getAllContainers}
    createApi={createUpdateContainers}
    updateApi={createUpdateContainers}
    columns={containerColumns}
    title="Containers"
    buttonLabel="Container"
    CreateForm={ContainerForm}
    filterSelectorEnum={[
      {
        name: 'global',
        label: 'Search Containers',
        placeholder: 'Search here...',
      },
    ]}
    onlyGlobalFilter
    dialogSize="sm"
    updateReplaceObjectKey="locationId"
  />
);

export default Containers;
