// paths with "/" at start will replace the url
export const crmRoutes = {
  DASHBOARD: '/dashboard',
  HOME_PATH: '/crm',
  USER_PROFILE_PATH: '/profile',
  LEADS_PATH: '/crm/leads',
  LOADS_PATH: '/crm/loads',
  GRA_PATH: '/crm/gra',
  CONTACTS_PATH: '/crm/contacts',
  // ACCOUNTS_PATH: "accounts",
  DEALS_PATH: '/crm/deals',
  TASKS_PATH: '/crm/tasks',
  REPORTS_PATH: '/crm/reports',
  CUSTOM_REPORTS_PATH: '/crm/custom-reports',
  FILTERED_REPORTS_PATH: '/crm/filtered-reports',
  ANALYTICS_PATH: '/crm/analytics',
  PRODUCTS_PATH: '/crm/products',
  ASSETS_PATH: '/crm/assets',
  QUOTES_PATH: '/crm/quotes',
  SALES_ORDERS_PATH: '/crm/sales-orders',
  PURCHASE_ORDERS_PATH: '/crm/purchase-orders',
  PAYMENT_ORDERS_PATH: '/crm/payment-orders',
  DISPATCH_ORDERS_PATH: '/crm/dispatch-orders',
  WORK_ORDERS_PATH: '/crm/work-orders',
  INVOICES_PATH: '/crm/invoices',
  VENDORS_PATH: '/crm/vendors',
  SUPPLIERS_PATH: '/crm/suppliers',
  PROJECTS_PATH: '/crm/projects',
  ASSET_RECYCLE_REPORT_PATH: '/crm/asset-recycle-report',
  TIME_SHEET_PATH: '/crm/time-sheets',
};

export const sideBarRoutes = {
  BUSINESS_DASHBOARD_PATH: '/crm/business-dashboard',
  ANALYTICS_DASHBOARD_PATH: '/crm/analytics-dashboard',
  ORDERS_PATH: '/crm/orders',
  INVENTORY_PATH: '/crm/inventory',
  CUSTOMERS_PATH: '/crm/customers',
  CALENDAR_EVENTS_PATH: '/crm/calendar-events',
};

export const otherRoutes = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  ABOUT_US: '/about-us',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS: '/terms',
  PRODUCT_PAGE: '/product',
  PRICING_PAGE: '/pricing',
  PLATFORM_PAGE: '/plaform',
  PARTNERS_PAGE: '/partners',
  RESOURCES_PAGE: '/resources',
  COMPANY_PAGE: '/company',
  CONTACT_US_PAGE: '/contact-us',
  HOME_PAGE: '/',
};

export const profileRoutes = {};
