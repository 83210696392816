import { Box } from '@mui/material'
import React, { memo } from 'react'

import Carousel from 'common/ui/Carousel';
import laptopImage from 'assets/staticPages/homePage/laptopImage.png';

import img1 from 'assets/staticPages/homePage/carousalImages/1.png';
import img2 from 'assets/staticPages/homePage/carousalImages/2.png';
import img3 from 'assets/staticPages/homePage/carousalImages/3.png';
import img4 from 'assets/staticPages/homePage/carousalImages/4.png';
import img5 from 'assets/staticPages/homePage/carousalImages/5.png';
import img6 from 'assets/staticPages/homePage/carousalImages/6.png';
import img7 from 'assets/staticPages/homePage/carousalImages/7.png';
import img8 from 'assets/staticPages/homePage/carousalImages/8.png';

const slides = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8
];

const LaptopCarousel = ({
  sx = {}
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${laptopImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        maxWidth: '900px',
        aspectRatio: '6.1 / 3.2',
        mt: { xs: 6, sm: 2, lg: 0 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2))',
        ...sx,
      }}
      data-aos="fade"
      data-aos-duration="700"
      data-aos-delay="200"
    >
      <Box
        sx={{
          width: '86%',
          transform: 'translate(0px, -7px)',
          mt: { xs: 1, sm: 0 },
        }}
      >
        <Carousel dots={false}>
          {slides?.map((item, key) => (
            <Box
              component='img'
              src={item}
              key={key}
              width='100%'
              // height='100%'
              sx={{
                aspectRatio: '6.1 / 3.24',
                borderRadius: '4px',
                '&:hover': {
                  cursor: 'grab',
                },
              }}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  )
}

export default memo(LaptopCarousel);