import React, { useState, useEffect, memo } from 'react'

import SearchSelect from 'common/input/SearchSelect';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

const DynamicActionSelector = ({
  createForm,
  getStateValue = () => { },
  fetchListAction = () => { },
  ...rest
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const selectorList = useSelector(getStateValue);

  const fetchDispatchData = async () => {
    if (!isEmpty(selectorList)) return;

    setLoading(true);
    await dispatch(fetchListAction());
    setLoading(false);
  }

  useEffect(() => {
    fetchDispatchData();
  }, [selectorList]);

  return (
    <SearchSelect
      loading={loading}
      searchSelectData={selectorList}
      {...rest}
      disabled={isEmpty(selectorList)}
    />
  );
}

export default memo(DynamicActionSelector);