import React from 'react'

import PublicNavbar from './PublicNavbar';
import Footer from './Footer';

const Layout = ({
  children
}) => {
  return (
    <>
      <PublicNavbar />
      {children}
      <Footer />
    </>
  )
}

export default Layout;