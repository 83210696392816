import { Container, Grid2 } from '@mui/material'
import React, { memo } from 'react'

import SmallInfoBox from 'pages/components/public/SmallInfoBox';

const infoText = [
  {
    title: 'User Guides',
    description: 'Explore our comprehensive user guides and manuals to master the functionality and features of Master CRM.',
  },
  {
    title: 'Case Studies',
    description: 'Read success stories from businesses like yours that have transformed their operations with Master CRM.',
  },
  {
    title: 'Blog',
    description: 'Stay up to date with the latest trends in CRM, sales, marketing, and more with articles from our expert team.',
  },
  {
    title: 'Webinars',
    description: 'Join our interactive webinars to learn best practices and get your questions answered by our product experts.',
  },
];

const ResourcesInfo = () => {
  return (
    <Container maxWidth='xl' sx={{ py: { xs: 8, md: 10 } }}>
      <Grid2
        container
        spacing={{ xs: 3, md: 5 }}
        display='flex'
        justifyContent='center'
        alignItems='stretch'
      >
        {infoText.map((item, key) => (
          <Grid2 size={{ xs: 12, md: 6 }} key={key} height='unset'>
            <SmallInfoBox title={item?.title} description={item?.description} />
          </Grid2>
        ))}
      </Grid2>
    </Container >
  )
};

export default memo(ResourcesInfo);
