import { dateTimeFormatter } from 'utils/textFormatUtils';

export const aisleColumns = [
  {
    field: 'aisle',
    headerName: 'Aisle',
    minWidth: 200,
    flex: 1,
  },
  {
    field: 'warehouseName',
    headerName: 'Warehouse',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'customer',
    headerName: 'Customer',
    flex: 1,
    minWidth: 160,
  },
  {
    field: 'project',
    headerName: 'Project',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    flex: 1,
    minWidth: 100,
    valueFormatter: dateTimeFormatter,
  },
  {
    field: 'modifiedAt',
    headerName: 'Modified at',
    valueFormatter: dateTimeFormatter,
    minWidth: 100,
    flex: 1,
  },
];
