import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import { otherRoutes } from 'routes/allRoutesMap';

import HomePage from 'pages/publicPages/homePage/HomePage';
import AboutUs from 'pages/publicPages/aboutUs/AboutUs';
import Terms from 'pages/publicPages/terms/Terms';
import ProductPage from 'pages/publicPages/productPage/ProductPage';
import PrivacyPolicy from 'pages/publicPages/privacyPolicy/PrivacyPolicy';
import PricingPage from 'pages/publicPages/pricingPage/PricingPage';
import PlatformPage from 'pages/publicPages/platformPage/PlatformPage';
import PartnersPage from 'pages/publicPages/partnersPage/PartnersPage';
import ResourcesPage from 'pages/publicPages/resourcesPage/ResourcesPage';
import CompanyPage from 'pages/publicPages/companyPage/CompanyPage';
import Login from 'pages/loginSignup/LoginPage';
import Signup from 'pages/loginSignup/SignupPage';
import ContactUs from 'pages/publicPages/contactUs/ContactUs';

import Layout from 'pages/components/public/Layout';

import AOS from "aos";
import "aos/dist/aos.css";
import { Box } from '@mui/material';

const MainPublicRoute = () => {
  useEffect(() => {
    AOS.init({
      duration: 700,
      once: true,
    });
  }, []);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Layout>
        <Routes>
          <Route path={otherRoutes.LOGIN} element={<Login />} />
          <Route path={otherRoutes.SIGNUP} element={<Signup />} />
          <Route path={otherRoutes.ABOUT_US} element={<AboutUs />} />
          <Route path={otherRoutes.TERMS} element={<Terms />} />
          <Route path={otherRoutes.HOME_PAGE} element={<HomePage />} />
          <Route path={otherRoutes.PRODUCT_PAGE} element={<ProductPage />} />
          <Route path={otherRoutes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={otherRoutes.PRICING_PAGE} element={<PricingPage />} />
          <Route path={otherRoutes.PLATFORM_PAGE} element={<PlatformPage />} />
          <Route path={otherRoutes.PARTNERS_PAGE} element={<PartnersPage />} />
          <Route path={otherRoutes.RESOURCES_PAGE} element={<ResourcesPage />} />
          <Route path={otherRoutes.COMPANY_PAGE} element={<CompanyPage />} />
          <Route path={otherRoutes.CONTACT_US_PAGE} element={<ContactUs />} />
        </Routes>
      </Layout>
    </Box>
  )
};

export default MainPublicRoute;
