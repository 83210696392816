import React from 'react'

import ResourcesHero from './sections/ResourcesHero';
import ResourcesInfo from './sections/ResourcesInfo';
import LearnFaster from '../../components/public/LearnFaster';
import { otherRoutes } from 'routes/allRoutesMap';
import { useNavigate } from 'react-router-dom';

const ResourcesPage = () => {
  const nav = useNavigate();

  return <>
    <ResourcesHero />
    <ResourcesInfo />
    <LearnFaster
      title='Looking for more?'
      description='Visit our Help Center or reach out to our support team for personalized assistance.'
      minHeight='340px'
      onClick={() => nav(otherRoutes.CONTACT_US_PAGE)}
      ctaLabel='Contact Us'
    />
  </>
};

export default ResourcesPage;