import React from 'react';

import BinForm from '../componenets/forms/warehouseLogistics/BinForm';

import { getAllBins, createUpdateBins } from 'api/profileApis/warehouseLogisticsApis';

import ProfileTable from '../componenets/ProfileTable';
import { binColumns } from '../componenets/tableColumns/warehouseLogistics/binColumns';

const Bins = () => (
  <ProfileTable
    getApi={getAllBins}
    createApi={createUpdateBins}
    updateApi={createUpdateBins}
    columns={binColumns}
    title="Bins"
    buttonLabel="Bin"
    CreateForm={BinForm}
    filterSelectorEnum={[
      {
        name: 'global',
        label: 'Search Bins',
        placeholder: 'Search here...',
      },
    ]}
    onlyGlobalFilter
    dialogSize="sm"
    updateReplaceObjectKey="locationId"
  />
);

export default Bins;
