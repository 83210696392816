import React from 'react';

import PaperBox from 'common/ui/PaperBox';
import { Box, Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth='md' sx={{ my: 3 }}>
      <PaperBox>
        <Box mb={2}>
          <Typography variant='h4'>Privacy Policy</Typography>
        </Box>
        <Typography>
          Your privacy is important to us at MasterCRM. This Privacy Policy
          outlines how we collect, use, and safeguard your personal information.
          By using our services, you agree to the terms of this policy.
        </Typography>

        <Box component='ul' display='flex' gap={2} flexDirection='column'>
          <Typography>
            <strong>Data Collection:</strong> We collect necessary user
            information to provide our SaaS services effectively.
          </Typography>
          <Typography>
            <strong>Usage Information:</strong> We may gather data on how you
            interact with our platform for continuous improvement.
          </Typography>
          <Typography>
            <strong>Security:</strong> We implement industry-standard security
            measures to protect your data from unauthorized access.
          </Typography>
          <Typography>
            <strong>Third-Party Integration:</strong> We may integrate third-party
            services for enhanced functionality, always ensuring your data's
            security.
          </Typography>
          <Typography>
            <strong>Communication:</strong> We may contact you for service-related
            updates, but we won't spam your inbox.
          </Typography>
          <Typography>
            <strong>Data Retention:</strong> Your data is retained only for the
            necessary period and deleted securely when no longer needed.
          </Typography>
          <Typography>
            <strong>Policy Updates:</strong> We may update our Privacy Policy, and
            any changes will be communicated to you.
          </Typography>
          <Typography>
            <strong>Company Values:</strong> Our commitment to transparency,
            integrity, and user trust guides our privacy practices.
          </Typography>
        </Box>

        <Typography>
          At MasterCRM, we prioritize your privacy and are committed to
          maintaining the confidentiality of your information. If you have any
          questions or concerns, please contact us.
        </Typography>
      </PaperBox>
    </Container>
  );
};

export default PrivacyPolicy;
