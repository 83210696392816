import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { invoiceorderColumns } from 'pages/tableColumns/invoiceorderColumn';
import ActionPageMain from 'pages/components/PageMain';
import createFormEnum from 'enum/createFormEnum';

import { fetchInvoices } from 'redux/slices/actionSlice/orderSlice';
import { getInvoiceOrderById } from 'api/masterApi';

import { crmRoutes } from 'routes/allRoutesMap';
import TableFilters from 'pages/components/TableFilters';

const Invoices = ({ ...props }) => {
  const { invoice } = useSelector((state) => state.actions.orders);
  const [filteredData, setFilteredData] = useState([]);

  const columns = invoiceorderColumns();

  return (
    <ActionPageMain
      formKey={createFormEnum.invoices}
      rows={filteredData}
      columns={columns}
      label="Invoices"
      createLabel="Create Invoice"
      fetchApi={fetchInvoices}
      createFormProps={{
        disableFormFooter: true,
      }}
      fetchByIdApi={getInvoiceOrderById}
      detailDataFetchIdKey="invoiceOrderID"
      drawerLabelKey="invoiceNo"
      pagePath={crmRoutes.INVOICES_PATH}
      {...props}
    >
      <TableFilters
        data={invoice}
        onlyGlobal
        filterSelectorEnum={[
          {
            name: 'global',
            label: 'Search Invoice',
            placeholder: 'Global search...',
            isInput: true,
          },
        ]}
        handleChange={(filtered) => setFilteredData(filtered)}
      />
    </ActionPageMain>
  );
};

export default Invoices;
