import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import { useTheme } from '@mui/material';
import master_crm_logo from 'assets/master-crm-logo.png';

Font.register({
  family: 'Lora',
  src: 'https://fonts.gstatic.com/s/lora/v16/0QI6MX1D_JOuGQbT0gvTJPa787weuxJBkqg.ttf', // Link to the Lora font file
});

const InvoicePdf = ({ data }) => {
  const theme = useTheme();

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      padding: 30,
    },
    section: {
      marginBottom: 2,
    },
    title: {
      fontSize: 24,
      marginBottom: 20,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    sectionTitle: {
      fontSize: 14,
      marginBottom: 10,
      fontWeight: 'bold',
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
    },
    table: {
      width: '100%',
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      height: 'auto',
      fontSize: 8,
    },
    tableHeader: {
      backgroundColor: '#faf5f4',
      height: 30,
      fontSize: 8,
      fontWeight: 'bold',
    },
    tableCol: {
      flex: 1,
      marginTop: 8,
      marginBottom: 8,
      textAlign: 'center',
      flexWrap: 'wrap',
    },
    narrowCol: {
      flex: '0 0 15%',
    },
    wideCol: {
      flex: '0 0 45%',
    },
    divider: {
      backgroundColor: '#30344E',
      height: 2,
      marginBottom: '20px',
    },
    invoiceHeaderContainer: {
      backgroundColor: '#faf5f4',
      flexDirection: 'row',
      padding: 20,
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      borderBottomColor: '#FF5538',
      borderStyle: 'dashed',
    },
    logoContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    logoImage: {
      width: 40,
      marginRight: 8,
    },
    companyName: {
      fontFamily: 'Lora',
      color: '#FF5538',
      fontSize: 15,
      fontWeight: 'bold',
      marginTop: 12,
    },
    locationText: {
      marginTop: 6,
      fontSize: 12,
    },
    invoiceDetailsContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 15,
      width: '100%',
      marginBottom: 15,
      marginLeft: 15,
    },
    invoiceDetailsHeading: {
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 15,
    },
    invoiceDetailsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    invoiceDetailsColumn: {
      flex: 1,
      paddingHorizontal: 10,
    },
    logoName: {
      flexDirection: 'column',
    },
  });

  return (
    <Document title="Quote-Invoice.pdf">
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Invoice</Text>

        <View style={styles.invoiceHeaderContainer}>
          <View style={styles.logoName}>
            <View style={styles.logoContainer}>
              <Image style={styles.logoImage} src={master_crm_logo} />
              <Text style={styles.companyName}>MASTER CRM</Text>
            </View>
            <Text style={styles.locationText}>Sydney, Australia</Text>
          </View>

          <View>
            {/* Customer Information */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Customer Information</Text>
              <Text style={styles.text}>
                Customer Name: {data?.accountName}
              </Text>
            </View>
          </View>
        </View>

        {/*Invoice Details with Heading */}
        <View style={styles.invoiceDetailsContainer}>
          <Text style={styles.invoiceDetailsHeading}>Invoice Details</Text>
          <View style={styles.invoiceDetailsRow}>
            <View style={styles.invoiceDetailsColumn}>
              <Text style={styles.text}>Invoice No: {data?.invoiceNo}</Text>
              <Text style={styles.text}>
                Invoice Date: {new Date(data?.invoiceDate).toLocaleDateString()}
              </Text>
              <Text style={styles.text}>
                Invoice Expiry Date:{' '}
                {new Date(data?.invoiceExpiryDate).toLocaleDateString()}
              </Text>
              <Text style={styles.text}>Sales Person: {data?.accountName}</Text>
            </View>
            <View style={styles.invoiceDetailsColumn}>
              <Text style={styles.text}>PO Number: {data?.customerPO}</Text>
              <Text style={styles.text}>Status: {data?.invoiceStatus}</Text>
              <Text style={styles.text}>Invoice Type: {data?.invoiceType}</Text>
              <Text style={styles.text}>
                Total Amount: {data?.currency} {data?.invoiceTotal?.toFixed(2)}
              </Text>
              <Text style={styles.text}>Discount: {data?.discount}%</Text>
            </View>
          </View>
        </View>

        {/* Divider */}
        <View style={styles.divider} />

        {/* Invoice Items */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Invoice Items</Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={[styles.tableCol, styles.wideCol]}>Description</Text>
              <Text style={[styles.tableCol, styles.narrowCol]}>Quantity</Text>
              <Text style={[styles.tableCol, styles.narrowCol]}>
                Unit Price
              </Text>
              <Text style={[styles.tableCol, styles.narrowCol]}>
                Total Amount
              </Text>
            </View>
            {data?.lineItems?.map((item, key) => (
              <View key={key} style={styles.tableRow}>
                <Text style={[styles.tableCol, styles.wideCol]}>
                  {item?.itemDescription}
                </Text>
                <Text style={[styles.tableCol, styles.narrowCol]}>
                  {item?.quantity}
                </Text>
                <Text style={[styles.tableCol, styles.narrowCol]}>
                  {data?.currency} {item?.unitPrice?.toFixed(2)}
                </Text>
                <Text style={[styles.tableCol, styles.narrowCol]}>
                  {data?.currency} {item?.totalAmount?.toFixed(2)}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;
