import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import InputField from 'common/input/InputField';
import SearchSelect from 'common/input/SearchSelect';
import DynamicSelector from 'components/createFormComponents/dynamicSelectorFields/DynamicSelector';
import DynamicActionSelector from 'components/createFormComponents/dynamicSelectorFields/DynamicActionSelector';

import { numberOnly } from 'utils/textFormatUtils';

import { getModels, getLoadsList } from 'api/listApis';
import { isEmpty } from 'lodash';

import CustomerProjectSelectors from 'components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors';

import { fetchAssetStatus, fetchItemTypes, fetchMakes, fetchWarehouses } from 'redux/slices/listSlice/listSlice';

const AssetsForm = ({
  formData,
  isUpdate = false,
  gridContainerProps = {},
  onChange = () => { },
  disableStar,
  placeholder,
  removeFields = [],
  gridItemSize = {
    md: 3,
    sm: 4,
    xs: 6,
  },
  defaultValues = {},
}) => {
  // const [modelList, setModelList] = useState(null);
  // const [loadList, setLoadList] = useState(null);

  // const { userProjects = [] } = useSelector((state) => state?.userDetails);

  const commonInputProps = {
    formData: formData,
    onChange: onChange,
    disableStar: disableStar,
    placeholder: placeholder,
  };

  // const handleValueChange = async (name, value) => {
  //   if (value === null || value === undefined || value === '') {
  //     if (name === 'projectId') {
  //       setLoadList(null);
  //       formData?.setValue('load', null);
  //     }
  //     return;
  //   }

  //   onChange(name, value);

  //   // let updatedValue = value;

  //   if (name === 'projectId') {
  //     value = returnLabel
  //       ? userProjects?.find((item) => item?.value === value)?.id
  //       : value;
  //     getLoadsList([value])
  //       ?.then((res) => {
  //         setLoadList(res);
  //         isEmpty(res) && formData?.setValue('load', null);
  //       })
  //       ?.catch(() => setLoadList([]));
  //     // const data = await getLoadsList([updatedValue]);
  //   }
  // };

  const { makeId, projectId } = formData.watch();

  // useEffect(() => {
  //   // const makeId = isString(makeId) ? find(makes, { value: makeId })?.id : makeId;
  //   !!makeId && handleValueChange('makeId', makeId);
  // }, [makeId]);

  // useEffect(() => {
  //   !!makeId && handleValueChange('projectId', projectId);
  // }, [projectId]);

  useEffect(() => {
    !isEmpty(defaultValues) && formData?.reset(defaultValues);
  }, [defaultValues]);

  return (
    <>
      <Grid
        pt={2}
        pb={isUpdate ? 0 : 2}
        container
        rowSpacing={2}
        columnSpacing={2}
        {...gridContainerProps}
      >
        {!removeFields?.includes('ssn') && !isUpdate && (
          <Grid {...gridItemSize} item>
            <InputField required {...commonInputProps} name="ssn" label="SSN" />
          </Grid>
        )}

        <Grid {...gridItemSize} item>
          <DynamicActionSelector
            name="makeId"
            label="Make"
            fetchListAction={fetchMakes}
            getStateValue={state => state?.lists?.makes}
            {...commonInputProps}
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <DynamicSelector
            name="modelId"
            label="Model"
            fetchId={makeId}
            fetchListApi={getModels}
            {...commonInputProps}
          />
        </Grid>

        {isUpdate && (
          <Grid {...gridItemSize} item>
            <DynamicActionSelector
              name="productId"
              label="Product"
              fetchListAction={fetchMakes}
              getStateValue={state => state?.lists?.makes}
              {...commonInputProps}
            />
          </Grid>
        )}

        <Grid {...gridItemSize} item>
          <InputField
            // required
            {...commonInputProps}
            name="serialNo"
          />
        </Grid>

        {/* <Grid {...gridItemSize} item>
          <InputField
            {...commonInputProps}
            name='barcode'
          />
        </Grid> */}

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="clientRef" />
        </Grid>

        {/* <Grid {...gridItemSize} item>
          <InputField
            {...commonInputProps}
            name='clientPO'
          />
        </Grid> */}

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="clientAssetTag" />
        </Grid>

        {isUpdate && (
          <Grid {...gridItemSize} item>
            <InputField
              format={numberOnly}
              {...commonInputProps}
              name="buyPrice"
              startAdornment="$"
            />
          </Grid>
        )}

        {isUpdate && (
          <Grid {...gridItemSize} item>
            <DynamicActionSelector
              name="assetStatus"
              label="Status"
              fetchListAction={fetchAssetStatus}
              getStateValue={state => state?.lists?.assetStatus}
              {...commonInputProps}
            />
          </Grid>
        )}

        <Grid {...gridItemSize} item>
          <DynamicActionSelector
            required
            name="itemType"
            label="Type"
            fetchListAction={fetchItemTypes}
            getStateValue={state => state?.lists?.itemTypes}
            {...commonInputProps}
          />
        </Grid>

        {!removeFields?.includes('warehouse') && (
          <Grid {...gridItemSize} item>
            <DynamicActionSelector
              name="warehouse"
              fetchListAction={fetchWarehouses}
              getStateValue={state => state?.lists?.warehouses}
              {...commonInputProps}
            />
          </Grid>
        )}
        {!removeFields?.includes('palletNo') && (
          <Grid {...gridItemSize} item>
            <InputField {...commonInputProps} name="palletNo" />
          </Grid>
        )}

        <CustomerProjectSelectors
          formData={formData}
          noCustomer={removeFields?.includes('customerId')}
          noProject={removeFields?.includes('projectId')}
          {...gridItemSize}
        />

        {!removeFields?.includes('load') && (
          <Grid {...gridItemSize} item>
            {/* <SearchSelect
              required
              name="load"
              searchSelectData={loadList}
              // disabled={!loadList?.length}
              {...commonInputProps}
            /> */}
            <DynamicSelector
              required
              name="load"
              fetchId={projectId}
              fetchListApi={getLoadsList}
              {...commonInputProps}
            />
          </Grid>
        )}

        {/* <Grid {...gridItemSize} item>
          <Selector
            {...commonInputProps}
            name='r2Grade'
            selectorData={[
              {value: 'best', label: 'Best'},
              {value: 'best', label: 'Best'},
              {value: 'best', label: 'Best'},
            ]}
          />
        </Grid> */}

        {/* <Grid {...gridItemSize} item>
          <SwitchToggle
            {...commonInputProps}
            name='modified'
            label={<Typography>is Modified</Typography>}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default memo(AssetsForm);
