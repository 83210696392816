import { useRoutes } from 'react-router-dom';
import { crmRoutes } from 'routes/allRoutesMap';
import { crmPaths, sideBarPaths } from 'routes/routesPathEnum';
import React, { Suspense } from 'react';

import SpinLoader from 'common/dataDisplay/spinLoader/SpinLoader';
import { Container } from '@mui/material';

// Lazy-loaded pages
const Home = React.lazy(() => import('pages/Home'));
const Accounts = React.lazy(() => import('pages/Accounts'));

// CRM pages
const Leads = React.lazy(() => import('pages/actionPages/crm/Leads'));
const Contacts = React.lazy(() => import('pages/actionPages/crm/Contacts'));
const Deals = React.lazy(() => import('pages/actionPages/crm/Deals'));
const Quotes = React.lazy(() => import('pages/actionPages/crm/Quotes'));
const Vendors = React.lazy(() => import('pages/actionPages/crm/Vendors'));
const Supplier = React.lazy(() => import('pages/actionPages/crm/Supplier'));
// const Projects = React.lazy(() => import('pages/actionPages/crm/Projects'));

// Interactions pages
const Tasks = React.lazy(() => import('pages/interactions/Tasks'));
const CalendarEvents = React.lazy(
  () => import('pages/interactions/CalendarEvents'),
);
const TimeSheets = React.lazy(() => import('pages/interactions/TimeSheets'));

// Reports pages
const Reports = React.lazy(() => import('pages/actionPages/reports/Reports'));
const CustomReports = React.lazy(() => import('pages/actionPages/reports/CustomReports'));

// Inventory pages
const Assets = React.lazy(() => import('pages/actionPages/inventory/Assets'));
const Products = React.lazy(
  () => import('pages/actionPages/inventory/Products'),
);
const Loads = React.lazy(() => import('pages/actionPages/inventory/Loads'));
const Gra = React.lazy(() => import('pages/actionPages/inventory/Gra'));

// Order pages
const DispatchOrders = React.lazy(
  () => import('pages/actionPages/orders/DispatchOrders'),
);
const PaymentOrders = React.lazy(
  () => import('pages/actionPages/orders/PaymentOrders'),
);
const PurchaseOrders = React.lazy(
  () => import('pages/actionPages/orders/PurchaseOrders'),
);
const SalesOrders = React.lazy(
  () => import('pages/actionPages/orders/SalesOrders'),
);
const WorkOrders = React.lazy(
  () => import('pages/actionPages/orders/WorkOrders'),
);

// Invoice pages
const Invoices = React.lazy(() => import('pages/actionPages/invoice/Invoices'));

// Sidebar pages
const Analytics = React.lazy(() => import('pages/sidebarPages/Analytics'));
const AnalyticsDashboard = React.lazy(
  () => import('pages/sidebarPages/AnalyticsDashboard'),
);
const BusinessDashboard = React.lazy(
  () => import('pages/sidebarPages/BusinessDashboard'),
);
const Customers = React.lazy(() => import('pages/sidebarPages/Customers'));
const Orders = React.lazy(() => import('pages/sidebarPages/Orders'));
const Inventory = React.lazy(
  () => import('pages/actionPages/inventory/Inventory'),
);

const CrmRoutes = () => {
  // const isProfilePage = window.location.pathname.includes(crmRoutes.USER_PROFILE_PATH);

  return (
    // <Container maxWidth={false} disableGutters={isProfilePage} sx={{ mt: isProfilePage ? 0 : 1 }}>
    <Suspense fallback={<SpinLoader loading noBlur />}>
      {useRoutes([
        { path: `${crmPaths.HOME_PATH}/:id?`, element: <Home /> },

        // CRM pages
        { path: `${crmPaths.LEADS_PATH}/:id?`, element: <Leads /> },
        { path: `${crmPaths.CONTACTS_PATH}/:id?`, element: <Contacts /> },
        { path: `${crmPaths.DEALS_PATH}/:id?`, element: <Deals /> },
        { path: `${crmPaths.QUOTES_PATH}/:id?`, element: <Quotes /> },
        { path: `${crmPaths.VENDORS_PATH}/:id?`, element: <Vendors /> },
        { path: `${crmPaths.SUPPLIERS_PATH}/:id?`, element: <Supplier /> },
        // { path: `${crmRoutes.USER_PROFILE_PATH}/:id?`, element: <ProfileSection /> },

        // Interactions pages
        { path: `${crmPaths.TASKS_PATH}/:id?`, element: <Tasks /> },
        { path: `${crmPaths.TIME_SHEET_PATH}/:id?`, element: <TimeSheets /> },
        {
          path: `${sideBarPaths.CALENDAR_EVENTS_PATH}/:id?`,
          element: <CalendarEvents />,
        },

        // Reports pages
        { path: `${crmPaths.REPORTS_PATH}/:id?`, element: <Reports /> },
        { path: `${crmPaths.CUSTOM_REPORTS_PATH}/:id?`, element: <CustomReports /> },

        // Sidebar pages
        { path: `${crmPaths.ANALYTICS_PATH}/:id?`, element: <Analytics /> },
        {
          path: `${sideBarPaths.ANALYTICS_DASHBOARD_PATH}/:id?`,
          element: <AnalyticsDashboard />,
        },
        {
          path: `${sideBarPaths.BUSINESS_DASHBOARD_PATH}/:id?`,
          element: <BusinessDashboard />,
        },
        { path: `${sideBarPaths.CUSTOMERS_PATH}/:id?`, element: <Customers /> },
        { path: `${sideBarPaths.ORDERS_PATH}/:id?`, element: <Orders /> },
        { path: `${sideBarPaths.INVENTORY_PATH}/:id?`, element: <Inventory /> },

        // Inventory pages
        { path: `${crmPaths.ASSETS_PATH}/:id?`, element: <Assets /> },
        { path: `${crmPaths.PRODUCTS_PATH}/:id?`, element: <Products /> },
        { path: `${crmPaths.LOADS_PATH}/:id?`, element: <Loads /> },
        { path: `${crmPaths.GRA_PATH}/:id?`, element: <Gra /> },

        // Order pages
        {
          path: `${crmPaths.DISPATCH_ORDERS_PATH}/:id?`,
          element: <DispatchOrders />,
        },
        {
          path: `${crmPaths.PAYMENT_ORDERS_PATH}/:id?`,
          element: <PaymentOrders />,
        },
        {
          path: `${crmPaths.PURCHASE_ORDERS_PATH}/:id?`,
          element: <PurchaseOrders />,
        },
        {
          path: `${crmPaths.SALES_ORDERS_PATH}/:id?`,
          element: <SalesOrders />,
        },
        { path: `${crmPaths.WORK_ORDERS_PATH}/:id?`, element: <WorkOrders /> },

        // Invoice pages
        { path: `${crmPaths.INVOICES_PATH}/:id?`, element: <Invoices /> },

        // 404 page
        { path: '/*', element: '404 Page Not Found' },
      ])}
    </Suspense>
    // </Container>
  );
};

export default CrmRoutes;
