import React, { useState } from 'react';

import { Grid, Grid2 } from '@mui/material';
import DialogBox from 'common/dataDisplay/dialogBox/DialogBox';
import { blanccoIntegrate } from 'api/profileApis/profileApis';

import CustomerProjectSelectors from 'components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors';
import InputField from 'common/input/InputField';
import RichNoteField from 'common/input/RichNoteField';

import useReactForm from 'hooks/useReactForm';
import PaperBox from 'common/ui/PaperBox';

import { useDispatch, useSelector } from 'react-redux';

import { setErrorDialogText, setSnackBar } from 'redux/slices/commonSlice/commonSlice';

const gridSize = {
  size: {
    xs: 12,
  }
}

export const IntegrationForm = ({
  openDialog = true,
  handleDialogClose = () => { },
}) => {
  const dispatch = useDispatch();
  const { userAccount } = useSelector((state) => state?.userDetails);

  const [loading, setLoading] = useState(false);
  const { formData, handleSubmit, reset } = useReactForm({});

  const onSubmit = (data) => {
    setLoading(true);

    blanccoIntegrate({
      accountId: userAccount?.accountId,
      isActive: true,
      ...data
    })
      ?.then(() => {
        dispatch(setSnackBar({
          open: true,
          message: 'Blancco api integrated successfully!'
        }));
        handleDialogClose();
        reset();
      })
      ?.catch(() => dispatch(setErrorDialogText('Error occurred while integrating blancco api!')))
      ?.finally(() => setLoading(false));
  }

  return (
    <DialogBox
      open={openDialog}
      handleClose={() => handleDialogClose()}
      title="Blancco Integration"
      disableSubmitNew
      maxWidth='xs'
      handleFormSubmit={() => handleSubmit(onSubmit)()}
      loading={loading}
    >
      <PaperBox>
        <Grid2 container spacing={2}>
          <Grid2 {...gridSize}>
            <InputField
              formData={formData}
              name="name"
              label="Api key name"
              required
            />
          </Grid2>
          <Grid2 {...gridSize}>
            <InputField
              formData={formData}
              name="ownerEmail"
              label="Owner Email"
              type='email'
              required
            />
          </Grid2>
          <Grid2 {...gridSize}>
            <InputField
              formData={formData}
              name="apI_Key"
              label="Api key"
              required
            />
          </Grid2>
          <Grid2 {...gridSize}>
            <InputField
              formData={formData}
              name="apI_Secret"
              label="Api Secret"
              required
              type='password'
            />
          </Grid2>
          <Grid2 {...gridSize}>
            <InputField
              formData={formData}
              name="API_BaseURL"
              label="Api BaseUrl"
              required
            />
          </Grid2>
          <Grid2 {...gridSize}>
            <CustomerProjectSelectors
              formData={formData}
              xs={12}
            />
          </Grid2>
          <Grid2 {...gridSize}>
            <RichNoteField
              formData={formData}
              name="description"
              label="Api key description"
              minWidth='100%'
              placeholder='Detailed description'
              required
            />
          </Grid2>
        </Grid2>
      </PaperBox>
    </DialogBox>
  )
}
