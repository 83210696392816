import React from 'react';

import DatePicker from 'common/input/DatePicker';
import { dateTimeFormatter } from 'utils/textFormatUtils';

const commonColumnProps = {
  flex: 1,
  minWidth: 100,
  editable: false,
  headerAlign: 'left',
  align: 'left',
};

const dateCell = () => ({
  type: 'date',
  valueFormatter: dateTimeFormatter,
  renderEditCell: (props) => {
    const { id, value, field, api } = props;

    const handleDateChange = (newValue) => {
      api.setEditCellValue({ id, field, value: newValue });
    };
    return <DatePicker {...props} onChange={handleDateChange} />;
  },
});

export const salesorderColumns = (props = {}) => {
  const { saleOrderStatuses, saleOrderTypes } = props;
  return [
    {
      field: 'salesOrderNumber',
      headerName: 'Sales Order',
      ...commonColumnProps,
    },
    { field: 'orderRef', headerName: 'Order Ref', ...commonColumnProps },
    {
      field: 'salesOrderStatus',
      headerName: 'Status',
      ...commonColumnProps,
      type: 'singleSelect',
      valueOptions: saleOrderStatuses,
    },
    {
      field: 'orderType',
      headerName: 'Type',
      ...commonColumnProps,
      type: 'singleSelect',
      valueOptions: saleOrderTypes,
    },
    {
      field: 'thirdPartyOrderNo',
      headerName: 'ThirdParty Order',
      ...commonColumnProps,
    },
    {
      field: 'project',
      headerName: 'Project',
      ...commonColumnProps,
    },
    {
      field: 'customer',
      headerName: 'Customer',
      ...commonColumnProps,
    },
    {
      field: 'salesPerson',
      headerName: 'Sales Person',
      ...commonColumnProps,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      ...commonColumnProps,
    },
    {
      field: 'salesOrderTotal',
      headerName: 'OrderTotal',
      ...commonColumnProps,
      type: 'number',
    },
    {
      field: 'netTotal',
      headerName: 'NetTotal',
      ...commonColumnProps,
      type: 'number',
    },
    {
      field: 'salesOrderDate',
      headerName: 'Order Date',
      ...commonColumnProps,
      ...dateCell(),
    },
  ];
};
