import React from 'react';

import PalletForm from '../componenets/forms/warehouseLogistics/PalletForm';

import { getAllPallets, createUpdatePallets } from 'api/profileApis/warehouseLogisticsApis';

import ProfileTable from '../componenets/ProfileTable';
import { palletColumns } from '../componenets/tableColumns/warehouseLogistics/palletColumns';

const Pallets = () => (
  <ProfileTable
    getApi={getAllPallets}
    createApi={createUpdatePallets}
    updateApi={createUpdatePallets}
    columns={palletColumns}
    title="Pallets"
    buttonLabel="Pallet"
    CreateForm={PalletForm}
    filterSelectorEnum={[
      {
        name: 'global',
        label: 'Search Pallets',
        placeholder: 'Search here...',
      },
    ]}
    onlyGlobalFilter
    dialogSize="sm"
    updateReplaceObjectKey="locationId"
  />
);

export default Pallets;
