import React from 'react';
import { useRoutes } from 'react-router-dom';

import CrmMain from 'pages/CrmMain';
import PublicRoutes from './PublicRoutes';

import SnackbarNotify from 'common/ui/SnackbarNotify';

const MainRoute = () => {
  const mainRouting = useRoutes([
    // { path: otherRoutes.LOGIN, element: <LoginSignup route={otherRoutes.LOGIN} /> },
    // { path: otherRoutes.SIGNUP, element: <LoginSignup route={otherRoutes.SIGNUP} /> },
    { path: '/crm/*', element: <CrmMain /> },
    { path: '/profile/*', element: <CrmMain /> },
    { path: '/*', element: <PublicRoutes /> },
  ]);

  return (
    <>
      <SnackbarNotify />
      {mainRouting}
    </>
  );
};

export default MainRoute;
