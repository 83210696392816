import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Selector from 'common/input/Selector';
import InputField from 'common/input/InputField';
import SwitchToggle from 'common/input/SwitchToggle';
import SearchSelect from 'common/input/SearchSelect';
import useScreenSize from 'hooks/useScreenSize';
import { useSelector } from 'react-redux';

import RichNoteField from 'common/input/RichNoteField';

import CustomerProjectSelectors from 'components/createFormComponents/dynamicSelectorFields/CustomerProjectSelectors';
import { limitNumberTo } from 'utils/helperFunctions';
import { fetchWarehouses, fetchWorkOrderList } from 'redux/slices/listSlice/listSlice';
import DynamicActionSelector from 'components/createFormComponents/dynamicSelectorFields/DynamicActionSelector';

const LoadsForm = ({ formData, isUpdate = false }) => {
  const { isMobile } = useScreenSize();

  const [isAutoGenerated, setIsAutoGenerated] = useState(false);
  // const [ssn, setSsn] = useState('');

  const commonInputProps = {
    formData: formData,
  };

  const gridItemSize = {
    sm: 4,
    md: 3,
    xs: 6,
  };

  return (
    <>
      <Grid
        px={!isMobile && 2}
        py={2}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        <Grid {...gridItemSize} item>
          <DynamicActionSelector
            name="warehouseId"
            label="Warehouse"
            fetchListAction={fetchWarehouses}
            getStateValue={state => state?.lists?.warehouses}
            {...commonInputProps}
          />
        </Grid>

        <CustomerProjectSelectors {...gridItemSize} formData={formData} />

        <Grid {...gridItemSize} item>
          <DynamicActionSelector
            required
            name="workOrderId"
            label="Work Order"
            {...commonInputProps}
            fetchListAction={fetchWorkOrderList}
            getStateValue={state => state?.lists?.workOrderList}
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="connote" label="connote" />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField
            {...commonInputProps}
            name="clientRef"
            label="clientRef"
          />
        </Grid>

        <Grid {...gridItemSize} item>
          <InputField {...commonInputProps} name="clientPO" label="clientPO" />
        </Grid>

        {!isUpdate && (
          <Grid {...gridItemSize} item>
            <SwitchToggle
              label="Is Auto Generated"
              checked={isAutoGenerated}
              onChange={(value) => {
                setIsAutoGenerated(value);
                !value && formData?.setValue('estimatedQty', 0);
              }}
            />
          </Grid>
        )}

        {isAutoGenerated && (
          <Grid {...gridItemSize} item>
            <InputField
              required
              name="estimatedQty"
              label="Estimated Qty"
              type="number"
              format={(value) => limitNumberTo(value, 5000)}
              {...commonInputProps}
            />
          </Grid>
        )}

        <Grid sm={12} md={12} xs={12} item>
          <RichNoteField
            formData={formData}
            name="comments"
            label="Comments"
            placeholder="Detailed comments..."
          />
        </Grid>

        {/* {isAutoGenerated && (
          <Grid {...gridItemSize} item>
            <InputField
              name="startingSSN"
              label="Starting SSN"
              placeholder="SSN Here.."
              {...commonInputProps}
            />
          </Grid>
        )} */}
      </Grid>
    </>
  );
};

export default LoadsForm;
