import React from 'react'

import PlatformHero from './sections/PlatformHero';
import PlatformInfo from './sections/PlatformInfo';
import LearnFaster from '../../components/public/LearnFaster';

const PlatformPage = () => {
  return <>
    <PlatformHero />
    <PlatformInfo />
    <LearnFaster
      title='Transform Your Business Today'
      description='Discover more about how MasterCRM can transform your business. Contact us for a demo today.'
      minHeight='340px'
    />
  </>
};

export default PlatformPage;