import React, { memo } from 'react'

import DrawerBar from 'common/dataDisplay/drawerBar/DrawerBar'
import DividerLine from 'common/ui/DividerLine';
import { navMap } from './mapping/navMap'
import { Box, Button, IconButton, Typography } from '@mui/material'

import master_crm_logo from 'assets/master-crm-logo.png';
import { otherRoutes } from 'routes/allRoutesMap';

import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';

const MobilePublicDrawer = ({
  open = false,
  handleClose = () => { },
  handleNav = () => { },
}) => {
  return (
    <DrawerBar
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          py: 2,
          px: 3,
          minWidth: { xs: '100vw', sm: '360px' },
          height: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          display='flex'
          alignItems='start'
          justifyContent='space-between'
        >
          <Box
            component='img'
            src={master_crm_logo}
            sx={{
              width: { xs: '44px', sm: '64px' },
            }}
          />
          <IconButton
            color='secondary'
            onClick={handleClose}
          >
            <CloseIcon sx={{ scale: 1.3 }} />
          </IconButton>
        </Box>

        <Box
          flex={1}
          sx={{
            my: { xs: 2, sm: 3 },
          }}
        >
          {navMap?.map((item, key) => (
            <Box
              key={key}
              sx={{
                borderBottom: theme => `1px solid ${theme.palette.secondary.main}`,
                py: { xs: 2.2, sm: 3 },
                '&:hover': {
                  cursor: 'pointer',
                  '& .MuiTypography-root': {
                    color: 'primary.main',
                  }
                }
              }}
              onClick={() => {
                handleNav(item?.path);
                handleClose();
              }}
            >
              <Typography variant='h4' fontWeight={500}>
                {item?.label}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            my: 2,
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            size='large'
            fullWidth
            startIcon={<PersonIcon />}
            onClick={() => {
              handleNav(otherRoutes.LOGIN);
              handleClose();
            }}
          >
            Login
          </Button>
          <Button
            variant='contained'
            size='large'
            fullWidth
            onClick={() => {
              handleNav(otherRoutes.SIGNUP);
              handleClose();
            }}
          >
            Get Started
          </Button>
        </Box>

      </Box>
    </DrawerBar>
  )
}

export default memo(MobilePublicDrawer);
