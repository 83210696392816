import React, { useState, memo, Fragment } from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListSubheader,
  Box,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

const MenuItem = memo(({ item, level, openKey, onClick, currentPath }) => {
  const isListItem = isEmpty(item?.list);
  const isOpenedPath = isListItem
    ? currentPath === item?.path
    : item?.list?.includes(currentPath);
  const isOpen = item?.list?.some((value) => value?.path === openKey);

  return (
    <>
      <ListItemButton
        sx={{
          m: 0.5,
          borderRadius: 1,
          px: level * 2,
          backgroundColor: (theme) =>
            isListItem && isOpenedPath
              ? 'primary.main'
              : isOpen
                ? `${theme.palette.primary.main}20`
                : 'white',
          '&:hover': {
            backgroundColor: (theme) =>
              isListItem && isOpenedPath
                ? 'primary.main'
                : `${theme.palette.primary.main}20`,
          },
        }}
        onClick={() => onClick(item?.list?.[0]?.path || null, item?.path)}
        disableRipple={!isEmpty(item?.list)}
      >
        <ListItemIcon
          sx={{
            '& svg': {
              color: isOpenedPath ? 'white' : 'secondary.main',
              fontSize: '22px',
            },
          }}
        >
          {item?.icon}
        </ListItemIcon>
        <ListItemText
          primary={item?.label}
          slotProps={{
            primary: {
              variant: isListItem ? 'p2' : 'p3',
              sx: {
                lineHeight: '18px',
                fontWeight: isListItem ? 'normal' : '500',
                color: isOpenedPath ? 'white' : 'common.text',
              },
            },
          }}
        />
        {!isEmpty(item?.list) && (isOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>

      {!isEmpty(item?.list) && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.list.map((subItem, key) => (
              <MenuItem
                key={key}
                item={subItem}
                level={level + 1}
                onClick={onClick}
                openKey={openKey}
                currentPath={currentPath}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
});

export default function ProfileSideMenu({
  data = [
    {
      icon: <ManageAccountsRoundedIcon />,
      label: 'Test label 1',
      list: [
        {
          icon: <ManageAccountsRoundedIcon />,
          label: 'Test label 2',
          path: '',
        },
        {
          icon: <ManageAccountsRoundedIcon />,
          label: 'Test label 3',
          path: '',
        },
      ],
    },
  ],
  subHeader = 'Settings',
  handleClick = () => { },
  sx = {},
  ...rest
}) {
  const [openKey, setOpenKey] = useState(null);

  const fullPath = window.location.pathname.split('/');
  const currentPath = fullPath[fullPath?.length - 1];

  useEffect(() => {
    setOpenKey(currentPath);
  }, [currentPath]);

  const onClick = (key, path) => {
    if (path) {
      handleClick(path);
      return;
    }
    setOpenKey((prev) => (prev === key ? null : key));
  };

  return (
    <Box
      sx={{
        minWidth: '260px',
        width: '260px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: '48px',
          height: 'calc(100dvh - 48px)',
          overflowY: 'auto',
          width: '100%',
          bgcolor: 'background.paper',
          ...sx,
          '& .MuiListItemIcon-root': { minWidth: '36px' },
          // '& .MuiButtonBase-root:hover': { background: theme => `${theme.palette.primary.main}20` },
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        {...rest}
      >
        <ListSubheader component="div">{subHeader}</ListSubheader>
        <List component="div" disablePadding>
          {data.map((item, key) => (
            <Fragment key={key}>
              <MenuItem
                item={item}
                openKey={openKey}
                onClick={onClick}
                level={1}
                currentPath={currentPath}
              />
              {/* <DividerLine color="common.hoverGrey" height="1px" /> */}
            </Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
}
