import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import {
  isEmpty,
  filter,
  omitBy,
  isNil,
  includes,
  every,
  isArray,
  difference,
  some,
  find,
  trim,
  isString,
} from 'lodash';

import { parseISO, isBefore, isSameDay, isAfter } from 'date-fns';

import SearchSelect from 'common/input/SearchSelect';
import InputField from 'common/input/InputField';
import DatePicker from 'common/input/DatePicker';
import PopperMenu from 'common/navigation/popperMenu/PopperMenu';
import PaperBox from 'common/ui/PaperBox';

import { Box, Button, Grid, Typography, IconButton } from '@mui/material';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useReactForm from 'hooks/useReactForm';

const TableFilters = ({
  filterSelectorEnum,
  filterSelectorEnumNoBox,
  data,
  handleChange,
  formData: externalFormData,
  resetFields = () => { },
  enableClearFilter = false,
  clearFilterCallback = () => { },
  onlyGlobal,
  // isSmall,
  flexReverse,
  returnValue,
  isCalendarData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [filterValues, setFilterValues] = useState({});
  const [filteredData, setFilteredData] = useState(data);
  const [currentFilterValue, setCurrentFilterValue] = useState({});

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const clearFilters = useMemo(() => {
    const hasFiltered =
      filteredData?.length < data?.length || enableClearFilter;
    return hasFiltered;
  }, [filteredData, enableClearFilter, data]);

  const { formData, reset } = useReactForm({});

  useEffect(() => {
    const filteredRows = isEmpty(filterValues)
      ? data
      : filter(data, (obj) =>
        every(filterValues, (value, key = '') => {
          const fieldObj = find(filterSelectorEnum, (field) => {
            const keyName = field?.date ? key?.slice(0, -1) : key;
            return field?.name === keyName;
          });

          //if asked for value then we ignore the filter as the value is id and ids cannot be compared to the actual data
          if (fieldObj?.returnValue) return true;

          const {
            name = '',
            multiple = false,
            date = false,
            // endDate = false,
          } = fieldObj;

          const keyName = date ? key?.slice(0, -1) : key;

          const propValue = isCalendarData
            ? obj?.extendedProps[keyName]
            : obj[keyName];

          // if (value === null || !value.length) return true; // Include the whole object if any value is null
          if (!value || (isArray(value) && value.length === 0)) return true;

          //if its a date
          if (date) {
            if (isNil(propValue)) return;

            const parsedDataDate = parseISO(propValue);
            const parsedFilterDate = parseISO(value);

            //A = start date | B = end date
            if (name + 'A' === key) {
              setSelectedStartDate(parsedFilterDate);
              return (
                isBefore(parsedFilterDate, parsedDataDate) ||
                isSameDay(parsedFilterDate, parsedDataDate)
              );
            }
            if (name + 'B' === key) {
              setSelectedEndDate(parsedFilterDate);
              return (
                isAfter(parsedFilterDate, parsedDataDate) ||
                isSameDay(parsedFilterDate, parsedDataDate)
              );
            }
          }

          //if its multi select
          if (multiple) {
            if (isArray(propValue)) {
              if (isEmpty(propValue)) return false;

              return difference(propValue, value)?.length === 0;
            }
            const trimmedValue = isString(value) ? trim(value) : value;
            return trimmedValue?.includes(isString(propValue) ? trim(propValue) : propValue);
          }

          //if its global
          if (name === 'global') {
            const haveFound = some(
              isCalendarData ? obj?.extendedProps : obj,
              (item) => {
                return includes(
                  String(item)?.toLowerCase(),
                  value?.toLowerCase(),
                );
              },
            );
            return haveFound;
          }

          //if its single input field or select
          return includes(
            trim(String(propValue))?.toLowerCase(),
            trim(String(value))?.toLowerCase(),
          );
        }),
      );

    setFilteredData(filteredRows, omitBy(filterValues, isNil));
  }, [filterValues, data]);

  useEffect(() => {
    if (isEmpty(data)) return;

    handleChange(filteredData, currentFilterValue, clearFilters);
  }, [filteredData]);

  const handleClearFilters = useCallback(() => {
    setCurrentFilterValue({});
    setFilterValues({});
    resetFields();
    reset({});
    clearFilterCallback();
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    // setFilteredData(data);
  }, [data]);

  useEffect(() => {
    setFilteredData(data);
    resetFields();
    reset({});
  }, [data]);

  return (
    <>
      <Box
        display="flex"
        columnGap={2}
        rowGap={1}
        sx={{
          // '& .MuiInputBase-root, .MuiButtonBase-root': {
          //   minHeight: isSmall ? '35px !important' : '48px',
          //   height: isSmall ? '35px !important' : '48px',
          // },
          flexDirection: flexReverse ? 'row-reverse' : 'row',
        }}
      >
        {clearFilters && (
          <Button size="tiny" onClick={handleClearFilters}>
            <Typography variant="p3">Clear filters</Typography>
          </Button>
        )}

        {!!filterSelectorEnumNoBox?.length &&
          filterSelectorEnumNoBox?.map((field, key) =>
            field?.name === 'global' && field?.isInput ? (
              <InputField
                key={key}
                size="tiny"
                name={field?.name}
                label={field?.label}
                placeholder={field?.placeholder}
                formData={externalFormData || formData}
                onChange={(name, value) => {
                  setFilterValues((prev) => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                sx={{ width: '160px' }}
              />
            ) : (
              <SearchSelect
                key={key}
                variant="outlined"
                name={field?.name}
                size="tiny"
                searchSelectData={field?.data}
                // disabled={!field?.data?.length}
                label={field?.label}
                formData={externalFormData || formData}
                required={field?.required}
                returnLabel={field?.returnValue ? false : !returnValue}
                onChange={(name, value) => {
                  setFilterValues((prev) => ({
                    ...prev,
                    [name]: value,
                  }));
                  // setCurrentFilterValue({[camelCase(name)]: value});
                  setCurrentFilterValue({ [name]: value });
                }}
                multiple={field?.multiple}
                disableCloseOnSelect={!!field?.multiple}
                sx={{ width: '160px' }}
              />
            ),
          )}

        {!!data?.length && (
          <>
            {filterSelectorEnum?.map(
              (field, key) =>
                field?.name === 'global' && (
                  <InputField
                    key={key}
                    size="tiny"
                    name={field?.name}
                    label={field?.label}
                    placeholder={field?.placeholder}
                    formData={externalFormData || formData}
                    onChange={(name, value) => {
                      setFilterValues((prev) => ({
                        ...prev,
                        [name]: value,
                      }));
                    }}
                  />
                ),
            )}

            {!onlyGlobal && (
              <Button
                size="tiny"
                variant="outlined"
                color="secondary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                endIcon={<TuneRoundedIcon />}
                sx={{ minWidth: '100px' }}
              >
                Filters
              </Button>
            )}
          </>
        )}
      </Box>

      <PopperMenu
        open={!!anchorEl}
        onClickAway={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        sx={{
          width: '100%',
          maxWidth: '670px',
        }}
        isBox
        placement="bottom-start"
      // keepMounted
      >
        <PaperBox sx={{ pt: 2, px: { xs: 1, sm: '20px' }, pb: 3 }}>
          <Grid
            container
            rowSpacing={2.5}
            columnSpacing={{ xs: 1, sm: 2 }}
            minWidth="300px"
            maxWidth="550px"
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" fontWeight="600">
                Filters
              </Typography>
              <IconButton
                size="tiny"
                onClick={() => setAnchorEl(null)}
                color="secondary"
              >
                <CloseRoundedIcon sx={{ width: '24px', height: '24px' }} />
              </IconButton>
            </Grid>
            {!!filterSelectorEnum?.length &&
              filterSelectorEnum?.map((field, key) => {
                if (field?.name === 'global') return '';
                return (
                  <Grid
                    item
                    xs={field?.fullWidth || field?.date ? 12 : 6}
                    key={key}
                  >
                    {field?.isInput ? (
                      <InputField
                        name={field?.name}
                        label={field?.label}
                        formData={externalFormData || formData}
                        onChange={(name, value) => {
                          setFilterValues((prev) => ({
                            ...prev,
                            [name]: value,
                          }));
                        }}
                      />
                    ) : field?.date ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="flex-start"
                        gap={{ xs: 1, sm: 2 }}
                      >
                        <DatePicker
                          name={field?.name + 'A'}
                          label={field?.label + ' From'}
                          maxDate={selectedEndDate}
                          formData={externalFormData || formData}
                          onChange={(value) => {
                            setFilterValues((prev) => ({
                              ...prev,
                              [field?.name + 'A']: value,
                            }));
                          }}
                          actions={['clear', 'accept']}
                        />
                        <DatePicker
                          name={field?.name + 'B'}
                          label={field?.label + ' To'}
                          minDate={selectedStartDate}
                          formData={externalFormData || formData}
                          onChange={(value) => {
                            setFilterValues((prev) => ({
                              ...prev,
                              [field?.name + 'B']: value,
                            }));
                          }}
                          actions={['clear', 'accept']}
                        />
                      </Box>
                    ) : (
                      <SearchSelect
                        variant="outlined"
                        name={field?.name}
                        searchSelectData={field?.data}
                        // disabled={!field?.data?.length}
                        label={field?.label}
                        formData={externalFormData || formData}
                        required={field?.required}
                        returnLabel={field?.returnValue ? false : !returnValue}
                        onChange={(name, value) => {
                          setFilterValues((prev) => ({
                            ...prev,
                            [name]: value,
                          }));
                          // setCurrentFilterValue({[camelCase(name)]: value});
                          setCurrentFilterValue({ [name]: value });
                        }}
                        multiple={field?.multiple}
                        disableCloseOnSelect={!!field?.multiple}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
          {!filterSelectorEnum?.length && (
            <Box mt={1} textAlign="center">
              <Typography variant="p" fontWeight="600">
                No filters found...
              </Typography>
            </Box>
          )}
        </PaperBox>
      </PopperMenu>
    </>
  );
};

export default memo(TableFilters);
