import React, { memo } from 'react'

import { Box, Container, Grid2, IconButton, Typography } from '@mui/material';
import { otherRoutes } from 'routes/allRoutesMap';
import { useNavigate } from 'react-router-dom';
import master_crm_logo from 'assets/master-crm-logo.png';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import DividerLine from 'common/ui/DividerLine';

import useScreenSize from 'hooks/useScreenSize';

const socialMap = [
  {
    icon: FacebookIcon,
    link: 'https://www.linkedin.com/company/master-crm-pty-ltd',
  },
  {
    icon: LinkedInIcon,
    link: 'https://www.linkedin.com/company/master-crm-pty-ltd',
  },
  {
    icon: WhatsAppIcon,
    link: 'https://www.linkedin.com/company/master-crm-pty-ltd',
  },
  {
    icon: YouTubeIcon,
    link: 'https://www.linkedin.com/company/master-crm-pty-ltd',
  },
  {
    icon: InstagramIcon,
    link: 'https://www.linkedin.com/company/master-crm-pty-ltd',
  },
]

const quickNavMap = [
  {
    label: 'Home Page',
    path: otherRoutes.HOME_PAGE,
  },
  {
    label: 'Login',
    path: otherRoutes.LOGIN,
  },
  {
    label: 'Sign up',
    path: otherRoutes.SIGNUP,
  },
  {
    label: 'About Us',
    path: otherRoutes.ABOUT_US,
  },
  {
    label: 'Products',
    path: otherRoutes.PRODUCT_PAGE,
  },
]

const mcrmNavMap = [
  {
    label: 'Privacy Policy',
    path: otherRoutes.PRIVACY_POLICY,
  },
  {
    label: 'Contact Us',
    path: otherRoutes.CONTACT_US_PAGE,
  },
]

const Footer = () => {
  const nav = useNavigate();

  const { isMobile } = useScreenSize();

  const handleNavClick = (path) => {
    nav(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'secondary.main',
      }}
    >
      <Box
        sx={{
          py: 9,
          px: 2,
        }}
      >
        <Container maxWidth='xl'>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 6, md: 3 }}>
              <Typography color='white' variant={isMobile ? 'h4' : 'h3'} fontWeight={400}>
                Quick Navigate
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: 'inline-flex',
                  flexDirection: 'column',
                }}
              >
                {quickNavMap?.map((item, key) => (
                  <Typography
                    key={key}
                    color='white'
                    variant='p'
                    fontWeight={300}
                    sx={{
                      py: 1.5,
                      '&:hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }
                    }}
                    onClick={() => handleNavClick(item?.path)}
                  >
                    {item?.label}
                  </Typography>
                ))}
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 6, md: 3 }}>
              <Typography color='white' variant={isMobile ? 'h4' : 'h3'} fontWeight={400}>
                MasterCRM
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: 'inline-flex',
                  flexDirection: 'column',
                }}
              >
                {mcrmNavMap?.map((item, key) => (
                  <Typography
                    key={key}
                    color='white'
                    variant='p'
                    fontWeight={300}
                    sx={{
                      py: 1.5,
                      '&:hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }
                    }}
                    onClick={() => handleNavClick(item?.path)}
                  >
                    {item?.label}
                  </Typography>
                ))}
              </Box>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <Box
                sx={{
                  px: 4,
                  py: 3,
                  background: '#FFFFFF0D',
                  borderRadius: '20px',
                }}
              >
                <Grid2 container spacing={2}>
                  <Grid2
                    size={{ xs: 12, md: 8 }}
                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                  >
                    <Typography
                      color='white'
                      variant='h6'
                      fontWeight={500}
                      fontSize='30px'
                    >
                      Connect with MasterCRM
                    </Typography>
                    <Box
                      display='flex'
                      flexDirection='column'
                      my={1}
                    >
                      <Typography
                        color='white'
                        variant='p'
                        fontWeight={300}
                        sx={{
                          py: 1,
                          '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                        }}
                        onClick={() => window.open('mailto:Basan@mastercrm.com.au')}
                      >
                        Basan@mastercrm.com.au
                      </Typography>
                      <Typography
                        color='white'
                        variant='p'
                        fontWeight={300}
                        sx={{
                          py: 1,
                          '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                        }}
                        onClick={() => window.open('tel:+610419248784')}
                      >
                        Mobile: +61-0419248784
                      </Typography>

                      <Box
                        mt={3}
                        display='flex'
                        gap={2}
                        sx={{
                          justifyContent: { xs: 'center', md: 'left' }
                        }}
                      >
                        {socialMap?.map((item, key) => (
                          <IconButton
                            key={key}
                            onClick={() => window.open(item?.link)}
                            color='secondary'
                            sx={{
                              background: '#FFCB5B',
                              '&:hover': {
                                background: '#FFCB5B',
                              }
                            }}
                          >
                            <item.icon sx={{ fontSize: '20px' }} />
                          </IconButton>
                        ))}
                      </Box>
                    </Box>
                  </Grid2>

                  <Grid2
                    size={{ xs: 12, md: 4 }}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Box
                      component='img'
                      src={master_crm_logo}
                      sx={{
                        width: '140px',
                        '&:hover': { cursor: 'pointer' }
                      }}
                      onClick={() => handleNavClick(otherRoutes.LOGIN)}
                    />
                  </Grid2>
                </Grid2>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box>

      <DividerLine height='1px' color='#FFFFFF33' />

      <Box py={2} textAlign='center'>
        <Typography color='white' variant='p2' fontWeight={300}>
          All rights reserved by MasterCRM 2024
        </Typography>
      </Box>
    </Box >
  )
}

export default memo(Footer);