import React, { useState, useEffect, memo, useMemo, useCallback, useRef } from 'react';
import { debounce, has, isArray, isEmpty, isEqualWith, isNumber, isObject, mapValues, omit, omitBy, pick, toNumber, toString } from 'lodash';

import SearchSelect from 'common/input/SearchSelect';
import InputField from 'common/input/InputField';
import DatePicker from 'common/input/DatePicker';
import PopperMenu from 'common/navigation/popperMenu/PopperMenu';
import PaperBox from 'common/ui/PaperBox';

import { Box, Button, Grid, Typography, IconButton } from '@mui/material';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useReactForm from 'hooks/useReactForm';

import { useUrlParams } from 'hooks/useUrlParams';

const DynamicFilters = ({
  filterSelectorEnum,
  filterSelectorEnumNoBox,
  fetchApi,
  handleChange = () => { },
  handleFieldChange = () => { },
  flexReverse,
  onlyGlobal,
  pageSize = 20,
  setLoading = () => { },
  triggerApiReFetch,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({});

  const [urlParams, setUrlParams] = useUrlParams();

  const { formData, reset } = useReactForm({});

  const customComparator = useCallback((val1, val2) => {
    if (isArray(val1) && isArray(val2)) {
      return isEqualWith(
        val1.map(String),
        val2.map(String)
      );
    }
    return undefined;
  }, []);

  const isFilterDifferent = useCallback(
    (values = {}) => !isEqualWith(urlParams, values, customComparator),
    [filterValues, urlParams],
  );

  const clearFilters = !isEmpty(omit(urlParams, "pageNumber"));

  const handleClearFilters = () => {
    const hasPageNumber = !!urlParams?.pageNumber;
    // If pageNumber is present, keep it, else set it to 1
    const newParams = hasPageNumber ? pick(urlParams, ['pageNumber']) : { pageNumber: 1 };
    setUrlParams(newParams);
    setFilterValues({});
    reset({});
  }

  const debounceFetchData = debounce(() => {
    if (isEmpty(urlParams)) {
      handleClearFilters();
      return;
    }

    reset(urlParams);
    setFilterValues(urlParams);

    //wrap all values in array except pageNumber or any object
    const wrappedInArray = mapValues(urlParams, (value, key) =>
      key === 'pageNumber' || isObject(value) ? value : isArray(value) ? value : [value]
    );

    //make all under this line to be called by parent component to make this reusable;

    let ssn = []
    if (!isEmpty(wrappedInArray?.ssn)) {
      ssn = wrappedInArray?.ssn?.map(item => toString(item));
    }

    //api has to have pageNumber and pageSize as keys
    if (fetchApi) {
      fetchApi({
        ...wrappedInArray,
        pageSize,
        pageNumber: urlParams?.pageNumber || 1,
        ssn,
        // "sort": {
        //   "ascending": "string",
        //   "descending": "string"
        // }
      })?.then((res) => {
        const { data, ...rest } = res;
        handleChange(data, { ...rest });
        setLoading(false);
      });
    }
  }, 250);

  useEffect(() => {
    setLoading(true);
    debounceFetchData();
    return () => {
      debounceFetchData.cancel();
    };
  }, [pageSize, urlParams, triggerApiReFetch]);

  const updateFilters = useCallback((name, value) => {
    handleFieldChange({ [name]: value });
    setFilterValues((prev) => (
      omitBy(
        {
          ...prev,
          [name]: value,
        },
        (v) => v === '' || (Array.isArray(v) && isEmpty(v)),
      )
    ));
  }, []);

  const applyFilters = () => {
    if (isFilterDifferent(filterValues)) {
      setUrlParams(filterValues);
    }
  }

  return (
    <>
      <Box
        display="flex"
        columnGap={2}
        rowGap={1}
        sx={{
          flexDirection: flexReverse ? 'row-reverse' : 'row',
        }}
      >
        {clearFilters && (
          <Button size="tiny" onClick={handleClearFilters}>
            <Typography variant="p3">Clear filters</Typography>
          </Button>
        )}

        {isFilterDifferent(filterValues) &&
          <Button size='tiny' variant='outlined' onClick={applyFilters}>Apply Filters</Button>
        }

        {!isEmpty(filterSelectorEnumNoBox) &&
          filterSelectorEnumNoBox?.map((field, key) =>
            field?.name === 'global' || field?.isInput ? (
              <InputField
                key={key}
                size="tiny"
                name={field?.name}
                label={field?.label}
                placeholder={field?.placeholder}
                formData={formData}
                onChange={updateFilters}
                sx={{ width: '160px' }}
              />
            ) : (
              <SearchSelect
                key={key}
                variant="outlined"
                name={field?.name}
                size="tiny"
                searchSelectData={field?.data}
                // disabled={!field?.data?.length}
                label={field?.label}
                formData={formData}
                required={field?.required}
                onChange={updateFilters}
                multiple={field?.multiple}
                disableCloseOnSelect={!!field?.multiple}
                sx={{ width: '160px' }}
              />
            ),
          )}

        {!isEmpty(filterSelectorEnum) && (
          <>
            {filterSelectorEnum?.map(
              (field, key) =>
                field?.name === 'global' && (
                  <InputField
                    key={key}
                    size="tiny"
                    name={field?.name}
                    label={field?.label}
                    placeholder={field?.placeholder}
                    formData={formData}
                    onChange={updateFilters}
                  />
                ),
            )}

            {!onlyGlobal && (
              <Button
                size="tiny"
                variant="outlined"
                color="secondary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                endIcon={<TuneRoundedIcon />}
                sx={{ minWidth: '100px' }}
              >
                Filters
              </Button>
            )}
          </>
        )}
      </Box>

      <PopperMenu
        open={!!anchorEl}
        onClickAway={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        sx={{
          width: '100%',
          maxWidth: '670px',
        }}
        isBox
        placement="bottom-start"
      // keepMounted
      >
        <PaperBox sx={{ pt: 2, px: { xs: 1, sm: '20px' }, pb: 2 }}>
          <Grid
            container
            rowSpacing={2.5}
            columnSpacing={{ xs: 1, sm: 2 }}
            minWidth="300px"
            maxWidth="550px"
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display='flex' alignItems='center' columnGap={2}>
                <Typography variant="h5" fontWeight="600">
                  Filters
                </Typography>
                {clearFilters && (
                  <Button size="tiny" onClick={handleClearFilters}>
                    <Typography variant="p3">Clear filters</Typography>
                  </Button>
                )}
              </Box>
              <IconButton
                size="tiny"
                onClick={() => setAnchorEl(null)}
                color="secondary"
              >
                <CloseRoundedIcon sx={{ width: '24px', height: '24px' }} />
              </IconButton>
            </Grid>
            {!!filterSelectorEnum?.length &&
              filterSelectorEnum?.map((field, key) => {
                if (field?.name === 'global') return '';
                return (
                  <Grid item xs={field?.fullWidth ? 12 : 6} key={key}>
                    {field?.isInput ? (
                      <InputField
                        name={field?.name}
                        label={field?.label}
                        formData={formData}
                        onChange={updateFilters}
                      />
                    ) : field?.date ? (
                      <DatePicker
                        name={field?.name}
                        label={field?.label}
                        formData={formData}
                        onChange={(value) => {
                          updateFilters(field?.name, value);
                        }}
                        actions={['clear', 'accept']}
                      />
                    ) : (
                      <SearchSelect
                        variant="outlined"
                        name={field?.name}
                        searchSelectData={field?.data}
                        // disabled={!field?.data?.length}
                        label={field?.label}
                        formData={formData}
                        required={field?.required}
                        onChange={updateFilters}
                        multiple={field?.multiple}
                        disableCloseOnSelect={!!field?.multiple}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
          {!filterSelectorEnum?.length && (
            <Box mt={1} textAlign="center">
              <Typography variant="p" fontWeight="600">
                No filters found...
              </Typography>
            </Box>
          )}

          <Box width="100%" mt={2.5}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              onClick={applyFilters}
              disabled={!isFilterDifferent(filterValues)}
            >
              Apply Filters
            </Button>
          </Box>
        </PaperBox>
      </PopperMenu>
    </>
  );
};

export default memo(DynamicFilters);
