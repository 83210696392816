import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Custom hook that checks a slice's value and dispatches a fetch action if it's empty.
 * @param {Function} selector - A function to select the slice value from the Redux store.
 * @param {Function} fetchAction - The thunk action creator to fetch the data.
 * @returns {*} - The slice value from the Redux store.
 */

export const useFetchAction = (selector, fetchAction) => {
  const dispatch = useDispatch();
  const sliceValue = useSelector(selector);

  useEffect(() => {
    if (isEmpty(sliceValue)) {
      dispatch(fetchAction());
    }
  }, [dispatch, fetchAction, sliceValue]);

  return sliceValue;
};