import React, { memo } from 'react'

import { Box, Container, Typography, Grid2 } from '@mui/material';
import { ReactComponent as Checkmark } from 'assets/staticPages/homePage/checkmark.svg';

import { transitions } from 'core/transitions';

const cardContent = [
  {
    dataAos: {
      aos: "fade-right",
      aosDelay: "0",
    },
    heading: 'Seamless Lead and Contact Management',
    description: <>Efficiently manage leads and contacts with comprehensive profiles, interactive dashboards, and seamless updates to maintain accurate and <span style={{ whiteSpace: "nowrap" }}>up-to-date</span> information.</>,
  },
  {
    dataAos: {
      aos: "fade-left",
      aosDelay: "100",
    },
    heading: 'Enhanced Deal Pipeline Visibility',
    description: 'Monitor and progress deals through various stages with real-time updates, ensuring that you can track and optimize your sales process effectively.',
  },
  {
    dataAos: {
      aos: "fade-right",
      aosDelay: "200",
    },
    heading: 'Powerful Al-Driven Analytics',
    description: 'Leverage Al for predictive lead scoring and customer behavior analysis to uncover future sales opportunities, optimize pricing strategies, and enhance user experience.',
  },
  {
    dataAos: {
      aos: "fade-left",
      aosDelay: "300",
    },
    heading: 'Comprehensive Customizable Reporting',
    description: 'Generate insightful, customizable reports across various metrics such as leads, sales, and product performance, providing you with the data needed to make informed business decisions.',
  },
];



const BenefitCard = ({ content = {} }) => (
  <Box
    sx={{
      p: 3,
      width: { xs: '100%', sm: '400px', md: '500px', xl: '680px' },
      borderRadius: '10px',
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      gap: { xs: 2, md: 3 },
      transition: transitions('border').common,
      border: theme => `2px solid ${theme.palette.common.backgroundBlue}`,
      '&:hover': {
        border: theme => `2px solid ${theme.palette.primary.main}`,
      }
    }}
    data-aos={content?.dataAos?.aos}
    data-aos-delay={content?.dataAos?.aosDelay}
  >
    <Box
      sx={{
        width: 'fit-content',
        p: { xs: 1, md: 1.5 },
        borderRadius: '10px',
        background: theme => theme.palette.common.backgroundBlue,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
          width: { xs: '32px', md: '48px' },
          height: 'auto',
        }
      }}
    >
      <Checkmark />
    </Box>

    <Typography
      variant='h4'
      sx={{
        fontSize: { xs: '24px', md: '28px' },
        lineHeight: '30px',
      }}
    >
      {content?.heading}
    </Typography>
    <Typography
      variant='p'
      color='common.textLight'
      lineHeight='28px'
    >
      {content?.description}
    </Typography>
  </Box >
);

const Benefits = () => {
  return (
    <Box backgroundColor='common.subtleGrey'>
      <Container
        maxWidth='xl'
        sx={{
          pt: { xs: 6, md: 10 },
          pb: { xs: 7, lg: 12 },
        }}
      >
        <Typography
          component='div'
          textAlign='center'
          variant='h2p'
          sx={{
            fontSize: { xs: '36px', md: '48px' }
          }}
          fontWeight='600'
        >
          Benefits of MasterCRM
        </Typography>

        <Box
          display='flex'
          justifyContent='center'
          alignItems='stretch'
          flexWrap='wrap'
          sx={{
            mt: { xs: 4, md: 7 },
            gap: { xs: 3, md: 5 },
          }}
        >
          {cardContent?.map((item, key) => (
            <BenefitCard content={item} key={key} />
          ))}

        </Box>
      </Container>
    </Box>
  )
}

export default memo(Benefits);