import React from 'react';

import AisleForm from '../componenets/forms/warehouseLogistics/AisleForm';

import { getAllAisles, createUpdateAisles } from 'api/profileApis/warehouseLogisticsApis';

import ProfileTable from '../componenets/ProfileTable';
import { aisleColumns } from '../componenets/tableColumns/warehouseLogistics/aisleColumns';

const Aisles = () => (
  <ProfileTable
    getApi={getAllAisles}
    createApi={createUpdateAisles}
    updateApi={createUpdateAisles}
    columns={aisleColumns}
    title="Aisles"
    buttonLabel="Aisle"
    CreateForm={AisleForm}
    filterSelectorEnum={[
      {
        name: 'global',
        label: 'Search Aisles',
        placeholder: 'Search here...',
      },
    ]}
    onlyGlobalFilter
    dialogSize="sm"
    updateReplaceObjectKey="locationId"
    addAccountId
  />
);

export default Aisles;
