import React from 'react'

import { Container, Box, Typography } from '@mui/material';

const SmallInfoBox = ({
  title = '',
  description = '',
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#F8F9FB',
        p: { xs: 3, md: 4 },
        borderRadius: 2,
        border: '1px solid #D0D3D6',
        '&:hover': {
          borderColor: 'primary.main',
        }
      }}
    >
      <Typography
        component='div'
        variant='p'
        color='common.text'
        fontWeight={600}
        sx={{
          fontSize: { xs: '24px', md: '32px' },
          lineHeight: '130%',
          mb: { xs: 2, md: 3 },
        }}
      >
        {title}
      </Typography>
      <Typography
        component='div'
        variant='p'
        color='common.textLight'
        sx={{
          fontSize: { xs: '16px', md: '18px' },
          lineHeight: '160%',
        }}
      >
        {description}
      </Typography>

    </Box>
  )
};

export default SmallInfoBox;
