import instanceApi from './instanceApi';
import _ from 'lodash';

export const getAllAssets = async () => {
  const res = await instanceApi.get('Asset/Assets');
  return res?.data;
};
export const getAssetDetails = async (id) => {
  const res = await instanceApi.get(`Asset/GetAssetDetails/${id}`);
  return res?.data;
};
export const createAsset = async (body) => {
  const res = await instanceApi.post('Asset/CreateAsset', body);
  return res;
};

export const updateSingleAsset = async (body) => {
  // removing all the unnecessary data
  const filteredBody = _.omit(body, [
    'locationId',
    'ssn',
    'make',
    'model',
    'barcode',
    'grade',
    'subGrade',
    'itemType',
    'product',
    'assetStatus',
    'project',
    'load',
    'warehouseId',
    'warehouse',
    'dateReceived',
    'physicalInspectionDate',
  ]);

  const payload = {
    ...filteredBody,
    assetStatusId: body?.assetStatus,
    // makeId: body?.make,
    // modelId: body?.model,
    loadId: body?.load,
    WarehouseId_Fk: body?.warehouseId,
  };

  const cleanedPayload = _.omitBy(
    payload,
    (value) => _.isNil(value) || value === '',
  );

  const res = await instanceApi.post('Asset/UpdateAsset', cleanedPayload);
  return res;
};

export const updateAssets = async (body) => {
  const res = await instanceApi.post('Asset/UpdateAssets', body);
  return res;
};
export const createBulkAsset = async (body) => {
  const res = await instanceApi.post('Asset/CreateAssets', body);
  return res?.data;
};
export const assetImport = async (body) => {
  const res = await instanceApi.post('Asset/ImportAssets', body);
  return res?.data;
};
//Asset attach
export const attachAssetToLoad = async (body) => {
  const res = await instanceApi.post('Asset/AssignAssetsToLoad', body);
  return res;
};
//Asset Testing api
export const getAssetTestConditions = async (assetID) => {
  const res = await instanceApi.get(
    `AssetTesting/AssetTestConditions/${assetID}`,
  );
  return res?.data;
};
export const submitAssetTestConditions = async (body) => {
  const res = await instanceApi.post(
    'AssetTesting/SubmitAssetTestConditions',
    body,
  );
  return res;
};
export const submitBulkAssetTestConditions = async (body) => {
  const res = await instanceApi.post(
    'AssetTesting/SubmitBulkAssetTestConditions',
    body,
  );
  return res;
};
export const createTestConditions = async (body) => {
  const res = await instanceApi.post('AssetTesting/CreateTestConditions', body);
  return res?.data;
};
export const updateTestConditions = async (body) => {
  const res = await instanceApi.post('AssetTesting/UpdateTestConditions', body);
  return res?.data;
};
export const getTestConditionTypes = async () => {
  const res = await instanceApi.get('AssetTesting/TestConditionTypes');
  return res?.data;
};

//Asset recycling report
export const getAssetRecycleReport = async () => {
  const res = await instanceApi.get('Reports/AssetRecyclingReport');
  return res?.data;
};
//Asset Transfer
export const getAssetTransferLocations = async () => {
  const res = await instanceApi.get('Asset/TransferLocations');
  return res?.data;
};
export const submitAssetTransferLocation = async (body) => {
  const res = await instanceApi.post('Asset/TransferAssetsLocation', body);
  return res;
};

export const getFilteredAssets = async (body = {}) => {
  const res = await instanceApi.post('Asset/GetFilteredAssets', body);
  return res?.data;
};
