import { useEffect, useMemo, useState, memo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { Box } from "@mui/material";

const ParticlesGenerator = ({
  color = ["#ffffff", '#ff826d']
}) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options = useMemo(
    () => ({
      fullScreen: { enable: false }, // Keeps it inside the parent container
      fpsLimit: 60,
      particles: {
        color: color,
        move: {
          enable: true,
          direction: "top", // Moves up
          speed: { min: 1, max: 3 }, // Slow movement
          straight: false, // Slightly random floating
          outModes: { default: "out" }, // Bubbles respawn at the bottom
        },
        number: {
          density: { enable: true, value_area: 800 },
          value: 20,
        },
        opacity: {
          value: { min: 0.15, max: 0.35 },
          random: true,
        },
        shape: { type: "circle" },
        size: {
          value: { min: 10, max: 45 },
          random: true,
          animation: {
            enable: true,
            speed: 7, // Controls how fast they grow/shrink
            minimumValue: 10, // Minimum size after shrinking
            sync: false, // Each particle animates independently
          },
        },
      },
      detectRetina: true,
    }),
    []
  );


  if (!init) return null;

  return (
    <Box
      sx={{
        '& #tsparticles': {
          position: "absolute",
          zIndex: 0,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }
      }}
    >
      <Particles
        id="tsparticles"
        options={options}
      />
    </Box>
  );
};

export default memo(ParticlesGenerator);
