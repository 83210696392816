import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAdminPermissions } from 'api/masterApi';
import { isEmpty } from 'lodash';

export const fetchAdminPermissions = createAsyncThunk(
  'actions/fetchAdminPermissions',
  async () => await getAdminPermissions(),
);

export const adminPermissionsSlice = createSlice({
  name: 'adminPermissions',
  initialState: [],
  extraReducers: (builder) => {
    builder.addCase(fetchAdminPermissions.fulfilled, (state, action) => {
      if (isEmpty(action.payload)) return state;
      return action.payload;
    });
  },
});

export default adminPermissionsSlice.reducer;
