import React, { memo, useState } from 'react'

import linesBackground from 'assets/staticPages/homePage/linesBackground.png';
import { Typography, Box, Container, Button } from '@mui/material';
import ContactFormDialog from 'pages/components/public/ContactFormDialog';

const LearnFaster = ({
  disableButton = false,
  title = '',
  description = '',
  minHeight = '480px',
  onClick,
  ctaLabel = 'Get a demo',
}) => {
  const [openContactDialog, setOpenContactDialog] = useState(false);

  return <>
    <Box
      sx={{
        backgroundImage: `url(${linesBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        minHeight,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container
        maxWidth='xl'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: { xs: 3, md: 4 },
          py: { xs: '60px', md: '100px' },
        }}
      >
        <Typography
          variant='h1'
          color='white'
          fontFamily='"Poppins", sans-serif'
          textAlign='center'
          sx={{ fontSize: { xs: '36px', md: '56px' } }}
        >
          {title}
        </Typography>
        <Typography
          component='div'
          maxWidth='1200px'
          variant='p'
          fontSize='18px'
          color='white'
          textAlign='center'
          lineHeight='32px'
        >
          {description}
        </Typography>
        {!disableButton &&
          <Box mt={2}>
            <Button
              variant='contained'
              color='primary'
              size='large'
              sx={{ px: 6, py: 4, }}
              onClick={() => {
                onClick ? onClick() : setOpenContactDialog(true);
                window.scrollTo(0, 0);
              }}
            >
              <Typography variant='p' fontSize='18px' color='white'>{ctaLabel}</Typography>
            </Button>
          </Box>
        }
      </Container>
    </Box >

    <ContactFormDialog open={openContactDialog} onClose={setOpenContactDialog} />
  </>
}

export default memo(LearnFaster);