import React from 'react';

import Grid2 from '@mui/material/Grid2';
import InputField from 'common/input/InputField';
import SwitchToggle from 'common/input/SwitchToggle';
import SearchSelect from 'common/input/SearchSelect';
import { formRegex, numberOnly } from 'utils/textFormatUtils';

const LocationForm = ({ formData = {} }) => {
  const gridItemSize = {
    size: {
      sm: 6,
      xs: 12,
    }
  };

  return (
    <Grid2 sx={{ px: { xs: 2 }, py: 2 }} container spacing={2}>

      <Grid2 {...gridItemSize}>
        <InputField formData={formData} name="location" required />
      </Grid2>

      <Grid2 {...gridItemSize}>
        <SearchSelect
          formData={formData}
          name="warehouse"
          required
        />
      </Grid2>

      <Grid2 {...gridItemSize}>
        <InputField formData={formData} name="suburb" required />
      </Grid2>
      <Grid2 {...gridItemSize}>
        <InputField formData={formData} name="addressLine" required />
      </Grid2>
      <Grid2 {...gridItemSize}>
        <InputField formData={formData} name="state" required />
      </Grid2>

      <Grid2 {...gridItemSize}>
        <InputField
          formData={formData}
          name="postCode"
          format={numberOnly}
          required
        />
      </Grid2>
      <Grid2 {...gridItemSize}>
        <InputField
          formData={formData}
          name="phoneNo"
          variant="outlined"
          required
          rules={{
            pattern: {
              value: formRegex.mobile,
              message: 'Invalid mobile number',
            },
          }}
        />
      </Grid2>
    </Grid2>
  );
};
export default LocationForm;
