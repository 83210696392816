//use these in profileRoutes and then use these as unique indexes to open the menus
//path will become /profile/account etc just the absolute path

export const profileRoutesMap = {
  //Overview
  ACCOUNT: 'account',
  PROJECTS: 'projects',
  CUSTOMERS: 'customers',

  // General Administration
  NOTIFICATIONS: 'notifications',
  BILLING_PLAN: 'billing',
  SECURITY: 'security',

  // Users
  USER_PERMISSIONS: 'permissions',
  THIRD_PARTY_INTEGRATIONS: 'integrations',

  // Assets & Inventory
  TEST_CONDITIONS: 'test-conditions',
  ITEM_TYPES: 'item-types',
  STATUSES: 'statuses',
  MAKES_MODELS: 'makes-models',
  PROCESSORS: 'processors',
  SCREEN_TYPES: 'screen-types',
  HDD_MEMORY: 'hdd-memory',
  OPERATING_SYSTEMS: 'operating-systems',
  COLORS: 'colors',
  SERVICE_ITEMS: 'service-items',
  SKUS: 'skus',

  // Warehouse & Logistics
  WAREHOUSES: 'warehouses',
  LOCATIONS: 'locations',
  AISLES: 'aisles',
  BINS: 'bins',
  CONTAINERS: 'containers',
  PALLETS: 'pallets',
  SHIPPING_METHODS: 'shipping-methods',

  // Compliance & Quality Control
  AUDIT_LOGS: 'audit-logs',
  ASSET_GRADING: 'asset-grading',
  SLAS: 'slas',
  BACKUP_RECOVERY: 'backup-recovery',

  // Process & Documents
  WORKFLOW_AUTOMATION: 'workflow-automation',
  DOCUMENT_MANAGEMENT: 'document-management',
};
