import React, { useState, useEffect, useCallback } from 'react';

import useScreenSize from '../../hooks/useScreenSize';
import { Controller } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DesktopDatePicker,
  MobileDatePicker,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import FieldLabel from './FieldLabel';

import { Box, IconButton, TextField, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { isValid, format as formatDate } from 'date-fns';
import { isEqual, isNil } from 'lodash';

const DatePicker = ({
  formData,
  name = '',
  label,
  inputFormat = 'dd/MM/yyyy',
  dateFormat = "yyyy-MM-dd'T'HH:mm:ss",
  // openTo = 'year',
  required = false,
  isEditable = false,
  minTime,
  maxTime,
  isDateTime = false,
  onChange = () => {},
  onEditApply = () => {},
  isSmallHeight,
  textFieldProps = {},
  size = 'small',
  actions = ['clear', 'cancel', 'accept'],
  value,
  disableStar,
  ...rest
}) => {
  const watchedValue = formData ? formData?.watch(name) : null;

  const [editedValue, setEditedValue] = useState(null);
  const [isError, setIsError] = useState(null);

  const { isMobile } = useScreenSize();

  useEffect(() => {
    (!isValid(editedValue) || !editedValue) && setEditedValue(watchedValue);
  }, [watchedValue]);

  // const minYear = minDate?.getFullYear() ?? addYears(new Date(), -100).getFullYear();
  // const maxYear = maxDate?.getFullYear() ?? addYears(new Date(), 100).getFullYear();

  const onChangeHandler = (field, newDate, validation) => {
    setIsError(null);

    const { validationError } = validation;
    if (!isNil(validationError)) {
      setIsError('Invalid Date');
    }

    const formattedDate = isNil(newDate)
      ? null
      : formatDate(newDate, dateFormat);

    isEditable ? setEditedValue(newDate) : field?.onChange(formattedDate);
    onChange(formattedDate);
  };

  const onEditCancel = () => {
    //fix this
    setEditedValue(watchedValue);
    setIsError(null);
  };

  const isValueEditedSame = () => {
    if (!isEditable || !watchedValue || !editedValue) return;

    // const formattedDate = formatDate(parseISO(watchedValue), 'dd/MM/yyyy');
    // const formattedEditedDate = formatDate(parseISO(editedValue), 'dd/MM/yyyy');

    return !isEqual(new Date(watchedValue), new Date(editedValue)) && !isError;
  };

  const onClickApply = () => {
    if (!editedValue) {
      onEditCancel();
      return;
    }
    const formattedValue = formatDate(editedValue, dateFormat);
    formData?.setValue(name, formattedValue);
    onEditApply(name, formattedValue);
    isValueEditedSame();
  };

  const handleBlur = () => {
    isEditable && !!isError && onEditCancel();
  };

  const datePickerProps = (field) => ({
    label: (
      <FieldLabel
        label={label}
        name={name}
        required={required}
        disableStar={disableStar}
      />
    ),
    placeholder: isDateTime ? 'Click to select' : '',
    format: `${inputFormat}${isDateTime ? ' hh:mm a' : ''}`,
    openTo: 'day',
    value: isEditable
      ? new Date(editedValue)
      : field?.value
        ? new Date(field.value)
        : null,
    onChange: (newDate, validation) =>
      onChangeHandler(field, newDate, validation),
    onBlur: handleBlur,
    componentsProps: {
      actionBar: { actions },
    },
    slotProps: {
      actionBar: {
        actions,
      },
      textField: (pickerParams) => ({
        error: !!isError || !!formData?.errors[name],
        helperText: isError,
        size,
        ...textFieldProps,
        ...pickerParams,
      }),
    },
    slots: {
      textField: TextField,
    },
    ...rest,
  });

  const EditButtons = () => (
    <>
      <IconButton size="small" onClick={onClickApply}>
        <CheckCircleRoundedIcon color="primary" fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={onEditCancel}>
        <CancelOutlinedIcon fontSize="small" />
      </IconButton>
    </>
  );

  if (!formData) {
    if (isDateTime) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDateTimePicker
            {...datePickerProps()}
            minTime={minTime}
            maxTime={maxTime}
          />
        </LocalizationProvider>
      );
    }
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {isMobile ? (
          <MobileDatePicker
            {...datePickerProps()}
            // value={field.value || null}
          />
        ) : (
          <DesktopDatePicker
            {...datePickerProps()}
            // value={field.value || null}
          />
        )}
      </LocalizationProvider>
    );
  }

  if (isDateTime)
    return (
      <Box display="flex" alignItems="flex-end" width="100%">
        <Controller
          name={name}
          control={formData.control}
          rules={{ required }}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                {...field}
                {...datePickerProps(field)}
                minTime={minTime}
                maxTime={maxTime}
              />
            </LocalizationProvider>
          )}
        />
        {isValueEditedSame() && <EditButtons />}
      </Box>
    );

  return (
    <Box display="flex" alignItems="flex-end" width="100%">
      <Controller
        name={name}
        control={formData.control}
        rules={{ required }}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {isMobile ? (
              <MobileDatePicker
                {...field}
                {...datePickerProps(field)}
                // value={field.value || null}
              />
            ) : (
              <DesktopDatePicker
                {...field}
                {...datePickerProps(field)}
                // value={field.value || null}
              />
            )}
          </LocalizationProvider>
        )}
      />
      {isValueEditedSame() && <EditButtons />}
    </Box>
  );
};

export default DatePicker;
