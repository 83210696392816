import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAssetRecycleReport } from 'api/assetsApi';
import { isEmpty } from 'lodash';

export const fetchAssetRecyclingReport = createAsyncThunk(
  'actions/fetchAssetRecyclingReport',
  async () => await getAssetRecycleReport(),
);

export const assetRecycleReportSlice = createSlice({
  name: 'assetRecyclingReport',
  initialState: [],
  extraReducers: (builder) => {
    builder.addCase(fetchAssetRecyclingReport.fulfilled, (state, action) => {
      if (isEmpty(action.payload)) return state;
      return action.payload;
    });
  },
});

export default assetRecycleReportSlice.reducer;
