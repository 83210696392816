import { Box, Typography } from '@mui/material';
import DatePicker from 'common/input/DatePicker';

import { dateTimeFormatter, toCurrency } from 'utils/textFormatUtils';

import AssetGrade from 'pages/components/assets/testComponents/AssetGrade';

const commonColumnProps = {
  flex: 1,
  minWidth: 100,
  editable: false,
  headerAlign: 'left',
  align: 'left',
};

const dateCell = () => ({
  type: 'date',
  // valueGetter: ({ value }) => {console.log(value); return value && new Date(value)},
  valueFormatter: dateTimeFormatter,
  renderEditCell: (props) => {
    const { id, value, field, api } = props;

    const handleDateChange = (newValue) => {
      api.setEditCellValue({ id, field, value: newValue });
    };
    return <DatePicker {...props} onChange={handleDateChange} />;
  },
});

export const assetsColumns = (props = {}) => {
  const { makes, models, itemTypes, projects, loads, warehouses, assetStatus } =
    props;
  return [
    //{ field: 'id', headerName: 'ID', width: 20},
    {
      field: 'ssn',
      headerName: 'SSN',
      ...commonColumnProps,
      type: 'number',
      required: true,
    },
    {
      field: 'make',
      headerName: 'Make',
      ...commonColumnProps,
      type: 'singleSelect',
      valueOptions: makes,
    },
    {
      field: 'model',
      headerName: 'Model',
      ...commonColumnProps,
      type: 'singleSelect',
      valueOptions: models,
    },
    { field: 'serialNo', headerName: 'Serial No', ...commonColumnProps },
    // { field: 'barcode', headerName: 'Barcode', ...commonColumnProps },
    { field: 'clientRef', headerName: 'ClientRef', ...commonColumnProps },
    {
      field: 'clientAssetTag',
      headerName: 'Client Asset Tag',
      ...commonColumnProps,
    },
    // { field: 'subGrade', headerName: 'subGrade', ...commonColumnProps },
    // { field: 'reservationCode', headerName: 'reservationCode', ...commonColumnProps },
    // { field: 'reservationDate', headerName: 'reservationDate', ...commonColumnProps, ...dateCell() },
    // { field: 'reservationComment', headerName: 'reservationComment', ...commonColumnProps },
    // { field: 'recycle', headerName: 'recycle', ...commonColumnProps, type: 'boolean' },
    // { field: 'releaseDate', headerName: 'releaseDate', ...commonColumnProps, ...dateCell() },
    // { field: 'completeness', headerName: 'completeness', ...commonColumnProps },
    // { field: 'appearance', headerName: 'appearance', ...commonColumnProps },
    // { field: 'services', headerName: 'services', ...commonColumnProps },
    // { field: 'operability', headerName: 'operability', ...commonColumnProps },
    // { field: 'qtyOnHand', headerName: 'qtyOnHand', ...commonColumnProps, type: 'number' },
    // { field: 'isSerialised', headerName: 'isSerialised', ...commonColumnProps, type: 'boolean' },
    // { field: 'physicalInspectionDate', headerName: 'physicalInspectionDate', ...commonColumnProps, ...dateCell() },
    // { field: 'physicalInspectionUser', headerName: 'physicalInspectionUser', ...commonColumnProps },
    {
      field: 'itemType',
      headerName: 'Item Type',
      ...commonColumnProps,
      type: 'singleSelect',
      valueOptions: itemTypes,
      required: true,
    },
    {
      field: 'assetStatus',
      headerName: 'Status',
      cellClassName: 'status',
      ...commonColumnProps,
      type: 'singleSelect',
      valueOptions: assetStatus,
    },
    {
      field: 'buyPrice',
      headerName: 'Buy Price',
      ...commonColumnProps,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Typography variant="p3" fontWeight="500">
          {toCurrency(params?.value || '')}
        </Typography>
      ),
    },
    {
      field: 'sellPrice',
      headerName: 'Sell Price',
      ...commonColumnProps,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Typography variant="p3" fontWeight="500">
          {toCurrency(params?.value || '')}
        </Typography>
      ),
    },
    {
      field: 'grade',
      headerName: 'Grade',
      ...commonColumnProps,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <AssetGrade grade={params?.value || ''} disableLabel isSmall />
        </Box>
      ),
    },
    { field: 'workOrder', headerName: 'Work Order', ...commonColumnProps },
    {
      field: 'project',
      headerName: 'Project',
      ...commonColumnProps,
      type: 'singleSelect',
      valueOptions: projects,
    },
    {
      field: 'load',
      headerName: 'Load',
      ...commonColumnProps,
      type: 'singleSelect',
      valueOptions: loads,
    },
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      ...commonColumnProps,
      type: 'singleSelect',
      valueOptions: warehouses,
      required: true,
    },
    {
      field: 'dateReceived',
      headerName: 'Received',
      ...commonColumnProps,
      ...dateCell(),
      minWidth: 120,
    },
    // { field: 'location', headerName: 'location', ...commonColumnProps, type: 'singleSelect',  valueOptions: locations },
    // { field: 'assetComment', headerName: 'assetComment', ...commonColumnProps },
    // { field: 'palletNo', headerName: 'palletNo', ...commonColumnProps, type: 'number' },
    // { field: 'r2Grade', headerName: 'r2Grade', ...commonColumnProps },
    // { field: 'modified', headerName: 'modified', ...commonColumnProps, type: 'boolean' },
  ];
};
