import React from 'react';
import { Navigate } from 'react-router-dom';

import AccountDetail from 'pages/userProfile/overview/AccountDetail';
import Projects from 'pages/userProfile/overview/Projects';
import Customers from 'pages/userProfile/overview/Customers';
import Notification from 'pages/userProfile/Notification';
import Connections from 'pages/userProfile/Connections';
import Security from 'pages/userProfile/Security';
import Billing from 'pages/userProfile/Billing';
import Statuses from 'pages/userProfile/assetsInventory/Statuses';
import UserPermissions from 'pages/userProfile/UserPermissions';
import TestConditions from 'pages/userProfile/assetsInventory/TestConditions';
import ItemTypes from 'pages/userProfile/assetsInventory/ItemTypes';
import Sku from 'pages/userProfile/assetsInventory/Sku';
import ServiceTypes from 'pages/userProfile/assetsInventory/ServiceTypes';
import Colors from 'pages/userProfile/assetsInventory/Colors';
import OperatingSystem from 'pages/userProfile/assetsInventory/OperatingSystem';
import ScreenTypes from 'pages/userProfile/assetsInventory/ScreenTypes';
import HDDsMemory from 'pages/userProfile/assetsInventory/HDDsMemory';
import Processor from 'pages/userProfile/assetsInventory/Processor';

import Warehouse from 'pages/userProfile/warehouseLogistics/Warehouse';
import Locations from 'pages/userProfile/warehouseLogistics/Locations';
import Aisles from 'pages/userProfile/warehouseLogistics/Aisles';
import Bins from 'pages/userProfile/warehouseLogistics/Bins';
import Containers from 'pages/userProfile/warehouseLogistics/Containers';
import Pallets from 'pages/userProfile/warehouseLogistics/Pallets';

import { useRoutes } from 'react-router-dom';

import { profileRoutesMap } from 'routes/profileRoutesMap';

const ProfileRoutes = () =>
  useRoutes([
    { path: profileRoutesMap.ACCOUNT, element: <AccountDetail /> },
    { path: profileRoutesMap.PROJECTS, element: <Projects /> },
    { path: profileRoutesMap.CUSTOMERS, element: <Customers /> },

    { path: profileRoutesMap.NOTIFICATIONS, element: <Notification /> },
    { path: profileRoutesMap.BILLING_PLAN, element: <Billing /> },
    { path: profileRoutesMap.SECURITY, element: <Security /> },

    { path: profileRoutesMap.USER_PERMISSIONS, element: <UserPermissions /> },
    {
      path: profileRoutesMap.THIRD_PARTY_INTEGRATIONS,
      element: <Connections />,
    },

    { path: profileRoutesMap.TEST_CONDITIONS, element: <TestConditions /> },
    { path: profileRoutesMap.ITEM_TYPES, element: <ItemTypes /> },
    { path: profileRoutesMap.STATUSES, element: <Statuses /> },
    { path: profileRoutesMap.MAKES_MODELS, element: <ItemTypes /> },
    { path: profileRoutesMap.PROCESSORS, element: <Processor /> },
    { path: profileRoutesMap.SCREEN_TYPES, element: <ScreenTypes /> },
    { path: profileRoutesMap.HDD_MEMORY, element: <HDDsMemory /> },
    { path: profileRoutesMap.OPERATING_SYSTEMS, element: <OperatingSystem /> },
    { path: profileRoutesMap.COLORS, element: <Colors /> },
    { path: profileRoutesMap.SERVICE_ITEMS, element: <ServiceTypes /> },
    { path: profileRoutesMap.SKUS, element: <Sku /> },

    { path: profileRoutesMap.WAREHOUSES, element: <Warehouse /> },
    { path: profileRoutesMap.LOCATIONS, element: <Locations /> },
    { path: profileRoutesMap.AISLES, element: <Aisles /> },
    { path: profileRoutesMap.BINS, element: <Bins /> },
    { path: profileRoutesMap.CONTAINERS, element: <Containers /> },
    { path: profileRoutesMap.PALLETS, element: <Pallets /> },
    { path: profileRoutesMap.SHIPPING_METHODS, element: <Warehouse /> },

    {
      path: profileRoutesMap.AUDIT_LOGS,
      element: 'Audit Logs and Compliance Monitoring',
    },
    { path: profileRoutesMap.ASSET_GRADING, element: <TestConditions /> },
    { path: profileRoutesMap.SLAS, element: 'Service Level Agreements (SLAs)' },
    {
      path: profileRoutesMap.BACKUP_RECOVERY,
      element: 'Backup and Recovery Settings',
    },

    {
      path: profileRoutesMap.WORKFLOW_AUTOMATION,
      element: 'Workflow Automation',
    },
    {
      path: profileRoutesMap.DOCUMENT_MANAGEMENT,
      element: 'Document Management',
    },

    { path: '/', element: <Navigate to={profileRoutesMap.ACCOUNT} /> },
  ]);

export default ProfileRoutes;
