import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { purchaseorderColumns } from 'pages/tableColumns/purchaseorderColumn';
import ActionPageMain from 'pages/components/PageMain';
import createFormEnum from 'enum/createFormEnum';

import { fetchPurchaseOrder } from 'redux/slices/actionSlice/orderSlice';

import { setErrorDialogText } from 'redux/slices/commonSlice/commonSlice';
import { useDispatch } from 'react-redux';

import { getPurchaseOrderById, uploadAttachmentsPO } from 'api/orderApis';
import { crmRoutes } from 'routes/allRoutesMap';

import { getFileFormData } from 'utils/fileHelperFunctions';

import {
  fetchPOStatuses,
  fetchPOTypes,
} from 'redux/slices/listSlice/listSlice';

import { isEmpty } from 'lodash';
import TableFilters from 'pages/components/TableFilters';

const PurchaseOrders = ({ ...props }) => {
  const dispatch = useDispatch();

  const { userProjects = [] } = useSelector((state) => state?.userDetails);
  const { purchase } = useSelector((state) => state?.actions?.orders);
  const { pOStatus, pOTypes } = useSelector((state) => state?.lists);

  const [filteredData, setFilteredData] = useState(purchase);

  useEffect(() => {
    isEmpty(pOStatus) && dispatch(fetchPOStatuses());
    isEmpty(pOTypes) && dispatch(fetchPOTypes());
  }, [pOStatus, pOTypes]);

  const handleUploadFile = async (returnData = {}, passedData = {}) => {
    const { purchaseOrderId } = returnData;
    if (!purchaseOrderId || !passedData?.files) return;

    await uploadAttachmentsPO(
      getFileFormData(passedData?.files),
      purchaseOrderId,
    )?.catch(() =>
      dispatch(
        setErrorDialogText(
          'Error occurred while uploading documents, please try again later.',
        ),
      ),
    );
  };

  const filterSelectorEnum = [
    {
      name: 'global',
      label: 'Global search',
      placeholder: 'Search work order...',
      isInput: true,
    },
    {
      name: 'orderType',
      label: 'Sale Order Type',
      data: pOTypes,
      multiple: true,
    },
    {
      name: 'salesOrderStatusId',
      label: 'Sale Order Status',
      data: pOStatus,
      multiple: true,
    },
    {
      name: 'projectId',
      label: 'Project',
      data: userProjects,
      multiple: true,
    },
    {
      name: 'salesOrderDate',
      label: 'Sale Order Date',
      date: true,
    },
  ];

  return (
    <>
      <ActionPageMain
        formKey={createFormEnum.purchase_orders}
        rows={filteredData}
        columns={purchaseorderColumns()}
        label="Purchase Orders"
        createLabel="Create Purchase Order"
        fetchApi={fetchPurchaseOrder}
        createFormProps={{
          disableSubmitNew: true,
          callback: handleUploadFile,
          maxWidth: 'lg',
        }}
        fetchByIdApi={getPurchaseOrderById}
        detailDataFetchIdKey="purchaseOrderId"
        drawerLabelKey="purchaseOrderNo"
        pagePath={crmRoutes.PURCHASE_ORDERS_PATH}
        {...props}
      >
        <TableFilters
          filterSelectorEnum={filterSelectorEnum}
          data={purchase}
          handleChange={(filteredData) => {
            setFilteredData(filteredData);
          }}
        />
      </ActionPageMain>
    </>
  );
};

export default PurchaseOrders;
