import instanceApi from '../instanceApi';

//Warehouse
export const getAllWarehouse = async () => {
  const res = await instanceApi.get('InventoryManagement/GetAllWarehouses');
  return res?.data;
};
export const createWarehouse = async (body) => {
  const res = await instanceApi.post(
    'InventoryManagement/CreateWarehouse',
    body,
  );
  return res?.data;
};
export const updateWarehouse = async (body) => {
  const res = await instanceApi.post(
    'InventoryManagement/UpdateWarehouse',
    body,
  );
  return res?.data;
};

//location
export const getAllLocations = async () => {
  const res = await instanceApi.get('InventoryManagement/LocationList');
  return res?.data;
};
export const createUpdateLocation = async (body) => {
  const res = await instanceApi.post(
    'InventoryManagement/CreateUpdateLocation',
    body,
  );
  return res?.data;
};

//aisles
export const getAllAisles = async () => {
  const res = await instanceApi.get('InventoryManagement/AislesList');
  return res?.data;
};
export const createUpdateAisles = async (body) => {
  const res = await instanceApi.post(
    'InventoryManagement/CreateUpdateWarehouseAisles',
    body,
  );
  return res?.data;
};

//bins
export const getAllBins = async () => {
  const res = await instanceApi.get('InventoryManagement/BinsList');
  return res?.data;
};
export const createUpdateBins = async (body) => {
  const res = await instanceApi.post(
    'InventoryManagement/CreateUpdateBin',
    body,
  );
  return res?.data;
};

//containers
export const getAllContainers = async () => {
  const res = await instanceApi.get('InventoryManagement/ContainerList');
  return res?.data;
};
export const createUpdateContainers = async (body) => {
  const res = await instanceApi.post(
    'InventoryManagement/CreateUpdateContainers',
    body,
  );
  return res?.data;
};

//pallets
export const getAllPallets = async () => {
  const res = await instanceApi.get('InventoryManagement/PalletsDashboard');
  return res?.data;
};
export const createUpdatePallets = async (body) => {
  const res = await instanceApi.post(
    'InventoryManagement/CreateUpdatePallets',
    body,
  );
  return res?.data;
};
