import React, { memo, useEffect, useState } from 'react'

import { Box, Button, Container, Grid2, Typography } from '@mui/material';

import { ReactComponent as Line } from 'assets/staticPages/lines.svg';
import { ReactComponent as Wave } from 'assets/staticPages/wave.svg';

import { random } from 'lodash';
import useScreenSize from 'hooks/useScreenSize';

import TextTransition from "react-text-transition";
import ParticlesGenerator from 'pages/components/public/ParticlesGenerator';
import ContactFormDialog from 'pages/components/public/ContactFormDialog';
import LaptopCarousel from 'pages/components/public/LaptopCarousel';

const titleTexts = [
  'Grow Better',
  'Boost Your Business',
  'Achieve More',
  'Simplify Growth',
  'Accelerate Success',
  'Scale Effortlessly',
  'Lead the Market',
  'Stay Ahead',
  'Unlock Potential',
  'Work Smarter',
];

const Hero = () => {
  const [titleTextIndex, setTitleTextIndex] = useState(random(0, 10));
  const [openContactDialog, setOpenContactDialog] = useState(false);

  const { isTablet } = useScreenSize();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTitleTextIndex((prevIndex) => (prevIndex + 1) % titleTexts.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return <>
    <Box
      width='100%'
      backgroundColor='secondary.main'
      position='relative'
      sx={{
        height: { xs: 'calc(100dvh - 64px)', md: 'calc(100dvh - 80px)' }
      }}
    >
      <ParticlesGenerator />

      <Box
        component={Line}
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: { xs: '-10%', lg: '-30%' },
          top: { xs: '-120px', lg: '-60px' },
        }}
      />
      <Container maxWidth='xl' sx={{ zIndex: 2, position: 'relative' }}>
        <Grid2
          container
          spacing={2}
          columns={13}
          sx={{
            pt: { xs: 8, lg: 16 },
            pb: 4,
          }}
        >
          <Grid2 size={{ xs: 13, lg: 5 }}>
            <Typography
              component='div'
              variant='p'
              color='white'
              whiteSpace='nowrap'
              sx={{
                '& .text-transition': {
                  justifyContent: { xs: 'center', lg: 'left' },
                  textAlign: { xs: 'center', lg: 'left' },
                },
                fontWeight: '600',
                textAlign: { xs: 'center', lg: 'left' },
                fontSize: { xs: '36px', sm: '50px', lg: '60px' },
              }}
              data-aos="fade-down"
            >
              <Box
                sx={{
                  height: { xs: '46px', sm: '60px', lg: '72px' },
                  '& div': {
                    whiteSpace: 'nowrap !important'
                  }
                }}
              >
                <TextTransition>
                  {titleTexts[titleTextIndex]}
                </TextTransition>
              </Box>
              with MasterCRM
            </Typography>

            <Box
              width='100%'
              display='flex'
              sx={{
                mt: { xs: 2.5, sm: 3, md: 4 },
                justifyContent: { xs: 'center', lg: 'left' },
              }}
              data-aos="fade-down"
              data-aos-delay="300"
            >
              <Typography
                variant='p'
                color='white'
                sx={{
                  fontSize: { xs: '16px', md: '18px' },
                  lineHeight: { xs: '26px', md: '32px' },
                  textAlign: { xs: 'center', lg: 'left' },
                  maxWidth: { xs: '680px', lg: '490px' },
                }}
              >
                Software that's powerful, not overpowering. Seamlessly connect your data, teams, and customers on one CRM platform that grows with your business.
              </Typography>
            </Box>

            <Box
              sx={{
                mt: { xs: 3, lg: 4 },
                display: 'flex',
                justifyContent: { xs: 'center', lg: 'left' }
              }}
              data-aos="fade-down"
              data-aos-delay="600"
            >
              <Button
                variant='contained'
                color='primary'
                size='large'
                sx={{ px: 6, py: 4, }}
                onClick={() => setOpenContactDialog(true)}
              >
                <Typography variant='p' fontSize='18px' color='white'>Get a demo</Typography>
              </Button>
            </Box>
          </Grid2>
          <Grid2
            size={{ xs: 13, lg: 8 }}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', lg: 'flex-end' }
            }}
          >
            <LaptopCarousel />
          </Grid2>
        </Grid2>
      </Container>

      <Box
        component={Wave}
        width='100%'
        height='22%'
        preserveAspectRatio={isTablet ? 'xMinYMax slice' : 'none'}
        sx={{
          position: 'absolute',
          bottom: -5, left: 0,
        }}
      />
    </Box>

    <ContactFormDialog open={openContactDialog} onClose={setOpenContactDialog} />
  </>
}

export default memo(Hero);
