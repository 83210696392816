import React, { useState, useEffect, memo, useMemo } from 'react';

import formComponentsEnum from 'enum/formComponentsEnum';
import useReactForm from 'hooks/useReactForm';

import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDispatch, useSelector } from 'react-redux';

import SpinLoader from 'common/dataDisplay/spinLoader/SpinLoader';
import {
  setErrorDialogText,
  setSnackBar,
} from 'redux/slices/commonSlice/commonSlice';
import { isEmpty } from 'lodash';

const UpdateForm = ({
  formKey = '',
  data = {},
  handleUpdate = () => { },
  replaceUpdate,
  isUpdate = false,
}) => {
  const { userAccount } = useSelector((state) => state?.userDetails);

  const [currentData, setCurrentData] = useState(data);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { formData, reset } = useReactForm();
  const watchedData = formData?.watch();

  useEffect(() => {
    if (!userAccount?.accountId || formData?.getValues('accountId')) return;

    formData?.setValue('accountId', userAccount?.accountId);
  }, [userAccount?.accountId]);

  useEffect(() => {
    if (isEmpty(data)) return;

    reset({
      ...data,
      accountId: userAccount?.accountId,
    });
  }, [data]);

  const formProps = useMemo(() => ({
    formData: formData,
    notrequired: true,
    isUpdate: isUpdate,
    handleUpdate,
  }), [formData, isUpdate, handleUpdate]);

  const handleClickUpdate = () => {
    if (replaceUpdate) {
      replaceUpdate(formData?.getValues());
      return;
    }

    setLoading(true);

    dispatch(formComponentsEnum()?.[formKey]?.updateApi(watchedData))
      .unwrap()
      .then(() => {
        handleUpdate(watchedData);
        setCurrentData(watchedData);
        reset({
          ...watchedData,
          accountId: userAccount?.accountId,
        });
        dispatch(
          setSnackBar({
            open: true,
            message: 'Info updated successfully!',
          }),
        );
      })
      .finally(async () => {
        setLoading(false);
      });
  };
  // .catch(() => {
  //   dispatch(
  //     setErrorDialogText('Error occurred while update, please try again.'),
  //   );
  // })

  return (
    <>
      <Box>{formComponentsEnum(formProps)?.[formKey]?.createForm}</Box>

      {formData?.isDirty && (
        <Box display="flex" justifyContent="flex-end" columnGap={1} mt={1}>
          <LoadingButton
            variant="outlined"
            color="secondary"
            sx={{
              flex: { xs: 1, sm: 0 },
              minWidth: 120,
            }}
            onClick={() => {
              reset(currentData);
            }}
            loading={loading}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              flex: { xs: 1, sm: 0 },
              minWidth: 120,
            }}
            onClick={handleClickUpdate}
            loading={loading}
          >
            Update
          </LoadingButton>
        </Box>
      )}

      <SpinLoader loading={loading} />
    </>
  );
};

export default memo(UpdateForm);
