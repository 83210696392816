import React, { memo } from 'react'

import { Box, Container, Grid2, Typography } from '@mui/material';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

const InfoSection = ({
  infoContent = [],
}) => {

  return (
    infoContent?.map((item, key) => {
      const isOdd = key % 2 === 0;

      return (
        <Box
          key={key}
          sx={{
            width: '100%',
            minHeight: '550px',
            backgroundColor: isOdd ? 'common.subtleGrey' : 'white',
            py: { xs: 8, lg: 12 },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Container maxWidth='false' sx={{ maxWidth: '1330px' }}>
            <Grid2
              container
              spacing={{ xs: 2, md: 4 }}
              direction={{ xs: 'column', md: isOdd ? 'row' : 'row-reverse' }}
            >
              <Grid2
                size={{ xs: 12, md: 6 }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: { xs: 'center', md: isOdd ? 'flex-start' : 'flex-end' },
                }}
              >
                <Box maxWidth='625px'>
                  <Typography
                    component='div'
                    variant='p'
                    color='common.text'
                    sx={{
                      maxWidth: '500px',
                      fontWeight: '600',
                      fontSize: { xs: '30px', md: '40px' },
                      lineHeight: { xs: '36px', md: '50px' },
                      mb: { xs: 2.5, md: 3.5 },
                    }}
                  >
                    {item.title}
                  </Typography>
                  {item?.bullets?.map((bullet, key) => (
                    <Box
                      key={key}
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                      }}
                    >
                      <CheckBoxRoundedIcon
                        sx={{
                          fontSize: '20px',
                          color: 'primary.main',
                          transform: 'translateY(1px)',
                        }} />
                      <Typography
                        component='div'
                        variant='p'
                        sx={{
                          lineHeight: '24px',
                          mb: 1.5,
                        }}
                      >
                        {bullet}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid2>

              <Grid2
                size={{ xs: 12, md: 6 }}
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', md: isOdd ? 'right' : 'left' },
                  alignItems: 'center',
                }}
              >
                <Box
                  component='img'
                  src={item?.img}
                  height='auto'
                  sx={{
                    maxWidth: '600px',
                    height: '100%',
                    width: '100%',
                  }}
                />
              </Grid2>
            </Grid2>

          </Container>
        </Box>
      );
    })
  );
};

export default memo(InfoSection);
