import React from 'react'

import Hero from './sections/ProductHero';
import Info from './sections/ProductInfo';

const ProductPage = () => {
  return <>
    <Hero />
    <Info />
  </>
}

export default ProductPage;
