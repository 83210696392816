import { Container, Grid2 } from '@mui/material'
import React, { memo } from 'react'

import SmallInfoBox from 'pages/components/public/SmallInfoBox';

const infoText = [
  {
    title: 'Seamless Integration',
    description: 'Connect your CRM with a host of other applications and services for a seamless workflow. Our API-first design means you can customize and extend your CRM as you grow.',
  },
  {
    title: 'Unified Customer Management',
    description: 'Keep track of every interaction with your customers through a centralized platform. From lead management to final sales, Master CRM ensures that every customer feels valued.',
  },
  {
    title: 'Mobile Accessibility',
    description: 'Access the full power of Master CRM on the go with our mobile app. Stay connected with your team and customers, no matter where you are.',
  },
  {
    title: 'Comprehensive Analytics',
    description: 'Leverage AI-driven analytics to understand customer behavior, predict sales trends, and make data-driven decisions to propel your business forward.',
  },
];

const PlatformInfo = () => {
  return (
    <Container maxWidth='xl' sx={{ py: { xs: 8, md: 10 } }}>
      <Grid2
        container
        spacing={{ xs: 3, md: 5 }}
        display='flex'
        justifyContent='center'
        alignItems='stretch'
      >
        {infoText.map((item, key) => (
          <Grid2 size={{ xs: 12, md: 6 }} key={key}>
            <SmallInfoBox title={item?.title} description={item?.description} />
          </Grid2>
        ))}
      </Grid2>
    </Container >
  )
};

export default memo(PlatformInfo);
