import React, { memo } from 'react'

import PricingCard from './PricingCard';
import { Grid2, Container, Box } from '@mui/material';

import Carousel from 'common/ui/Carousel';

const cardsContent = [
  {
    title: 'Basic',
    description: 'A simple start for everyone',
    price: '15',
    features: [
      "Lead Management",
      "Contact Management",
      "Vendor Management",
      "Quotes",
      "Deals",
      "Tasks",
      "Meetings",
      "Scheduler"
    ]
  },
  {
    title: 'Standard',
    description: 'For small to medium businesses',
    price: '25',
    features: [
      "Lead Management",
      "Contact Management",
      "Vendor Management",
      "Quotes",
      "Deals",
      "Tasks",
      "Meetings",
      "Scheduler",
      "Accounts/Customer Management",
      "HR- Resource Management"
    ]
  },
  {
    title: "Enterprise",
    description: "Solution for big organizations",
    price: "35",
    features: [
      "Lead Management",
      "Contact Management",
      "Vendor Management",
      "Quotes And Deals Management",
      "Tasks, Meetings And Scheduler",
      "Accounts / Customer Management",
      "HR - Resource Management",
      "Product Management",
      "Inventory And Warehouse Management",
      "Profit And Loss Interactive Live Analytics",
      "Customizable Reports",
      "AI Driven Predictive And Behavioral Analytics"
    ]
  }
]

const CardsSection = () => (
  <Container maxWidth="xl" sx={{ px: { xs: 0, md: 3 } }}>
    <Box
      minHeight={{ xs: '475px', lg: "430px" }}
      position="relative"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '-75%', lg: '-70%' },
          left: '50%',
          transform: "translateX(-50%)",
          width: '100%',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Grid2
          container
          spacing={4}
          display="flex"
          justifyContent="center"
          alignItems="stretch"
        >
          {cardsContent?.map((content, key) => (
            <Grid2 size={4} key={key}>
              <PricingCard key={key} cardContent={content} />
            </Grid2>
          ))}
        </Grid2>
      </Box>

      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          py: 4,
          '& .slick-list': {
            overflow: 'visible',
          },
          '& .slick-slide': {
            pl: 2,
          },
        }}
      >
        <Carousel
          infinite={false}
          autoplay={false}
          dots={false}
          centerMode={true}
          slidesToShow={1.2}
          arrows={false}
        >
          {cardsContent?.map((content, key) => (
            <PricingCard key={key} cardContent={content} />
          ))}
        </Carousel>
      </Box>
    </Box>
  </Container >
);

export default memo(CardsSection);
