import { otherRoutes } from 'routes/allRoutesMap';

export const navMap = [
  {
    label: 'Home',
    path: otherRoutes.HOME_PAGE,
  },
  {
    label: 'Products',
    path: otherRoutes.PRODUCT_PAGE,
  },
  {
    label: 'Platform',
    path: otherRoutes.PLATFORM_PAGE,
  },
  {
    label: 'Partners',
    path: otherRoutes.PARTNERS_PAGE,
  },
  {
    label: 'Resources',
    path: otherRoutes.RESOURCES_PAGE,
  },
  {
    label: 'Pricing',
    path: otherRoutes.PRICING_PAGE,
  },
  {
    label: 'Company',
    path: otherRoutes.COMPANY_PAGE,
  },
  {
    label: 'Contact Us',
    path: otherRoutes.CONTACT_US_PAGE,
  },
];
