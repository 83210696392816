import { dateTimeFormatter } from 'utils/textFormatUtils';

export const containerColumns = [
  {
    field: 'container',
    headerName: 'Container',
    width: 200,
  },
  {
    field: 'bin',
    headerName: 'Bin',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'account',
    headerName: 'Account',
    flex: 1,
  },
  {
    field: 'customer',
    headerName: 'Customer',
    flex: 1,
    minWidth: 160,
  },
  {
    field: 'project',
    headerName: 'Project',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    flex: 1,
    minWidth: 100,
    valueFormatter: dateTimeFormatter,
  },
  {
    field: 'modifiedAt',
    headerName: 'Modified at',
    valueFormatter: dateTimeFormatter,
    minWidth: 100,
    flex: 1,
  },
];
