import React from 'react';

import { locationColumns } from '../componenets/tableColumns/warehouseLogistics/locationColumns';
import LocationForm from '../componenets/forms/warehouseLogistics/LocationForm';

import { getAllLocations, createUpdateLocation } from 'api/profileApis/warehouseLogisticsApis';

import ProfileTable from '../componenets/ProfileTable';

const Locations = () => (
  <ProfileTable
    getApi={getAllLocations}
    createApi={createUpdateLocation}
    updateApi={createUpdateLocation}
    columns={locationColumns}
    title="Locations"
    buttonLabel="Location"
    CreateForm={LocationForm}
    filterSelectorEnum={[
      {
        name: 'global',
        label: 'Search Locations',
        placeholder: 'Search here...',
      },
    ]}
    onlyGlobalFilter
    dialogSize="sm"
    updateReplaceObjectKey="locationId"
  />
);

export default Locations;
