import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { dispatchorderColumns } from 'pages/tableColumns/dispatchorderColumns';
import PageMain from 'pages/components/PageMain';
import createFormEnum from 'enum/createFormEnum';

import { fetchDispatchOrder } from 'redux/slices/actionSlice/orderSlice';

import { setErrorDialogText } from 'redux/slices/commonSlice/commonSlice';
import { useDispatch } from 'react-redux';

import { getDispatchOrderById, uploadAttachmentsDO } from 'api/orderApis';
import { crmRoutes } from 'routes/allRoutesMap';

import { getFileFormData } from 'utils/fileHelperFunctions';

import {
  fetchDispatchMethods,
  fetchDispatchStatuses,
  fetchDispatchTypes,
} from 'redux/slices/listSlice/listSlice';
import { isEmpty } from 'lodash';
import TableFilters from 'pages/components/TableFilters';

const DispatchOrders = ({ ...props }) => {
  const dispatch = useDispatch();
  const { dispatchOrder } = useSelector((state) => state?.actions?.orders);
  const { dOStatus, dOMethods, dOTypes } = useSelector((state) => state.lists);
  const { userProjects = [] } = useSelector((state) => state?.userDetails);

  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    isEmpty(dOStatus) && dispatch(fetchDispatchStatuses());
    isEmpty(dOMethods) && dispatch(fetchDispatchMethods());
    isEmpty(dOTypes) && dispatch(fetchDispatchTypes());
  }, [dOStatus, dOMethods, dOTypes]);

  const handleUploadFile = async (returnData = {}, passedData = {}) => {
    const { dispatchId } = returnData;
    if (!dispatchId || !passedData?.files) return;

    await uploadAttachmentsDO(
      getFileFormData(passedData?.files),
      dispatchId,
    )?.catch(() =>
      dispatch(
        setErrorDialogText(
          'Error occurred while uploading documents, please try again later.',
        ),
      ),
    );
  };

  const filterSelectorEnum = [
    {
      name: 'global',
      label: 'Global search',
      placeholder: 'Search dispatch order...',
      isInput: true,
    },
    {
      name: 'dispatchType',
      label: 'Dispatch order type',
      data: dOTypes,
      multiple: true,
    },
    {
      name: 'dispatchStatusId',
      label: 'Dispatch order status',
      data: dOStatus,
      multiple: true,
    },
    {
      name: 'dispatchMethod',
      label: 'Dispatch order method',
      data: dOMethods,
      multiple: true,
    },
    {
      name: 'projectId',
      label: 'Project',
      data: userProjects,
      multiple: true,
    },
    {
      name: 'dispatchedDate',
      label: 'Dispatch date',
      date: true,
    },
  ];

  return (
    <>
      <PageMain
        formKey={createFormEnum.dispatch_orders}
        rows={filteredOrders}
        columns={dispatchorderColumns()}
        label="Dispatch Orders"
        createLabel="Create Dispatch Order"
        fetchApi={fetchDispatchOrder}
        createFormProps={{
          disableSubmitNew: true,
          callback: handleUploadFile,
          maxWidth: 'lg',
        }}
        fetchByIdApi={getDispatchOrderById}
        detailDataFetchIdKey="dispatchId"
        drawerLabelKey="dispatchNo"
        pagePath={crmRoutes.DISPATCH_ORDERS_PATH}
        {...props}
      >
        <TableFilters
          data={dispatchOrder}
          filterSelectorEnum={filterSelectorEnum}
          handleChange={(filteredData) => {
            setFilteredOrders(filteredData);
          }}
        />
      </PageMain>
    </>
  );
};

export default DispatchOrders;
