import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAdminPermissions } from 'redux/slices/consoleAdministration/permissionSlice';

// import {
//   fetchAssetStatus,
//   fetchMakes,
//   fetchProjects,
//   fetchWarehouses,
//   fetchItemTypes,
//   fetchLocations,
//   fetchProductList,
//   fetchAccountList,
//   fetchContactList,
//   fetchWorkOrderList,
//   fetchITADServices,
//   fetchCustomerDropDownList,
//   fetchUserList,
//   fetchLeadStatusList,
//   fetchQuoteStatusList,
//   fetchAllStatusList,
//   fetchAllServiceItems
// } from 'redux/slices/listSlice/listSlice';

import { fetchUserDetails } from 'redux/slices/userSlice/userDetailsSlice';
import { fetchAdminUserRolePermissions } from 'redux/slices/consoleAdministration/userRolePermissions';

const ApiInitiator = () => {
  const dispatch = useDispatch();

  //only fetch the ones required globally and not specific ones
  useEffect(() => {
    //fetch user details
    dispatch(fetchUserDetails());

    // console admin
    dispatch(fetchAdminPermissions());
    dispatch(fetchAdminUserRolePermissions());

    // dispatch(fetchAssetStatus());
    // dispatch(fetchMakes());
    // dispatch(fetchWarehouses());
    // dispatch(fetchItemTypes());
    // dispatch(fetchLocations());
    // dispatch(fetchProductList());
    // dispatch(fetchAccountList());
    // dispatch(fetchContactList());
    // dispatch(fetchWorkOrderList());
    // dispatch(fetchITADServices());
    // dispatch(fetchCustomerDropDownList());
    // dispatch(fetchUserList());
    // dispatch(fetchLeadStatusList());
    // dispatch(fetchQuoteStatusList());
    // dispatch(fetchAllStatusList());
    // dispatch(fetchAllServiceItems())
  }, []);
};

export default ApiInitiator;
