import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import { fetchAssetTestConditions } from 'redux/slices/detailSlice/assetTestSlice';
import {
  setErrorDialogText,
  setSnackBar,
} from 'redux/slices/commonSlice/commonSlice';
// import { postAssetTestConditions } from 'redux/slices/detailSlice/assetTestSlice';

import {
  submitAssetTestConditions,
  submitBulkAssetTestConditions,
  getAssetTestConditions,
  getAssetDetails,
} from 'api/assetsApi';

import DialogBox from 'common/dataDisplay/dialogBox/DialogBox';
import SwitchToggle from 'common/input/SwitchToggle';
import TabsMenu from 'common/dataDisplay/tabs/TabsMenu';
import PaperBox from 'common/ui/PaperBox';
import InputField from 'common/input/InputField';

import AssetGrade from './AssetGrade';

import { Box, Button, Grid, Typography } from '@mui/material';
import RichNoteField from 'common/input/RichNoteField'
import Selector from 'common/input/Selector'

import useReactForm from 'hooks/useReactForm';

import _, {
  difference,
  isEmpty,
  max,
  clone,
  includes,
  filter,
  mapValues,
  camelCase,
  concat,
} from 'lodash';

export default function AssetTestDialog({
  open = false,
  handleClose = () => { },
  //pass asset data as empty obj from the main compononent to fix rerender issue
  assetData = {},
  assetIds = [],
  isBulk,
  noDialog,
  testValues = [],
  manualData = null,
  isAssetDetail = false,
  setUpdatedData = () => { },
  submitCallback = () => { },
  triggerAssetFetch = () => { },
}) {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedTabs, setCheckedTabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredValues, setFilteredValues] = useState([]);
  const [filteredItemIds, setFilteredItemIds] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [assetTestConditions, setAssetTestConditions] = useState([]);
  const [allPassId, setAllPassId] = useState(null);
  const [isAllPass, setIsAllPass] = useState(false);

  const [isManual, setIsManual] = useState(false);

  const [grade, setGrade] = useState('A');
  const [gradeList, setGradeList] = useState(['A']);

  const [testedData, setTestedData] = useState([]);

  const { formData, handleSubmit, reset } = useReactForm();

  const handleManualToggle = (check) => {
    setIsManual(check);
    check ? setTabValue(false) : setTabValue(0);
  }

  useEffect(() => {
    if (isAssetDetail || !open || isBulk) return;

    setLoading(true);
    getAssetDetails(assetData?.ssn).then((res) => {

      const { isManual: manualData, ...autoTestData } = res?.assetTestResults;
      const { isManual, grade, gradingComments } = manualData;

      if (isManual) {
        handleManualToggle(true);
        setGrade(grade);
        reset({
          grade,
          gradingComments,
        });
        return;
      }

      const splittedValues = mapValues(autoTestData, (value) =>
        value
          ?.split(';')
          ?.map((item) => item.trim())
          ?.filter((item) => item.length > 0),
      );
      setTestedData(splittedValues);
    });
  }, [assetData, isAssetDetail, open]);

  useEffect(() => {
    if (manualData) {
      handleManualToggle(true);
      setGrade(manualData?.grade);
      reset({ ...manualData });
      return;
    }

    isAssetDetail && setTestedData(testValues);
  }, [manualData, isAssetDetail, testValues]);

  useEffect(() => {
    if (isEmpty(assetData) && !isBulk) return;

    if (open || noDialog) {
      setLoading(true);
      getAssetTestConditions(
        isBulk ? 0 : assetData?.assetID || assetData?.assetId,
      )
        .then((res) => setAssetTestConditions(res))
        .finally(() => setLoading(false));
    } else {
      assetTestConditions?.length && setAssetTestConditions([]);
      isManual && setIsManual(false);
    }
  }, [open, assetData]);


  useEffect(() => {
    if (
      assetTestConditions?.length &&
      !isManual
    ) {
      const currentTabItems = assetTestConditions?.[tabValue]?.testConditions;
      const currentTabIds = currentTabItems.map((item) => item.testConditionID);
      const currentAllPassId = currentTabItems?.find(
        (item) => item?.conditionName === 'ALL-PASS',
      )?.testConditionID;

      setFilteredValues(currentTabItems);
      setFilteredItemIds(currentTabIds);
      setSearchValue('');

      setIsAllPass(checkedItems?.includes(currentAllPassId));
      setAllPassId(currentAllPassId);
    }
  }, [assetTestConditions, tabValue, checkedTabs]);

  const handleSwitchToggleClick = (checked, testID, currentGrade) => {
    if (checked) {
      !checkedTabs?.includes(tabValue) &&
        setCheckedTabs([...checkedTabs, tabValue]);

      let newSelectedArr;

      if (testID === allPassId || checkedItems?.includes(allPassId)) {
        const currentTabItemsFiltered = difference(
          checkedItems,
          filteredItemIds,
        );
        newSelectedArr = [...currentTabItemsFiltered, testID];

        setCheckedItems(newSelectedArr);
        setIsAllPass(true);

        //Grade logic
        const allTestConditions =
          assetTestConditions?.[tabValue]?.testConditions;
        const gradeById = filter(allTestConditions, (item) =>
          includes(checkedItems, item.testConditionID),
        )?.map((item) => item?.grade);

        const gradeListClone = clone(gradeList);

        gradeById.forEach((gradeItem) => {
          const index = gradeListClone.indexOf(gradeItem);
          if (index !== -1) {
            gradeListClone.splice(index, 1);
          }
        });

        setGradeList(gradeListClone);
        ![...gradeList, gradeById]?.length && setGradeList(['A']);

        return;
      }

      setIsAllPass(false);

      newSelectedArr = [...checkedItems, testID];
      setCheckedItems(newSelectedArr);

      setGradeList([...gradeList, currentGrade]);
    } else {
      if (testID === allPassId) {
        const removedAllPassed = checkedItems?.filter(
          (item) => item !== allPassId,
        );
        setCheckedItems(removedAllPassed);
        setIsAllPass(false);
        return;
      }

      setCheckedItems(checkedItems?.filter((item) => item !== testID));
      setCheckedTabs(checkedTabs?.filter((item) => item !== tabValue));

      const gradeIndex = gradeList?.indexOf(currentGrade);

      if (gradeIndex !== -1) {
        const clonedGradeList = clone(gradeList);
        clonedGradeList?.splice(gradeIndex, 1);
        clonedGradeList?.length
          ? setGradeList(clonedGradeList)
          : setGradeList(['A']);
      }
    }
  };

  //setting fetched values as initial
  useEffect(() => {
    if (!assetTestConditions?.length || isBulk || isManual) return;

    setLoading(true);

    let fetchedGrades = ['A'];

    const checkedIds = assetTestConditions?.map((conditionObj, key) => {
      const conditionName = camelCase(conditionObj?.conditionCategority);
      const checkedList = testedData?.[conditionName] || [];

      if (!checkedList?.length) return checkedList;

      // if (checkedList?.includes('ALL-PASS')) {
      //   const match = _.find(conditionObj.testConditions, { conditionName: 'ALL-PASS' });
      //   if (key === 0) {
      //     setIsAllPass(true);
      //     setAllPassId(match.testConditionID);
      //   }
      //   return match.testConditionID;
      // }

      return _(checkedList)
        .map((name) => {
          const match = _.find(conditionObj.testConditions, {
            conditionName: name,
          });
          if (match === undefined || match === null) return null;
          fetchedGrades?.push(match?.grade);
          return match.testConditionID;
        })
        .compact()
        .value();
    });

    const concattedIds = concat(...checkedIds);
    setCheckedTabs(concattedIds);
    setCheckedItems(concattedIds);
    setGradeList(fetchedGrades);
    setLoading(false);
  }, [testedData, assetTestConditions]);

  useEffect(() => {
    if (isManual) return;
    setGrade(max(gradeList));
  }, [gradeList]);

  const onClose = () => {
    setTabValue(0);
    handleClose();
    reset({});
    // setCheckedItems([]);
    // setCheckedTabs([]);
  };

  const handleApiCall = (payload) => {
    const submitApi = isBulk ? submitBulkAssetTestConditions : submitAssetTestConditions;
    setLoading(true);

    submitApi(payload)
      .then((res) => {
        dispatch(
          setSnackBar({
            open: true,
            message: 'Test conditions added successfully!',
          }),
        );

        if (!isBulk) {
          isAssetDetail &&
            getAssetDetails(assetData?.ssn).then((res) => setUpdatedData(res));
        }

        submitCallback(res);
        triggerAssetFetch();
        reset({});
        onClose();
      })
      .catch((err) => {
        console.log(err, 'err')
        dispatch(
          setErrorDialogText(
            'Server error occurred, please try again later.',
          ),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onSubmit = async () => {
    if (checkedTabs?.length < assetTestConditions.length) {
      dispatch(
        setErrorDialogText(
          'Please select at least one condition from all the categories.',
        ),
      );
      return;
    }

    const payload = {
      grade,
      ...(isBulk ? {
        assetIds,
        testConditionIds: checkedItems,
      }
        :
        {
          assetID: assetData?.assetID || assetData?.assetId,
          testConditionID: checkedItems
        })
    };

    handleApiCall(payload);
  };

  const onManualSubmit = (data) => {
    const payload = {
      ...(isBulk ? {
        assetIds,
        testConditionIds: [],
      }
        :
        {
          assetID: assetData?.assetID || assetData?.assetId,
          testConditionID: []
        }),
      IsManual: true,
      ...data,
    };

    handleApiCall(payload);
  }

  const handleSearch = (value = '') => {
    setSearchValue(value);
    const conditions = assetTestConditions?.[tabValue]?.testConditions;
    const res = conditions?.filter((item) =>
      item?.conditionName?.toUpperCase()?.includes(value?.toUpperCase()),
    );
    setFilteredValues(res);
  };

  const testContent = (
    <>
      <Box display='flex' alignItems='center' columnGap={0.5} mb={2}>
        <Typography
          variant='p3'
          fontWeight='500'
          onClick={() => handleManualToggle(false)}
          sx={{ cursor: 'pointer' }}
        >
          Data Erasure
        </Typography>
        <Box width='60px'>
          <SwitchToggle
            size='large'
            labelPlacement='start'
            checked={isManual}
            onChange={(checked) => handleManualToggle(checked)}
            sx={{ m: 0 }}
          />
        </Box>
        <Typography
          variant='p3'
          fontWeight='500'
          onClick={() => handleManualToggle(true)}
          sx={{ cursor: 'pointer' }}
        >
          Manual
        </Typography>
      </Box>

      <Box width='100%' minHeight='500px'>
        <Box
          display='flex'
          justifyContent='flex-start'
          gap='12px'
          alignItems='center'
        >
          <TabsMenu
            setTabValue={setTabValue}
            tabHeaders={assetTestConditions?.map(
              (item) => item?.conditionCategority,
            )}
            tabValue={tabValue}
            disabled={isManual}
          />
          {isManual &&
            <Button
              variant='contained'
              size='large'
            >
              <Typography variant='p2' fontWeight='500'>MANUAL</Typography>
            </Button>
          }
        </Box>

        <PaperBox sx={{ mt: 2 }}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            flexWrap='wrap'
            rowGap={1}
          >
            {!isBulk && !noDialog && (
              <Box display='flex' alignItems='center' columnGap={1}>
                <Typography fontWeight='bold'>SSN:</Typography>
                <Typography component='span'>{assetData?.ssn}</Typography>
              </Box>
            )}

            {!isManual &&
              <>
                <Box maxWidth='250px' width='100%'>
                  <InputField
                    isSmallHeight
                    label='Search'
                    value={searchValue}
                    onChange={(name, value) => handleSearch(value)}
                  />
                </Box>


                <AssetGrade grade={grade} />
              </>
            }
          </Box>
        </PaperBox>

        <PaperBox sx={{ mt: 1 }}>
          {!isManual ? (
            <Grid container spacing={2}>
              {filteredValues?.map((item, key) => (
                <Grid key={key} item md={3} sm={4} xs={12}>
                  <SwitchToggle
                    size='medium'
                    isCheckBox
                    disabled={
                      loading ||
                      (isAllPass && item?.testConditionID !== allPassId)
                    }
                    checked={checkedItems?.includes(item?.testConditionID)}
                    onChange={(checked) =>
                      handleSwitchToggleClick(
                        checked,
                        item?.testConditionID,
                        item?.grade,
                      )
                    }
                    label={
                      <Typography
                        variant='description'
                        fontWeight='500'
                        sx={{
                          wordBreak: 'break-word',
                          opacity:
                            isAllPass && item?.testConditionID !== allPassId
                              ? 0.6
                              : 1,
                        }}
                      >
                        {item?.conditionName?.toUpperCase()}
                      </Typography>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              <Box maxWidth='280px' mb={3}>
                <Selector
                  name='grade'
                  formData={formData}
                  selectorData={[
                    { value: 'A', label: 'A' },
                    { value: 'B', label: 'B' },
                    { value: 'C', label: 'C' },
                    { value: 'D', label: 'D' },
                    { value: 'E', label: 'E' },
                    { value: 'F', label: 'F' },
                  ]}
                  required
                />
              </Box>
              <InputField
                formData={formData}
                name='gradingComments'
                label='Grading Comment'
                multiline
                maxRows={8}
                minRows={2}
              />
            </>
          )}
        </PaperBox>
      </Box>
    </>
  );

  return (
    <>
      {noDialog ? (
        testContent
      ) : (
        <DialogBox
          open={open}
          disableSubmitNew
          title={`Asset ${isBulk ? 'Bulk' : ''} Testing`}
          loading={loading}
          handleClose={onClose}
          handleFormSubmit={() => {
            isManual ? handleSubmit(onManualSubmit)() : onSubmit();
          }}
          // sx={{ zIndex: 999999 }}
          maxWidth='lg'
        >
          {testContent}
        </DialogBox>
      )}
    </>
  );
}
