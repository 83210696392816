import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { salesorderColumns } from 'pages/tableColumns/salesorderColumns';
import PageMain from 'pages/components/PageMain';
import createFormEnum from 'enum/createFormEnum';

import { fetchSalesOrder } from 'redux/slices/actionSlice/orderSlice';

import {
  fetchAllCurrencies,
  setErrorDialogText,
} from 'redux/slices/commonSlice/commonSlice';
import { useDispatch } from 'react-redux';

import { getSalesOrderById, uploadAttachmentsSO } from 'api/orderApis';
import { crmRoutes } from 'routes/allRoutesMap';

import { getFileFormData } from 'utils/fileHelperFunctions';
import TableFilters from 'pages/components/TableFilters';

import {
  fetchSaleOrderStatuses,
  fetchSaleOrderTypes,
} from 'redux/slices/listSlice/listSlice';
import { isEmpty } from 'lodash';

const SalesOrders = ({ ...props }) => {
  const dispatch = useDispatch();

  const [filteredOrders, setFilteredOrders] = useState([]);

  const { sales } = useSelector((state) => state?.actions?.orders);
  const { userProjects = [] } = useSelector((state) => state?.userDetails);
  const { saleOrderTypes, saleOrderStatuses } = useSelector(
    (state) => state?.lists,
  );

  const { currencies } = useSelector((state) => state.common);

  useEffect(() => {
    isEmpty(currencies) && dispatch(fetchAllCurrencies());
  }, [currencies]);

  useEffect(() => {
    !saleOrderStatuses?.length && dispatch(fetchSaleOrderStatuses());
    !saleOrderTypes?.length && dispatch(fetchSaleOrderTypes());
  }, [saleOrderStatuses, saleOrderTypes]);

  const handleUploadFile = async (returnData = {}, passedData = {}) => {
    const { salesOrderID } = returnData;
    if (!salesOrderID || !passedData?.files) return;

    await uploadAttachmentsSO(
      getFileFormData(passedData?.files),
      salesOrderID,
    )?.catch(() =>
      dispatch(
        setErrorDialogText(
          'Error occurred while uploading documents, please try again later.',
        ),
      ),
    );
  };

  const filterSelectorEnum = [
    {
      name: 'global',
      label: 'Global search',
      placeholder: 'Search work order...',
      isInput: true,
    },
    {
      name: 'orderType',
      label: 'Sale Order Type',
      data: saleOrderTypes,
      multiple: true,
    },
    {
      name: 'salesOrderStatusId',
      label: 'Sale Order Status',
      data: saleOrderStatuses,
      multiple: true,
    },
    {
      name: 'projectId',
      label: 'Project',
      data: userProjects,
      multiple: true,
    },
    {
      name: 'currency',
      label: 'Currency',
      data: currencies,
      multiple: true,
    },
    {
      name: 'salesOrderDate',
      label: 'Sale Order Date',
      date: true,
    },
  ];

  return (
    <>
      <PageMain
        formKey={createFormEnum.sales_orders}
        rows={filteredOrders}
        columns={salesorderColumns()}
        label="Sales Orders"
        createLabel="Create Sale Order"
        fetchApi={fetchSalesOrder}
        createFormProps={{
          disableSubmitNew: true,
          callback: handleUploadFile,
          maxWidth: 'lg',
        }}
        fetchByIdApi={getSalesOrderById}
        detailDataFetchIdKey="salesOrderID"
        drawerLabelKey="salesOrderNumber"
        pagePath={crmRoutes.SALES_ORDERS_PATH}
        {...props}
      >
        <TableFilters
          filterSelectorEnum={filterSelectorEnum}
          data={sales}
          handleChange={(filteredData) => {
            setFilteredOrders(filteredData);
          }}
        />
      </PageMain>
    </>
  );
};

export default SalesOrders;
