import { format, isValid, parseISO } from 'date-fns';
import { isArray, isEmpty, isEqual, isNumber, isString, toNumber } from 'lodash';
import { useEffect, useState } from 'react';

import { useSearchParams, useNavigate } from 'react-router-dom';

import { safeParseISO } from 'utils/textFormatUtils';

const toDate = (dateString) =>
  (isString(dateString) && isValid(safeParseISO(dateString)))
    ? safeParseISO(dateString)
    : null;

const parseParam = (urlParamsObj = {}) => {
  const paramStringParsed = {};
  for (const param in urlParamsObj) {
    const paramValue = urlParamsObj[param];

    const parsedDate = toDate(paramValue);
    if (parsedDate) {
      paramStringParsed[param] = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
    } else if (!isNaN(toNumber(paramValue))) {
      const parsedNumber = toNumber(paramValue);
      paramStringParsed[param] = parsedNumber;
    }
    else if (paramValue === 'true' || paramValue === 'false') {
      paramStringParsed[param] = paramValue === 'true';
    } else if (param === 'sort' && isString(paramValue)) {
      try {
        paramStringParsed[param] = JSON.parse(paramValue);
      } catch (e) {
        paramStringParsed[param] = paramValue;
      }
    }
    else {
      const parseArray = isString(paramValue) && param !== 'search'
        ? paramValue.split(",")
        : paramValue;
      paramStringParsed[param] = parseArray;
    }
  }
  return paramStringParsed;
};

const normalizePageNumber = (params) => {
  if (params.pageNumber) {
    params.pageNumber = isArray(params.pageNumber)
      ? toNumber(params.pageNumber[0])
      : toNumber(params.pageNumber);

    if (isNaN(params.pageNumber) || params.pageNumber < 1) {
      params.pageNumber = 1;
    }
  }
  return params;
};

export const useUrlParams = () => {
  const nav = useNavigate();

  const [searchParams] = useSearchParams();

  const initialUrlParams = normalizePageNumber(parseParam(Object.fromEntries(searchParams)));
  const [urlParams, setUrlParams] = useState(initialUrlParams);

  const setUpdatedUrlParams = (newParams) => {
    let paramString = "?" +
      Object.entries(newParams)
        .map(([key, value]) => {
          if (isEmpty(value) && !isNumber(value)) return '';
          if (isArray(value)) return `${key}=${value.join(",")}`;
          return `${key}=${value}`;
        })
        .filter(Boolean)
        .join("&");


    nav(paramString);
  };

  useEffect(() => {
    const paramParsed = parseParam(Object.fromEntries(searchParams));
    const normalizedParams = normalizePageNumber(paramParsed);

    if (!isEqual(normalizedParams, urlParams)) {
      setUrlParams(normalizedParams);
    }
  }, [searchParams]);

  return [urlParams, setUpdatedUrlParams];
};
