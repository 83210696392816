import React, { useEffect, useState, useCallback, memo, useMemo } from 'react';
import {
  Autocomplete,
  FormControl,
  Box,
  Chip,
  CircularProgress,
} from '@mui/material';

import {
  isArray,
  isEmpty,
  isString,
  isNull,
  toNumber,
  toString,
} from 'lodash';

import { Controller } from 'react-hook-form';
import { camelCaseToSpace } from '../../utils/textFormatUtils';
import InputField from './InputField';

const LoadingSpinner = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '55%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 5,
    }}
  >
    <CircularProgress size={25} />
  </Box>
);

const SearchSelect = ({
  formData = {},
  searchSelectData,
  name = '',
  label,
  noLabel = false,
  placeholder,
  onChange = () => { },
  disableStar = false,
  variant = 'outlined',
  required = false,
  fullWidth = true,
  helperText = null,
  size = 'small',
  rules,
  sx,
  InputLabelProps = {},
  InputProps = {},
  inputProps = {},
  returnLabel = false,
  value,
  multiple = false,
  customLabel,
  inputFieldProps = {},
  loading = false,
  disabled = false,
  onBlur = () => { },
  // defaultValue = null,
  ...rest
}) => {
  const [optionValues, setOptionValues] = useState([]);
  const [optionLabels, setOptionLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstUpdate, setIsFirstUpdate] = useState(true);

  const { watch = () => { }, setValue = () => { }, control, errors } = formData;

  const watchedData = watch(name);

  useEffect(() => {
    const isEmptyData = !searchSelectData?.length && !disabled && loading;
    setIsLoading(isEmptyData);
  }, [searchSelectData, loading]);

  // useEffect(() => {
  //   fieldValue === undefined && setValue(name, multiple ? [] : defaultValue);
  // }, [fieldValue]);

  useEffect(() => {
    const optionsArr = searchSelectData?.map((item) => ({
      value: item?.id !== undefined ? item?.id : item?.value || null,
      label: item?.label !== undefined ? item?.label : item?.value || null,
    }));
    setOptionLabels(optionsArr?.map((option) => option?.label));
    setOptionValues(optionsArr?.map((option) => option?.value));
  }, [searchSelectData]);

  const handleBlur = useCallback((item) => {
    onBlur(name, item);
  }, []);

  const valueLabelMap = useMemo(() => {
    if (isEmpty(optionValues) || isEmpty(optionLabels)) return new Map();
    return new Map(
      optionValues.map((value, index) => [
        toNumber(value) || value,
        optionLabels[index],
      ]),
    );
  }, [optionValues, optionLabels]);

  const valueToLabel = useCallback(
    (value) => {
      if (returnLabel) return value;
      const parsedValue = isNaN(toNumber(value)) ? value : toNumber(value);
      return toString(valueLabelMap.get(parsedValue) || '') || (isString(value) ? value : '');
    },
    [valueLabelMap, returnLabel],
  );

  const labelValueMap = useMemo(() => {
    if (isEmpty(optionLabels) || isEmpty(optionValues)) return new Map();
    return new Map(
      optionLabels.map((label, index) => [label, optionValues[index]]),
    );
  }, [optionLabels, optionValues, optionValues, optionLabels]);

  const labelToValue = useCallback(
    (label) => {
      if (!multiple) {
        return labelValueMap.get(label) ?? (isString(label) ? label : '');
      }
      return isArray(label)
        ? label.map((lbl) => labelValueMap.get(lbl) ?? lbl)
        : [];
    },
    [labelValueMap, multiple],
  );

  //converting incoming values to convert from labels to values
  useEffect(() => {
    if (isFirstUpdate && !isNull(watchedData) && !isEmpty(optionLabels)) {
      setIsFirstUpdate(false);

      const newValue = valueLabelMap.has(watchedData)
        ? returnLabel
          ? valueToLabel(watchedData)
          : watchedData
        : labelToValue(watchedData);

      setValue(name, newValue);
    }
  }, [watchedData, optionLabels]);

  const handleChange = (value, field) => {
    field?.onChange(value);
    onChange(name, value, labelToValue(value));
  };

  const formControlSx = useMemo(
    () => ({
      '& .MuiAutocomplete-endAdornment': {
        '& .MuiButtonBase-root': {
          width: '16px',
          '& svg': {
            fontSize: '16px',
          },
        },
      },
      ...(multiple
        ? {
          '& .MuiButtonBase-root': {
            height: 1,
            fontSize: '12px',
            '& svg': {
              fontSize: '16px',
            },
          },
        }
        : {}),
      '& .Mui-disabled': {
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.55) !important',
      },
      '& .MuiInputBase-root': {
        ...(multiple
          ? {
            height: 'auto',
            ...(size === 'tiny' ? { py: '0px !important' } : {}),
          }
          : {}),
        pr: '30px !important',
        fontSize: '13px',
        flexWrap: multiple ? 'wrap' : 'nowrap',
        overflow: multiple ? 'auto' : 'hidden !important',
      },
      ...sx,
    }),
    [],
  );

  if (isEmpty(formData))
    return (
      <Box position="relative" width={fullWidth ? '100%' : ''}>
        {!disabled && (loading || isLoading) && <LoadingSpinner />}
        {/*
      {isLabelOutside && (
        <InputLabel>
          <Typography color={'#000000'} variant='p' fontSize={{ xs: "12px", sm: "14px", md: "16px" }}>
            {label || camelCaseToSpace(name)}
          </Typography>
        </InputLabel>
      )} */}

        <FormControl fullWidth={fullWidth} sx={formControlSx}>
          <Autocomplete
            size={size}
            loading={isLoading}
            options={returnLabel ? optionLabels || [] : optionValues || []}
            multiple={multiple}
            disableCloseOnSelect={multiple}
            disabled={disabled || loading || isLoading}
            onBlur={(e) => onBlur(name, e.target.value)}
            onChange={(e, val) => onChange(name, val)}
            value={value || (multiple ? [] : '')} // Ensure value is always defined
            // getOptionLabel={option => isString(option) ? option : valueToLabel(option)}
            getOptionLabel={(option) => valueToLabel(option)}
            renderOption={(props, option, item) => (
              <Box component="li" {...props} key={item?.index}>
                {/* {isString(option) ? option : valueToLabel(option)} */}
                {valueToLabel(option)}
              </Box>
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) =>
                customLabel ? (
                  customLabel(option, { ...getTagProps({ index }) })
                ) : (
                  <Chip
                    {...getTagProps({ index })}
                    key={option}
                    label={valueToLabel(option)}
                  />
                ),
              )
            }
            renderInput={(params) => (
              <InputField
                {...params}
                InputProps={{
                  ...params?.InputProps,
                  ...InputProps,
                }}
                inputProps={{ ...params?.inputProps, ...inputProps }}
                variant={variant}
                label={label}
                noLabel={noLabel}
                placeholder={placeholder}
                name={name}
                required={required}
                disableStar={disableStar}
                {...InputLabelProps}
                size={size}
                {...inputFieldProps}
              />
            )}
            // componentsProps={{ popper: { style: { minWidth: 'fit-content' } } }}
            {...rest}
          />
        </FormControl>
      </Box>
    );

  return (
    <Box position="relative" width={fullWidth ? '100%' : ''}>
      {(loading || isLoading) && <LoadingSpinner />}

      <Controller
        control={control}
        name={name}
        rules={{
          required: required,
          ...rules,
        }}
        render={({ field }) => (
          <FormControl fullWidth={fullWidth} sx={formControlSx}>
            <Autocomplete
              {...field}
              loading={isLoading}
              size={size}
              multiple={multiple}
              disableCloseOnSelect={multiple}
              disabled={disabled || loading || isLoading}
              options={returnLabel ? optionLabels || [] : optionValues || []}
              onBlur={(e) => handleBlur(e.target.value, field)}
              onChange={(e, item) => handleChange(item, field)}
              value={
                multiple
                  ? field.value
                    ? isArray(field.value)
                      ? field.value
                      : [field.value]
                    : []
                  : field.value !== undefined
                    ? field.value
                    : ''
              }
              // getOptionLabel={option => isString(option) ? option : valueToLabel(option)}
              getOptionLabel={(option) => valueToLabel(option)}
              renderOption={(props, option, item) => (
                <Box component="li" {...props} key={item?.index}>
                  {valueToLabel(option)}
                  {/* {isString(option) ? option : valueToLabel(option)} */}
                </Box>
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  return customLabel ? (
                    customLabel(option, { ...getTagProps({ index }) })
                  ) : (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      label={valueToLabel(option)} />
                  );
                },
                )
              }
              renderInput={(params) => (
                <InputField
                  {...params}
                  InputProps={{
                    ...params?.InputProps,
                    ...InputProps,
                  }}
                  inputProps={{
                    ...params?.inputProps,
                    ...inputProps,
                    value: params?.inputProps?.value || '',
                  }}
                  error={!!errors[name]}
                  helperText={
                    !!errors[name] &&
                    (helperText ||
                      `${label || camelCaseToSpace(name)} is required`)
                  }
                  variant={variant}
                  label={label}
                  noLabel={noLabel}
                  placeholder={placeholder}
                  name={name}
                  required={required}
                  disableStar={disableStar}
                  {...InputLabelProps}
                  {...inputFieldProps}
                  size={size}
                />
              )}
              // componentsProps={{ popper: { style: { minWidth: 'fit-content' } } }}
              {...rest}
            />
          </FormControl>
        )}
      />
    </Box>
  );
};

export default memo(SearchSelect);
