import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAdminPermissions,
  getAdminUserRolePermissions,
} from 'api/masterApi';
import { isEmpty } from 'lodash';

export const fetchAdminUserRolePermissions = createAsyncThunk(
  'actions/fetchAdminUserRolePermissions',
  async () => await getAdminUserRolePermissions(),
);

export const adminUserRolePermissionsSlice = createSlice({
  name: 'adminUserRolePermissions',
  initialState: [],
  extraReducers: (builder) => {
    builder.addCase(
      fetchAdminUserRolePermissions.fulfilled,
      (state, action) => {
        if (isEmpty(action.payload)) return state;
        return action.payload;
      },
    );
  },
});

export default adminUserRolePermissionsSlice.reducer;
