import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { Container } from '@mui/material';

import CrmRoutes from 'pages/route/CrmRoutes';
import ProfileSection from './profile/ProfileSection';

import SideDrawer from 'components/sideDrawer/SideDrawer';
import Navbar from 'components/navbar/Navbar';

import ApiInitiator from 'api/ApiInitiator';
import ErrorDialog from 'common/dataDisplay/dialogBox/ErrorDialog';

import { otherRoutes } from 'routes/allRoutesMap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function CrmMain() {
  const navigate = useNavigate();
  const { isLogin } = useSelector((state) => state.auth);

  const isProfile = window.location.pathname.split('/')[1] === 'profile';

  useEffect(() => {
    !isLogin && navigate(otherRoutes.LOGIN);
  }, [isLogin]);

  //move to main route
  return (
    <>
      <ApiInitiator />
      <ErrorDialog />

      <Box
        sx={{
          display: 'flex',
          backgroundColor: (theme) => theme.palette.common.backgroundGrey,
          minHeight: '100dvh',
          // pb: 2,
        }}
      >
        <SideDrawer />
        <Navbar />
        <Box component="main" sx={{ flex: 1, width: 0 }}>
          <Box height={48}></Box>
          <Container
            maxWidth={false}
            disableGutters={isProfile}
            sx={{ mt: isProfile ? 0 : 1 }}
          >
            {isProfile ? <ProfileSection /> : <CrmRoutes />}
          </Container>
        </Box>
      </Box>
    </>
  );
}
